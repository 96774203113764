import { LitElement, css, html } from 'lit';
import Authenticate from '../api/authenticate';
import { store } from '../redux/store';
import { Router } from '@vaadin/router';
import { cacheAllMeasurementData, preloadObjects, initObjectManager } from '../api/dataManagementApi';
import { setObjectManager, setAppState } from '../redux/actions';
import { APP_STATES } from '../api/ui';
export class BernerScreenBase extends LitElement {
    async checkInitialized() {
        const userEmail = await Authenticate.getCurrentUserEmail();
        const authenticated = Boolean(userEmail);
        if (authenticated) {
            console.log(`${userEmail} is authenticated`);
            const { objectManager, measurementDataCachedState, measurementDataCachingInProgressState, preloadObjectsState, preloadObjectsInProgressState } = store.getState();
            this._objectManager = objectManager;
            if (!this._objectManager) {
                const { objectManager, initialized } = await initObjectManager(userEmail);
                if (!objectManager) {
                    console.error(`Failed to create ObjectManager`);
                    Router.go('auth/login');
                    return false;
                }
                else {
                    store.dispatch(setObjectManager(objectManager));
                    this._objectManager = objectManager;
                    if (!initialized) {
                        console.warn(objectManager.lastError);
                        if (!this._objectManager.getCurrentIdentity()) {
                            setAppState(APP_STATES.CREATE_ORG);
                            Router.go('auth/create-org');
                        }
                        return false;
                    }
                    else {
                        store.dispatch(setAppState(APP_STATES.LOGGED_IN));
                    }
                }
            }
            // cache measurement data for each air curtain, per site
            if (!measurementDataCachedState && !measurementDataCachingInProgressState) {
                cacheAllMeasurementData(this._objectManager);
            }
            // preload air curtains, users and identities
            if (!preloadObjectsState && !preloadObjectsInProgressState) {
                preloadObjects(this._objectManager);
            }
        }
        else {
            console.log(`${userEmail} is not authenticated`);
            Router.go('auth/login');
        }
        return authenticated;
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    renderLoader() {
        return html `
            <div class="row content-row col-lg-12 loader-container">
                <div
                    class="spinner-border spinner-loader mx-auto"
                    role="status"
                ></div>
            </div>
        `;
    }
    renderAlert(message, closeFunc) {
        return html `
            <div
                class="alert alert-light alert-dismissible show mx-auto mt-2"
                role="alert"
            >
                <button
                    type="button"
                    class="btn close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    @click=${closeFunc}
                >
                    x
                </button>
                <span class="mx-auto"> ${message} </span>
            </div>
        `;
    }
}
