import { SET_APP_STATE, AUTH_FAILED, SET_APP_TEXT, SET_OBJECT_MANAGER, SET_USER_EMAIL, SET_NAV_BAR_COLLAPSED_STATE, SET_MEASUREMENT_DATA, SET_OLD_MEASUREMENT_DATA, RESET_CACHED_MEASUREMENT_DATA, SET_MEASUREMENT_DATA_CACHED_STATE, SET_MEASUREMENT_DATA_CACHING_IN_PROGRESS_STATE, SET_PRELOAD_OBJECTS_STATE, SET_PRELOAD_OBJECTS_IN_PROGRESS_STATE, RESET_PRELOADED_OBJECTS_STATE } from './actions';
// userEmail saved here only when being retained between screens before user auth
const INITIAL_STATE = {
    appState: '',
    userEmail: '',
    applicationText: null,
    authError: null,
    objectManager: null,
    navBarCollapsedState: false,
    measurementData: {},
    measurementDataOld: {},
    measurementDataCachedState: false,
    measurementDataCachingInProgressState: false,
    preloadObjectsState: false,
    preloadObjectsInProgressState: false
};
export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_APP_STATE:
            return { ...state, appState: action.payload.appState };
        case SET_APP_TEXT:
            return {
                ...state,
                applicationText: action.payload.applicationText,
            };
        case SET_USER_EMAIL:
            return { ...state, userEmail: action.payload.userEmail };
        case AUTH_FAILED:
            return { ...state, authError: action.payload.error };
        case SET_OBJECT_MANAGER:
            return {
                ...state,
                objectManager: action.payload.objectManager,
            };
        case SET_NAV_BAR_COLLAPSED_STATE:
            return {
                ...state,
                navBarCollapsedState: action.payload.navBarCollapsedState,
            };
        case SET_MEASUREMENT_DATA:
            return {
                ...state,
                measurementData: {
                    ...state.measurementData,
                    [action.payload.deviceId]: action.payload.measurementData
                }
            };
        case SET_OLD_MEASUREMENT_DATA:
            return {
                ...state,
                measurementDataOld: {
                    ...state.measurementDataOld,
                    [action.payload.deviceId]: action.payload.measurementDataOld
                }
            };
        case SET_MEASUREMENT_DATA_CACHED_STATE:
            return {
                ...state,
                measurementDataCachedState: action.payload.measurementDataCachedState,
                measurementDataCachingInProgressState: false
            };
        case SET_MEASUREMENT_DATA_CACHING_IN_PROGRESS_STATE:
            return {
                ...state,
                measurementDataCachingInProgressState: action.payload.measurementDataCachingInProgressState,
            };
        case RESET_CACHED_MEASUREMENT_DATA:
            return {
                ...state,
                measurementData: {},
                measurementDataOld: {},
                measurementDataCachedState: false,
                measurementDataCachingInProgressState: false
            };
        case SET_PRELOAD_OBJECTS_STATE:
            return {
                ...state,
                preloadObjectsState: action.payload.preloadObjectsState,
                preloadObjectsInProgressState: false
            };
        case SET_PRELOAD_OBJECTS_IN_PROGRESS_STATE:
            return {
                ...state,
                preloadObjectsInProgressState: action.payload.preloadObjectsInProgressState,
            };
        case RESET_PRELOADED_OBJECTS_STATE:
            return {
                ...state,
                preloadObjectsState: false,
                preloadObjectsInProgressState: false,
            };
        default:
            return state;
    }
};
