import { getObjects, createObjects, updateObjects, deleteObjects, } from './../api/webApplicationApi';
import { v4 as uuidv4 } from 'uuid';
const objectType = 'userGroup';
export class UserGroup {
    constructor(userGroupID) {
        this._saveMethod = createObjects;
        this._object = {
            userGroupID: userGroupID,
            accountID: undefined,
            name: undefined,
            parentObjectType: undefined,
            parentObjectID: undefined,
            headObjectType: undefined,
            headObjectID: undefined,
            users: [],
            roles: [],
            policies: [],
        };
    }
    static createNew() {
        return new UserGroup(uuidv4());
    }
    static createFromObject(userGroup) {
        const obj = new UserGroup(userGroup.userGroupID);
        obj._object = userGroup;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.userGroupID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('user group not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.name == undefined) {
            this._lastError = new Error('Name is undefined');
            return false;
        }
        if (this._object.accountID == undefined) {
            this._lastError = new Error('Account ID is undefined');
            return false;
        }
        if (this._object.parentObjectType == undefined) {
            this._lastError = new Error('Parent Group Type is undefined');
            return false;
        }
        if (this._object.parentObjectID == undefined) {
            this._lastError = new Error('Parent Group ID is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknow server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get userGroupID() {
        return this._object.userGroupID;
    }
    get accountID() {
        return this._object.accountID;
    }
    set accountID(accountID) {
        this._object.accountID = accountID;
    }
    get name() {
        return this._object.name;
    }
    set name(name) {
        this._object.name = name;
    }
    get parentObjectType() {
        return this._object.parentObjectType;
    }
    set parentObjectType(parentObjectType) {
        this._object.parentObjectType = parentObjectType;
    }
    get parentObjectID() {
        return this._object.parentObjectID;
    }
    set parentObjectID(parentObjectID) {
        this._object.parentObjectID = parentObjectID;
    }
    get headObjectType() {
        return this._object.headObjectType;
    }
    set headObjectType(headObjectType) {
        this._object.headObjectType = headObjectType;
    }
    get headObjectID() {
        return this._object.headObjectID;
    }
    set headObjectID(headObjectID) {
        this._object.headObjectID = headObjectID;
    }
    get users() {
        return this._object.users;
    }
    addUser(user) {
        if (this._object.users.includes(user.userID))
            return;
        this._object.users.push(user.userID);
    }
    deleteUser(user) {
        const index = this._object.users.indexOf(user.userID);
        if (index > -1) {
            this._object.users.splice(index, 1);
        }
    }
    get roles() {
        return this._object.roles;
    }
    addRole(role) {
        if (this._object.roles.includes(role.roleID))
            return;
        this._object.roles.push(role.roleID);
    }
    deleteRole(role) {
        const index = this._object.roles.indexOf(role.roleID);
        if (index > -1) {
            this._object.roles.splice(index, 1);
        }
    }
    get policies() {
        return this._object.policies;
    }
    addPolicy(policy) {
        if (this._object.policies.includes(policy.policyID))
            return;
        this._object.policies.push(policy.policyID);
    }
    deletePolicy(policy) {
        const index = this._object.policies.indexOf(policy.policyID);
        if (index > -1) {
            this._object.policies.splice(index, 1);
        }
    }
}
