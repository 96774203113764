import { getObjects, createObjects, updateObjects, } from './../api/webApplicationApi';
import { v4 as uuidv4 } from 'uuid';
const objectType = 'system';
export class System {
    constructor(systemID) {
        this._saveMethod = createObjects;
        this._object = {
            systemID: systemID,
            name: undefined,
            roles: [],
            policies: [],
        };
    }
    static createNew() {
        return new System(uuidv4());
    }
    static createFromObject(system) {
        const obj = new System(system.systemID);
        obj._object = system;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.systemID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('system not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    // public async save(): Promise<boolean> {
    //     const saveObject:CreateUpdateDeleteRequest = {
    //         objectType: objectType,
    //         object: this._object
    //     };
    //     this._lastError = undefined;
    //     if (this._object.name == undefined){
    //         this._lastError = new Error('Name is undefined');
    //         return false;
    //     }
    //     try {
    //         const response = await this._saveMethod([saveObject]);
    //         if (response) {
    //             this._saveMethod = updateObjects;
    //         } else {
    //             this._lastError = new Error('unknow server error');
    //         }
    //         return response;
    //     } catch(error) {
    //         this._lastError = error;
    //         return false;
    //     }
    // }
    // public async delete() {
    //     const deleteObject:CreateUpdateDeleteRequest = {
    //         objectType: objectType,
    //         object: this._object
    //     };
    //     this._lastError = undefined;
    //     try {
    //         const response = await deleteObjects([deleteObject]);
    //         return response;
    //     } catch (error) {
    //         this._lastError = error;
    //         return false;
    //     }
    // }
    get lastError() {
        return this._lastError;
    }
    get systemID() {
        return this._object.systemID;
    }
    get name() {
        return this._object.name;
    }
    set name(name) {
        this._object.name = name;
    }
    get roles() {
        return this._object.roles;
    }
    addRole(role) {
        if (this._object.roles.includes(role.roleID))
            return;
        this._object.roles.push(role.roleID);
    }
    deleteRole(role) {
        const index = this._object.roles.indexOf(role.roleID);
        if (index > -1) {
            this._object.roles.splice(index, 1);
        }
    }
    get policies() {
        return this._object.policies;
    }
    addPolicy(policy) {
        if (this._object.policies.includes(policy.policyID))
            return;
        this._object.policies.push(policy.policyID);
    }
    deletePolicy(policy) {
        const index = this._object.policies.indexOf(policy.policyID);
        if (index > -1) {
            this._object.policies.splice(index, 1);
        }
    }
}
