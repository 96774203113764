import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { EVENTS, POWER_CYCLES, getLastError, updateSiteCosts } from '../../api/ui';
import { store } from '../../redux/store.js';
import { classMap } from 'lit/directives/class-map';
import { loadStyle } from '../../api/ui';
let BernerUpdateSiteCostsModal = class BernerUpdateSiteCostsModal extends LitElement {
    constructor() {
        super(...arguments);
        this.siteId = '';
        this._saving = false;
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    async firstUpdated() {
        await loadStyle('./css/modals/berner-update-site-costs-modal.css', this.shadowRoot);
        const { objectManager } = store.getState();
        this._objectManager = objectManager;
        const site = this._objectManager.getSite(this.siteId);
        this._location = await this._objectManager.getLocation(site.siteID);
        this._gasRate = this._location.naturalGasRate;
        this._electricityRate = this._location.electricityRate;
        this._oilRate = this._location.oilRate;
        this.powerCycles = this._location.powerCycles === 50 ? POWER_CYCLES.FIFTY_HZ : POWER_CYCLES.SIXTY_HZ;
    }
    async save() {
        var _a, _b, _c;
        this._saving = true;
        const saved = await updateSiteCosts(this._objectManager, this.siteId, (_a = this._gasRate) !== null && _a !== void 0 ? _a : 0, (_b = this._electricityRate) !== null && _b !== void 0 ? _b : 0, (_c = this._oilRate) !== null && _c !== void 0 ? _c : 0, this.powerCycles);
        this._saving = false;
        return saved;
    }
    get saveEnabled() {
        var _a;
        if (!this._objectManager)
            return false;
        const gasRateChanged = this._location.naturalGasRate != this._gasRate;
        const electricityRateChanged = this._location.electricityRate != this._electricityRate;
        const oilRateChanged = this._location.oilRate != this._oilRate;
        const currentPowerCycles = this._location.powerCycles == 50 ? POWER_CYCLES.FIFTY_HZ : POWER_CYCLES.SIXTY_HZ;
        const powerCyclesChanged = currentPowerCycles != this.powerCycles;
        return Boolean((_a = this.siteId) === null || _a === void 0 ? void 0 : _a.trim()) && (gasRateChanged || electricityRateChanged || oilRateChanged || powerCyclesChanged);
    }
    renderContent() {
        const onClickRadioButton = (e) => {
            this.powerCycles = e.target.id;
            e.stopPropagation();
        };
        const electricityRatePlaceholder = "Electricity Rate {$/kWH}";
        const gasRatePlaceholder = "Gas Rate ($/Mcf)";
        const oilRatePlaceholder = "Oil ($/Barrel)";
        return html `
            <div class="container-fluid">
                <div class="form-group">
                    <section class="form-group row">
                        ${this._saving
            ? html `
                                  <div class="mx-auto pt-5 pb-5">
                                      <div
                                          class="spinner-border spinner-loader"
                                          role="status"
                                      >
                                          <span class="sr-only">Saving...</span>
                                      </div>
                                  </div>
                              `
            : html `
                                  <section class="col-lg-12">
                                    <h5 class="mb-3 section-title">Energy Costs</h5>

                                      <div class="input-container">
                                        <label for="electricity-rate" .hidden=${!this._electricityRate} class="floating-label">${electricityRatePlaceholder}</label>
                                        <input
                                            type="number"
                                            value=${this._electricityRate}
                                            id="electricity-rate"
                                            placeholder=${electricityRatePlaceholder}
                                            @input=${(e) => {
                this._electricityRate =
                    e.target.value;
            }}
                                            class="${classMap({
                'form-control': true,
                "pt-3": this._electricityRate
            })}"
                                        />
                                      </div>

                                      <div class="input-container mt-4">
                                        <label for="gas-rate" .hidden=${!this._gasRate} class="floating-label">${gasRatePlaceholder}</label>
                                        <input
                                            type="number"
                                            value=${this._gasRate}
                                            id="gas-rate"
                                            placeholder=${gasRatePlaceholder}
                                            @input=${(e) => {
                this._gasRate = e.target.value;
            }}
                                            class="${classMap({
                'form-control': true,
                "pt-3": this._gasRate
            })}"
                                        />
                                      </div>

                                      <div class="input-container mt-4">
                                        <label for="oil-rate" .hidden=${!this._oilRate} class="floating-label">${oilRatePlaceholder}</label>
                                        <input
                                            type="number"
                                            value=${this._oilRate}
                                            id="oil-rate"
                                            placeholder=${oilRatePlaceholder}
                                            @input=${(e) => {
                this._oilRate = e.target.value;
            }}
                                            class="${classMap({
                'form-control': true,
                "pt-3": this._oilRate
            })}"
                                        />
                                      </div>

                                      <div class="mt-2 d-flex power-cycle-container">
                                      <label class="mr-auto">Power cycle</label>
                                          <div class="custom-control custom-radio custom-control-inline">
                                              <input
                                                  type="radio"
                                                  id=${POWER_CYCLES.FIFTY_HZ}
                                                  name="powerCycleRadioButton"
                                                  class="custom-control-input"
                                                  checked=${this.powerCycles === POWER_CYCLES.FIFTY_HZ}
                                                  @click=${onClickRadioButton}
                                              />
                                              <label
                                                  class="custom-control-label"
                                                  for=${POWER_CYCLES.FIFTY_HZ}
                                                  >50 hz</label
                                              >
                                          </div>
                                          <div
                                              class="custom-control custom-radio custom-control-inline"
                                          >
                                              <input
                                                  type="radio"
                                                  id=${POWER_CYCLES.SIXTY_HZ}
                                                  name="powerCycleRadioButton"
                                                  class="custom-control-input"
                                                  checked=${this.powerCycles === POWER_CYCLES.SIXTY_HZ}
                                                  @click=${onClickRadioButton}
                                              />
                                              <label
                                                  class="custom-control-label"
                                                  for=${POWER_CYCLES.SIXTY_HZ}
                                                  >60 hz</label
                                              >
                                          </div>
                                      </div>
                                  </section>
                              `}
                    </section>
                </div>
            </div>
        `;
    }
    render() {
        const onClick = (e) => {
            window.dispatchEvent(new Event(EVENTS.CLICK_OUT));
        };
        const onClickClose = (e) => {
            e.stopPropagation();
            window.dispatchEvent(new Event(EVENTS.CLOSE_MODAL));
        };
        const onClickSave = async (e) => {
            e.stopPropagation();
            const saved = await this.save();
            if (!saved) {
                alert('Failed to update site costs:  ' + getLastError());
                window.dispatchEvent(new Event(EVENTS.CLOSE_MODAL));
            }
            else {
                const event = new CustomEvent(EVENTS.CLOSE_MODAL, {
                    detail: { refresh: true },
                });
                window.dispatchEvent(event);
            }
        };
        return html `
        <link id="css-target">
        <div class="css-loading" id="root" @click=${onClick}>
            <section
                class="modal show"
                tabindex="-1"
                role="dialog"
                style="display: block; padding-right: 15px;"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <section class="modal-content">
                        <section class="modal-header">
                            <h5 class="modal-title">Update Site Costs</h5>
                            <button
                                type="button"
                                class="close"
                                aria-label="Close"
                                @click=${onClickClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </section>
                        <section class="modal-body">
                            ${this.renderContent()}
                        </section>
                        <section class="modal-footer">
                            <div class="button-container mx-auto">
                                <button
                                    type="button"
                                    class="btn btn-secondary mr-2"
                                    @click=${onClickClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    .disabled=${!this.saveEnabled}
                                    type="button"
                                    class="btn btn-info ml-2"
                                    @click=${onClickSave}
                                >
                                    Save
                                </button>
                            </div>
                        </section>
                    </section>
                </div>
            </section>
        </div>`;
    }
};
__decorate([
    property({ attribute: 'site-id' })
], BernerUpdateSiteCostsModal.prototype, "siteId", void 0);
__decorate([
    state()
], BernerUpdateSiteCostsModal.prototype, "_saving", void 0);
__decorate([
    state()
], BernerUpdateSiteCostsModal.prototype, "powerCycles", void 0);
__decorate([
    state()
], BernerUpdateSiteCostsModal.prototype, "_electricityRate", void 0);
__decorate([
    state()
], BernerUpdateSiteCostsModal.prototype, "_gasRate", void 0);
__decorate([
    state()
], BernerUpdateSiteCostsModal.prototype, "_oilRate", void 0);
BernerUpdateSiteCostsModal = __decorate([
    customElement('berner-update-site-costs-modal')
], BernerUpdateSiteCostsModal);
export { BernerUpdateSiteCostsModal };
