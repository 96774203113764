import { __decorate } from "tslib";
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { EVENTS } from '../api/ui';
let BernerFrame = class BernerFrame extends LitElement {
    firstUpdated() {
        // consider Escape press as a click out
        window.document.onkeyup = (e) => {
            if (e.key === 'Escape') {
                window.dispatchEvent(new Event(EVENTS.CLICK_OUT));
            }
        };
    }
    render() {
        const onClick = (e) => {
            // detect unhandled clicks, used to close dropdowns etc
            window.dispatchEvent(new Event(EVENTS.CLICK_OUT));
        };
        return html `<head content="width=device-width, initial-scale=1">
                <link rel="stylesheet" href="./css/berner-frame.css" />
            </head>

            <main @click=${onClick}>
                <slot></slot>
            </main>`;
    }
};
BernerFrame = __decorate([
    customElement('berner-frame')
], BernerFrame);
export { BernerFrame };
