import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map';
import { router } from '../index';
import { Router } from '@vaadin/router';
import { EVENTS, loadStyle, tempUnitButtonClickHandler, getTempUnitCelsiusSelected } from '../api/ui';
import { store } from '../redux/store';
import { setNavBarCollapsedState } from '../redux/actions';
let BernerContentNavigation = class BernerContentNavigation extends LitElement {
    constructor() {
        super(...arguments);
        this.location = router.location;
        this._pageName = '';
        this._collapsed = false;
        this._lessThan1367px = false;
        this.onResize = () => {
            if (window.matchMedia('(max-width: 1366.98px)').matches) {
                this._lessThan1367px = true;
                this.collapsed = false;
            }
            else {
                this._lessThan1367px = false;
            }
        };
        this.onNavigated = async (e) => {
            var _a;
            if ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.pageName) {
                this._pageName = e.detail.pageName;
            }
        };
        this.firstUpdated = async () => {
            const path = location.pathname.split('/');
            this._pageName = path[path.length - 1];
            await loadStyle('./css/berner-content-navigation.css', this.shadowRoot);
            if (window.matchMedia('(max-width: 1366.98px)').matches) {
                this._lessThan1367px = true;
                this.collapsed = false;
            }
            this.requestUpdate();
        };
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    set collapsed(isCollapsed) {
        this._collapsed = isCollapsed;
        store.dispatch(setNavBarCollapsedState(this._collapsed));
        const event = new CustomEvent(EVENTS.NAV_BAR_COLLAPSE_CHANGE);
        window.dispatchEvent(event);
    }
    get collapsed() {
        return this._collapsed;
    }
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener(EVENTS.NAVIGATE, this.onNavigated);
        window.addEventListener('resize', this.onResize);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener(EVENTS.NAVIGATE, this.onNavigated);
        window.removeEventListener('resize', this.onResize);
    }
    renderSideBar() {
        const isCelsiusSelected = getTempUnitCelsiusSelected();
        return html `
            <section class="side-main">
                <div class="content-container">
                    <section
                        class="content-row d-none d-xl-flex content-row-1 mb-5"
                    >
                        <section
                            class=${classMap({
            'overflow-hidden': true,
            'col-10': !this.collapsed,
            'col-12': this.collapsed,
        })}
                        >
                            <div
                                class=${classMap({
            logo: !this.collapsed,
            'logo-collapsed': this.collapsed,
        })}
                            >
                                <button
                                    class="collapse-button"
                                    @click=${() => {
            if (this.collapsed) {
                this.collapsed = false;
            }
        }}
                                >
                                    <img
                                        src="./assets/${this.collapsed
            ? 'bernerB.svg'
            : 'logo_white.svg'}"
                                        alt="Berner logo"
                                        class=${classMap({
            'berner-b': this.collapsed,
            'mx-auto': this.collapsed,
        })}
                                    />
                                </button>
                            </div>
                        </section>

                        <section
                            class=${classMap({
            'col-1': !this.collapsed,
            'p-0': !this.collapsed,
            'overflow-hidden': false,
            'd-none': this.collapsed,
        })}
                        >
                            <button
                                class="collapse-button"
                                @click=${() => {
            this.collapsed = !this.collapsed;
        }}
                            >
                                <img
                                    src="./assets/icon_back_arrow.svg"
                                    alt="collapse"
                                />
                            </button>
                        </section>
                    </section>

                    <section class="content-row d-lg-flex content-row-2">
                        <div class="links">
                            <div class="${classMap({ 'temp-buttons-container': true, 'collapsed': this.collapsed })}">                            
                                    <button type="button" class="${classMap({ 'btn': true, 'btn-primary': true, 'temp-select-button': true, 'selected': isCelsiusSelected === false })}"
                                        @click=${(e) => {
            if (!isCelsiusSelected)
                return;
            tempUnitButtonClickHandler(e);
            const event = new CustomEvent(EVENTS.CHANGE_TEMP_UNIT);
            window.dispatchEvent(event);
            this.requestUpdate();
        }}>Fahrenheit</button>

                                    <button type="button" class="${classMap({ 'btn': true, 'btn-primary': true, 'temp-select-button': true, 'selected': isCelsiusSelected === true })}" 
                                        @click=${(e) => {
            if (isCelsiusSelected)
                return;
            tempUnitButtonClickHandler(e);
            const event = new CustomEvent(EVENTS.CHANGE_TEMP_UNIT);
            window.dispatchEvent(event);
            this.requestUpdate();
        }}>Celsius</button>
                            </div>

                            <a
                                class="${classMap({
            'link-item': true,
            dashboard: true,
            highlight: this._pageName === 'dashboard',
            'mx-auto': this.collapsed,
            'pr-0': this.collapsed,
        })}"
                                href="content/dashboard"
                                @click=${() => {
            this._pageName = 'dashboard';
        }}
                            >
                                <span
                                    class=${classMap({
            'd-none': this.collapsed,
        })}
                                    >${this._lessThan1367px === true
            ? 'Dash'
            : 'Dashboard'}</span
                                >
                            </a>
                            <a
                                class="${classMap({
            'link-item': true,
            sites: true,
            highlight: this._pageName === 'organization',
            'mx-auto': this.collapsed,
            'pr-0': this.collapsed,
        })}"
                                href="content/organization"
                                @click=${() => {
            this._pageName = 'organization';
        }}
                            >
                                <span
                                    class=${classMap({
            'd-none': this.collapsed,
        })}
                                >
                                    ${this._lessThan1367px === true
            ? 'Org'
            : 'Organization'}</span
                                >
                            </a>
                            <a
                                class="${classMap({
            'link-item': true,
            profile: true,
            highlight: this._pageName === 'profile',
            'mx-auto': this.collapsed,
            'pr-0': this.collapsed,
        })}"
                                href="content/profile"
                                @click=${() => {
            this._pageName = 'profile';
        }}
                            >
                                <span
                                    class=${classMap({
            'd-none': this.collapsed,
        })}
                                    >Profile</span
                                >
                            </a>
                            <div class="d-block d-lg-none py-auto">
                                <button
                                    class="log-out mx-auto pr-0 link-item"
                                    @click=${() => {
            Router.go('auth/login');
        }}
                                >
                                    <img
                                        src="./assets/icon_logout.png"
                                        alt="Log out"
                                    />
                                </button>
                            </div>
                        </div>
                    </section>
                    <section class="content-row d-none d-lg-flex content-row-3">
                        <div>
                            <button
                                class=${classMap({
            'log-out': true,
            'float-right': !this.collapsed,
            'ml-3': !this.collapsed,
            'mr-3': this.collapsed,
        })}
                                @click=${() => {
            Router.go('auth/login');
        }}
                            >
                                <img
                                    src="./assets/icon_logout.png"
                                    alt="Log out"
                                />
                            </button>
                        </div>
                    </section>
                </div>
            </section>
        `;
    }
    render() {
        return html `
        <head content="width=device-width, initial-scale=1"> </head>
            <link id="css-target">
            <div class="css-loading" id="root">
                <main class="container-fluid">
                    <section class="row content-row">
                        <aside
                            id="sidebar"
                            class=${classMap({
            'col-lg-2': !this.collapsed,
            'col-lg-1': this.collapsed,
            'p-0': true,
            sidebar: true,
        })}
                        >
                            ${this.renderSideBar()}
                        </aside>

                        <section
                            class=${classMap({
            'col-lg-10': !this.collapsed,
            'col-lg-11': this.collapsed,
            mainbody: true,
            'overflow-auto': true,
        })}
                        >
                            <slot></slot>
                        </section>
                    </section>
                </main>
                <div></div>
            </div>`;
    }
};
__decorate([
    property({ type: Object })
], BernerContentNavigation.prototype, "location", void 0);
__decorate([
    state()
], BernerContentNavigation.prototype, "_pageName", void 0);
__decorate([
    state()
], BernerContentNavigation.prototype, "_collapsed", void 0);
__decorate([
    state()
], BernerContentNavigation.prototype, "_lessThan1367px", void 0);
BernerContentNavigation = __decorate([
    customElement('berner-content-navigation')
], BernerContentNavigation);
export { BernerContentNavigation };
