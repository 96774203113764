import { setMeasurementData, setOldMeasurementData, setMeasurementDataCachedState, setMeasurementDataCachingInProgressState, setPreloadObjectsInProgressState, setPreloadObjectsState } from '../redux/actions';
import { store } from "../redux/store";
import { GRAPH_TYPES, MEASUREMENTS, RANGES, getIntervalFromRange, getDateStringsFromRange, dateToUTCDate, getAirCurtainData, getSiteWeatherData, getDateMinusRange } from '../api/reporting';
import { AGGREGATES_KEY, COLUMN_CHART_MEASUREMENTS, AREA_GRAPH_MEASUREMENTS, MEASUREMENTS_LACKING_24HR_DATA, LOCALLY_CALCULATED_MEASUREMENTS, getGrossSavingData, getLossData, getCostToOperateData, getSavingData, getCoolingCost, getHeatingCost, SITE_SPECIFIC_MEASUREMENTS } from '../api/graphDataApi';
import { EVENTS, isTimeStampLast24hrs } from './ui';
import { ObjectManager } from '../objectModelV2/objectManager';
import { Auth } from 'aws-amplify';
const FUNCTION_TYPES = {
    AVG: "avg",
    MIN: "min",
    MAX: "max",
    SUM: "sum"
};
export const MEASUREMENT_TO_FUNC = new Map([[MEASUREMENTS.AVERAGE_TEMP, FUNCTION_TYPES.AVG],
    [MEASUREMENTS.MIN_TEMP, FUNCTION_TYPES.AVG], [MEASUREMENTS.MAX_TEMP, FUNCTION_TYPES.AVG],
    [MEASUREMENTS.WEATHER_AVG_TEMP, FUNCTION_TYPES.AVG],
    [MEASUREMENTS.BTU, FUNCTION_TYPES.AVG], [MEASUREMENTS.TON_GAIN_RATE, FUNCTION_TYPES.AVG],
    [MEASUREMENTS.EFFICIENCY, FUNCTION_TYPES.AVG],
    [MEASUREMENTS.DOOR_OPEN_COUNT, FUNCTION_TYPES.SUM], [MEASUREMENTS.DOOR_OPEN_TIME, FUNCTION_TYPES.SUM],
    [MEASUREMENTS.COST_TO_OPERATE, FUNCTION_TYPES.SUM], [MEASUREMENTS.GROSS_SAVING, FUNCTION_TYPES.SUM],
    [MEASUREMENTS.LOSS, FUNCTION_TYPES.SUM], [MEASUREMENTS.SAVING, FUNCTION_TYPES.SUM]
]);
const localStorage = window.localStorage;
function mergeData(a, b) {
    if (a && b) {
        for (const device of a.keys()) {
            const dataB = b.get(device);
            if (dataB) {
                const dataA = a.get(device);
                for (const key of dataB.keys()) {
                    dataA.set(key, dataB.get(key));
                }
            }
        }
    }
    return a;
}
export function saveToLocalStorage(key, value) {
    localStorage.setItem(key, value);
}
export function getFromLocalStorage(key) {
    return localStorage.getItem(key);
}
export async function preloadObjects(objectManager) {
    store.dispatch(setPreloadObjectsInProgressState());
    await objectManager.getAllAirCurtains();
    await objectManager.getAllUsers();
    await objectManager.getAllIdentities();
    store.dispatch(setPreloadObjectsState(true));
}
export async function cacheAllMeasurementData(objectManager) {
    store.dispatch(setMeasurementDataCachingInProgressState());
    const siteGroup = objectManager.getSiteGroup();
    const siteToAirCurtains = new Map();
    // minimal data
    for (const siteId of siteGroup.sites) {
        const site = objectManager.getSite(siteId);
        for (const curtainGroupId of site.airCurtainGroups) {
            const airCurtainGroup = objectManager.getAirCurtainGroup(curtainGroupId);
            if (siteToAirCurtains.has(siteId)) {
                let airCurtainsIds = siteToAirCurtains.get(siteId);
                airCurtainsIds = airCurtainsIds.concat(airCurtainGroup.airCurtains);
                siteToAirCurtains.set(siteId, airCurtainsIds);
            }
            else {
                siteToAirCurtains.set(siteId, airCurtainGroup.airCurtains);
            }
        }
        const siteAirCurtains = siteToAirCurtains.get(siteId);
        if (siteAirCurtains === null || siteAirCurtains === void 0 ? void 0 : siteAirCurtains.length) {
            await cacheMeasurementData(objectManager, [RANGES.WEEKLY], siteAirCurtains, siteId);
        }
    }
    await cacheHistoricWeatherData([RANGES.WEEKLY], siteGroup.sites);
    window.dispatchEvent(new CustomEvent(EVENTS.MINIMAL_DATA_CACHED));
    // all data
    await cacheHistoricWeatherData(Object.values(RANGES), siteGroup.sites);
    for (const siteId of siteGroup.sites) {
        const siteAirCurtains = siteToAirCurtains.get(siteId);
        if (siteAirCurtains === null || siteAirCurtains === void 0 ? void 0 : siteAirCurtains.length) {
            // get all data including old  (last week, last month etc) for infographics comparison
            await cacheMeasurementData(objectManager, Object.values(RANGES), siteAirCurtains, siteId);
            await cacheMeasurementData(objectManager, Object.values(RANGES), siteAirCurtains, siteId, true);
        }
    }
    store.dispatch(setMeasurementDataCachedState(true));
    window.dispatchEvent(new CustomEvent(EVENTS.ALL_DATA_CACHED));
}
async function cacheHistoricWeatherData(ranges, siteIds) {
    const dataByRange = new Map();
    for (const range of ranges) {
        const weatherData = await getWeatherDataFromBackend(range, siteIds, [MEASUREMENTS.WEATHER_AVG_TEMP], getIntervalFromRange(range, GRAPH_TYPES.AREA), dateToUTCDate(new Date()) //now
        );
        dataByRange.set(range, weatherData);
    }
    const dataBySite = new Map();
    for (const entry of dataByRange.entries()) {
        const range = entry[0];
        const siteToMeasurements = entry[1];
        for (const siteToData of siteToMeasurements.entries()) {
            const siteId = siteToData[0];
            const measurementsToData = siteToData[1];
            const siteData = dataBySite.has(siteId) ? dataBySite.get(siteId) : [];
            for (const measurementToData of measurementsToData.entries()) {
                const measurement = measurementToData[0];
                const measurementData = measurementToData[1];
                if (!siteData[`${measurement}`]) {
                    siteData[`${measurement}`] = [];
                }
                for (const rangeData of measurementData.entries()) {
                    const graphData = rangeData[1];
                    siteData[`${measurement}`][`${range}`] = graphData.values;
                }
                dataBySite.set(siteId, siteData);
            }
        }
    }
    for (const entry of dataBySite.entries()) {
        const siteId = entry[0];
        const data = entry[1];
        store.dispatch(setMeasurementData(siteId, data));
    }
}
export async function cacheMeasurementDataForDevice(objectManager, airCurtainID, siteID) {
    // cache data for new air curtain
    await cacheMeasurementData(objectManager, Object.values(RANGES), [airCurtainID], siteID);
    await cacheMeasurementData(objectManager, Object.values(RANGES), [airCurtainID], siteID, true);
    // trigger a refresh
    window.dispatchEvent(new CustomEvent(EVENTS.DATA_UPDATED));
}
export async function cacheMeasurementData(objectManager, ranges, airCurtainIds, siteId, cacheOldData = false) {
    var _a, _b;
    const airCurtainIdChunks = [];
    const deviceMeasurements = new Map();
    let location;
    const requestedInstallationObjects = [];
    let installations = new Map();
    // batch requests by smaller groups of curtains to avoid response size limit on AWS
    const MAX_CHUNK = 100;
    let count = 0;
    let chunk = 0;
    for (const id of airCurtainIds) {
        requestedInstallationObjects.push({ thingName: id, siteID: siteId });
        if (!airCurtainIdChunks[chunk]) {
            airCurtainIdChunks[chunk] = [];
        }
        airCurtainIdChunks[chunk].push(id);
        count++;
        if (count % MAX_CHUNK === 0) {
            chunk++;
        }
    }
    if (!cacheOldData) {
        location = await objectManager.getLocation(siteId);
        installations = await objectManager.getInstallations(requestedInstallationObjects);
    }
    for (const deviceIds of airCurtainIdChunks) {
        const utcD = dateToUTCDate(new Date()); //now
        for (const range of ranges) {
            const rangeDataForAreaGraphs = await getMeasurementData(range, deviceIds.join(","), siteId, cacheOldData ? [MEASUREMENTS.AVERAGE_TEMP] : AREA_GRAPH_MEASUREMENTS, getIntervalFromRange(range, GRAPH_TYPES.AREA), cacheOldData ? getDateMinusRange(range, new Date()) : utcD);
            const rangeDataForBarGraphs = await getMeasurementData(range, deviceIds.join(","), siteId, cacheOldData ? [MEASUREMENTS.DOOR_OPEN_COUNT, MEASUREMENTS.EFFICIENCY] : COLUMN_CHART_MEASUREMENTS, getIntervalFromRange(range, GRAPH_TYPES.BAR), cacheOldData ? getDateMinusRange(range, new Date()) : utcD);
            const allRangeData = mergeData(rangeDataForAreaGraphs, rangeDataForBarGraphs);
            if (allRangeData) {
                for (const deviceData of allRangeData) {
                    const deviceId = deviceData[0];
                    const graphData = deviceData[1];
                    const deviceDataByMeasurement = (_a = deviceMeasurements.get(deviceId)) !== null && _a !== void 0 ? _a : {};
                    const measurementTypes = Array.from(graphData.keys());
                    for (const measurement of measurementTypes) {
                        const measurementData = (_b = deviceDataByMeasurement[`${measurement}`]) !== null && _b !== void 0 ? _b : {};
                        measurementData[`${range}`] = graphData.get(measurement);
                        deviceDataByMeasurement[`${measurement}`] = measurementData;
                    }
                    if (!cacheOldData) {
                        const installation = installations.get(siteId + "|" + deviceId);
                        if (installation && location) {
                            const coolingCost = getCoolingCost(location.electricityRate, location.naturalGasRate, location.oilRate, installation.coolingEnergyType);
                            const heatingCost = getHeatingCost(location.electricityRate, location.naturalGasRate, location.oilRate, installation.heatingEnergyType);
                            locallyCalculateMeasurementData(deviceDataByMeasurement, range, coolingCost, heatingCost, location.electricityRate, installation.model);
                        }
                    }
                    deviceMeasurements.set(deviceId, deviceDataByMeasurement);
                }
            }
        }
    }
    for (const deviceId of Array.from(deviceMeasurements.keys())) {
        const func = cacheOldData ? setOldMeasurementData(deviceId, deviceMeasurements.get(deviceId)) : setMeasurementData(deviceId, deviceMeasurements.get(deviceId));
        store.dispatch(func);
    }
}
function locallyCalculateMeasurementData(deviceDataByMeasurement, range, coolingCost, heatingCost, electricityRate, model) {
    const btuData = deviceDataByMeasurement[`${MEASUREMENTS.BTU}`];
    const tonGainRateData = deviceDataByMeasurement[`${MEASUREMENTS.TON_GAIN_RATE}`];
    const efficiencyData = deviceDataByMeasurement[`${MEASUREMENTS.EFFICIENCY}`];
    const doorOpenTimeData = deviceDataByMeasurement[`${MEASUREMENTS.DOOR_OPEN_TIME}`];
    // GROSS_SAVING
    if ((btuData && btuData[`${range}`]) && (tonGainRateData && tonGainRateData[`${range}`]) && (efficiencyData && efficiencyData[`${range}`])) {
        const grossSavingData = getGrossSavingData(efficiencyData[`${range}`], btuData[`${range}`], tonGainRateData[`${range}`], coolingCost, heatingCost);
        if (grossSavingData.length) {
            if (deviceDataByMeasurement[`${MEASUREMENTS.GROSS_SAVING}`] === undefined) {
                deviceDataByMeasurement[`${MEASUREMENTS.GROSS_SAVING}`] = {};
            }
            deviceDataByMeasurement[`${MEASUREMENTS.GROSS_SAVING}`][`${range}`] = grossSavingData;
        }
    }
    //LOSS
    if ((btuData && btuData[`${range}`]) && (tonGainRateData && tonGainRateData[`${range}`])) {
        const lossData = getLossData(btuData[`${range}`], tonGainRateData[`${range}`], coolingCost, heatingCost);
        if (lossData.length) {
            if (deviceDataByMeasurement[`${MEASUREMENTS.LOSS}`] === undefined) {
                deviceDataByMeasurement[`${MEASUREMENTS.LOSS}`] = {};
            }
            deviceDataByMeasurement[`${MEASUREMENTS.LOSS}`][`${range}`] = lossData;
        }
    }
    //COST TO OPERATE
    if (doorOpenTimeData && doorOpenTimeData[`${range}`]) {
        const costToOperateData = getCostToOperateData(doorOpenTimeData[`${range}`], model, electricityRate);
        if (costToOperateData.length) {
            if (deviceDataByMeasurement[`${MEASUREMENTS.COST_TO_OPERATE}`] === undefined) {
                deviceDataByMeasurement[`${MEASUREMENTS.COST_TO_OPERATE}`] = {};
            }
            deviceDataByMeasurement[`${MEASUREMENTS.COST_TO_OPERATE}`][`${range}`] = costToOperateData;
        }
    }
    //SAVINGS
    const grossSavingData = deviceDataByMeasurement[`${MEASUREMENTS.GROSS_SAVING}`];
    const costToOperateData = deviceDataByMeasurement[`${MEASUREMENTS.COST_TO_OPERATE}`];
    if ((grossSavingData && grossSavingData[`${range}`]) && (costToOperateData && costToOperateData[`${range}`])) {
        const savingData = getSavingData(grossSavingData[`${range}`], costToOperateData[`${range}`]);
        if (savingData.length) {
            if (deviceDataByMeasurement[`${MEASUREMENTS.SAVING}`] === undefined) {
                deviceDataByMeasurement[`${MEASUREMENTS.SAVING}`] = {};
            }
            deviceDataByMeasurement[`${MEASUREMENTS.SAVING}`][`${range}`] = savingData;
        }
    }
}
export async function getCachedData(range, deviceIds, requestMeasurements, shouldAggregate, useOldData, siteId) {
    const { measurementData, measurementDataOld } = store.getState();
    const data = useOldData ? measurementDataOld : measurementData;
    const deviceToData = new Map();
    for (const deviceId of deviceIds) {
        const measurementsToData = new Map();
        let deviceData = data[`${deviceId}`];
        for (const measurement of requestMeasurements) {
            const isSiteSpecific = SITE_SPECIFIC_MEASUREMENTS.includes(measurement);
            deviceData = (isSiteSpecific ? data[`${siteId}`] : deviceData);
            if (deviceData) {
                const measurementData = deviceData[`${measurement}`];
                const graphData = measurementData ? measurementData[`${range}`] : [];
                measurementsToData.set(measurement, graphData);
            }
        }
        deviceToData.set(deviceId, measurementsToData);
    }
    if (shouldAggregate) {
        const aggregates = {};
        for (const measurement of requestMeasurements) {
            if (measurement.trim() !== "") {
                aggregates[measurement] = [MEASUREMENT_TO_FUNC.get(measurement)];
            }
        }
        const thingName = "thingName";
        const aggregate = { thingName: "aggregates" };
        const promises = [];
        for (const measurement in aggregates) {
            const functionTypes = aggregates[measurement];
            functionTypes.forEach((func) => {
                promises.push(computeAggregation(aggregate, Object.fromEntries(deviceToData), measurement, func));
            });
        }
        await Promise.all(promises);
        const aggregatedMeasures = Object.keys(aggregate).filter(key => key !== thingName);
        const aggregationData = new Map();
        for (const measure of aggregatedMeasures) {
            const aggMeasure = aggregate[measure];
            const aggValues = aggMeasure.values;
            aggregationData.set(measure, aggValues);
        }
        deviceToData.set(AGGREGATES_KEY, aggregationData);
    }
    return deviceToData;
}
async function getMeasurementData(range, deviceIds, siteId, requestMeasurements, interval, endDate = dateToUTCDate(new Date())) {
    const backendMeasurements = requestMeasurements.filter((measurement) => { return !LOCALLY_CALCULATED_MEASUREMENTS.includes(measurement); })
        .filter((measurement) => { return measurement !== MEASUREMENTS.WEATHER_AVG_TEMP; });
    const data = await getDataFromBackend(range, deviceIds, siteId, backendMeasurements, false, interval, endDate);
    const devicesToData = new Map();
    for (const entry of data.entries()) {
        const measurementToData = new Map();
        const deviceId = entry[0];
        const measurementsToIntervalsToData = entry[1];
        for (const measurement of backendMeasurements) {
            const intervalToData = measurementsToIntervalsToData.get(measurement);
            let graphDataContainer; // {startTimestamp: 0, endTimestamp: 0, values: GraphData[]};
            if (intervalToData && intervalToData.size) {
                graphDataContainer = intervalToData.has(interval) ? intervalToData.get(interval) : [];
                measurementToData.set(measurement, graphDataContainer.values);
            }
        }
        devicesToData.set(deviceId, measurementToData);
    }
    return devicesToData.size ? devicesToData : null;
}
const computeAggregation = async (aggregate, aircurtains, measurement, func) => {
    var _a, _b;
    const aggregateName = func + "(" + measurement + ")";
    aggregate[aggregateName] = {
        "startTimestamp": Number.MAX_VALUE,
        "endTimestamp": -Number.MAX_VALUE,
        "values": {},
        "counts": {}
    };
    const targetValues = aggregate[aggregateName].values;
    const targetCounts = aggregate[aggregateName].counts;
    for (const key in aircurtains) {
        const values = aircurtains[key].get(measurement) ? aircurtains[key].get(measurement) : [];
        const startTimestamp = (_a = aircurtains[key].get(measurement)) === null || _a === void 0 ? void 0 : _a.startTimestamp;
        const endTimestamp = (_b = aircurtains[key].get(measurement)) === null || _b === void 0 ? void 0 : _b.endTimestamp;
        if (startTimestamp < aggregate[aggregateName].startTimestamp) {
            aggregate[aggregateName].startTimestamp = startTimestamp;
        }
        if (endTimestamp > aggregate[aggregateName].endTimestamp) {
            aggregate[aggregateName].endTimestamp = endTimestamp;
        }
        let lastValidValue = 0;
        for (const gData of values) {
            if (!(gData.timestamp in targetValues)) {
                switch (func) {
                    case 'max':
                        targetValues[gData.timestamp] = -Number.MAX_VALUE;
                        break;
                    case 'min':
                        targetValues[gData.timestamp] = Number.MAX_VALUE;
                        break;
                    default:
                        targetValues[gData.timestamp] = 0;
                }
                targetCounts[gData.timestamp] = 0;
            }
            let value = gData.value;
            switch (func) {
                case 'max':
                    if (gData.value > targetValues[gData.timestamp]) {
                        targetValues[gData.timestamp] = value;
                    }
                    break;
                case 'min':
                    if (gData.value < targetValues[gData.timestamp]) {
                        targetValues[gData.timestamp] = value;
                    }
                    break;
                default:
                    if (isTimeStampLast24hrs(gData.timestamp) && MEASUREMENTS_LACKING_24HR_DATA.includes(measurement)) {
                        value = lastValidValue;
                    }
                    else {
                        lastValidValue = value;
                    }
                    targetValues[gData.timestamp] += value;
            }
            targetCounts[gData.timestamp]++;
        }
    }
    const temp = [];
    for (const key in targetValues) {
        if (func == 'avg') {
            targetValues[key] /= targetCounts[key];
        }
        temp.push({ "timestamp": key, "value": targetValues[key] });
    }
    delete aggregate[aggregateName].counts;
    aggregate[aggregateName].values = temp;
    if (temp.length == 0) {
        aggregate[aggregateName].startTimestamp = undefined;
        aggregate[aggregateName].endTimestamp = undefined;
    }
};
export async function getDataFromBackend(range, deviceIds, siteId, measurements, aggregate, interval, endDate = dateToUTCDate(new Date())) {
    // one or many devices?
    const devices = deviceIds.indexOf(',') ? deviceIds.split(',') : [deviceIds];
    const siteIDThingNamePairs = [];
    for (const devicesId of devices) {
        siteIDThingNamePairs.push({ "ThingName": devicesId, "SiteID": siteId });
    }
    const requestMeasurements = [];
    for (const measurement of measurements) {
        if (measurement.trim().length) {
            const aggregationType = measurement === MEASUREMENTS.DOOR_OPEN_COUNT ? 'sum' : 'avg';
            requestMeasurements.push(aggregate ? `${aggregationType}(${measurement})` : measurement);
        }
    }
    const dates = getDateStringsFromRange(range, endDate);
    const response = await getAirCurtainData(devices, siteIDThingNamePairs, [interval], requestMeasurements, dates.startDate, dates.endDate);
    const deviceToMeasurementsToIntervalData = new Map();
    if (response === null || response === void 0 ? void 0 : response.length) {
        // one mData per device/<energy data> one per device/<non energy data> (data by siteId/aircurtainId vs data by siteId)
        response.forEach((mData) => {
            const measurementsToIntervalData = new Map();
            const thingName = mData['thingName'];
            const keys = Object.keys(mData);
            const measurements = keys.filter(key => key !== 'thingName');
            for (const measurement of measurements) {
                const intervalsToData = mData[measurement];
                const intervalsToDataMap = new Map();
                for (const interval of Object.keys(intervalsToData)) {
                    intervalsToDataMap.set(interval, intervalsToData[interval]);
                }
                measurementsToIntervalData.set(measurement, intervalsToDataMap);
            }
            if (deviceToMeasurementsToIntervalData.has(thingName)) {
                const existingMeasurementsToIntervalData = deviceToMeasurementsToIntervalData.get(thingName);
                const mergedMeasurementsToIntervalData = new Map([...measurementsToIntervalData, ...existingMeasurementsToIntervalData]);
                deviceToMeasurementsToIntervalData.set(thingName, mergedMeasurementsToIntervalData);
            }
            else {
                deviceToMeasurementsToIntervalData.set(thingName, measurementsToIntervalData);
            }
        });
    }
    return deviceToMeasurementsToIntervalData;
}
export async function getWeatherDataFromBackend(range, siteIds, measurements, interval, endDate = dateToUTCDate(new Date())) {
    const dates = getDateStringsFromRange(range, endDate);
    const response = await getSiteWeatherData(siteIds, [interval], measurements, dates.startDate, dates.endDate);
    const dataBySite = new Map();
    if (response === null || response === void 0 ? void 0 : response.length) {
        response.forEach((siteData) => {
            const siteId = siteData["siteID"];
            const measurements = Object.keys(siteData).filter((key) => key !== "siteID");
            // measurement to map of interval to GraphData
            const measurementDataByInterval = new Map();
            for (const measurement of measurements) {
                const measurementData = siteData[`${measurement}`];
                const intervals = Object.keys(measurementData);
                const intervalToGraphData = new Map();
                for (interval of intervals) {
                    const graphData = measurementData[`${interval}`];
                    intervalToGraphData.set(interval, graphData);
                }
                measurementDataByInterval.set(measurement, intervalToGraphData);
            }
            dataBySite.set(siteId, measurementDataByInterval);
        });
    }
    return dataBySite;
}
export async function initObjectManager(username) {
    let initialized = false;
    const session = await Auth.currentSession();
    const identityID = session.getAccessToken().payload.sub;
    // identityID is the same as the cognito sub (UUID) for the user
    const objectManager = new ObjectManager(identityID, username);
    initialized = await objectManager.initialize();
    return { objectManager, initialized };
}
