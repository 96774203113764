import { Auth } from 'aws-amplify';
import { AirCurtainGroup } from '../objectModelV2/airCurtainGroup';
import { UserGroup } from '../objectModelV2/userGroup';
import { dateToUTCDate, getDateMinusRange, RANGES } from '../api/reporting';
import { html } from 'lit';
import { classMap } from 'lit/directives/class-map';
import { repeat } from 'lit/directives/repeat';
import { saveToLocalStorage, getFromLocalStorage } from '../api/dataManagementApi';
const googleMaps = globalThis.google.maps;
const geocoder = new googleMaps.Geocoder();
const autocompleteService = new googleMaps.places.AutocompleteService();
let _lastError = undefined;
export function getLastError() {
    return _lastError;
}
export function setLastError(error) {
    _lastError = error;
}
let _tempUnitCelsiusSelected = getFromLocalStorage('temperature-unit') === 'celsius';
export function getTempUnitCelsiusSelected() {
    return _tempUnitCelsiusSelected;
}
export function setTempUnitCelsiusSelected(tempUnitCelsiusSelected) {
    saveToLocalStorage('temperature-unit', tempUnitCelsiusSelected ? 'celsius' : 'fahrenheit');
    _tempUnitCelsiusSelected = tempUnitCelsiusSelected;
}
export function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}
export const getTimeElapsed = (timeStamp) => {
    const old = dateToUTCDate(new Date(timeStamp * 1000)).getTime();
    const now = dateToUTCDate(new Date()).getTime();
    const diffMS = now - old;
    const hours = Math.floor(diffMS / (1000 * 3600));
    const days = Math.floor(diffMS / (1000 * 3600 * 24));
    const weeks = Math.floor(diffMS / (1000 * 3600 * 24 * 7));
    const months = Math.floor(diffMS / (1000 * 3600 * 24 * 30));
    const years = Math.floor(diffMS / (1000 * 3600 * 24 * 365));
    let elapsed = '';
    if (years) {
        elapsed = `${years} year${years > 1 ? 's' : ''} ago`;
    }
    else if (months) {
        elapsed = `${months} month${months > 1 ? 's' : ''} ago`;
    }
    else if (weeks) {
        elapsed = `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    }
    else if (days) {
        elapsed = `${days} day${days > 1 ? 's' : ''} ago`;
    }
    else if (hours) {
        elapsed = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    else {
        elapsed = `minutes ago`;
    }
    return elapsed;
};
export const orderAirCurtains = (airCurtainsBySite) => {
    const airCurtainSitePairs = [];
    for (const site of airCurtainsBySite.keys()) {
        const siteCurtains = airCurtainsBySite.get(site);
        for (const curtain of siteCurtains) {
            airCurtainSitePairs.push({ curtain, site });
        }
    }
    const connectedCurtainsNoTimeStamps = airCurtainSitePairs.filter((a => a.curtain.connected && !a.curtain.connectivityTimestamp));
    const connectedCurtainsWithTimeStamps = airCurtainSitePairs.filter((a => a.curtain.connected && a.curtain.connectivityTimestamp));
    const unConnectedCurtainsNoTimeStamps = airCurtainSitePairs.filter((a => !a.curtain.connected && !a.curtain.connectivityTimestamp));
    const unConnectedCurtainsWithTimeStamps = airCurtainSitePairs.filter((a => !a.curtain.connected && a.curtain.connectivityTimestamp));
    const sortbyLastConnected = (a, b) => { return a.curtain.connectivityTimestamp > b.curtain.connectivityTimestamp ? -1 : 1; };
    const sortbyName = (a, b) => { return a.curtain.name.toUpperCase() < b.curtain.name.toUpperCase() ? -1 : 1; };
    connectedCurtainsNoTimeStamps.sort(sortbyName);
    connectedCurtainsWithTimeStamps.sort(sortbyLastConnected);
    unConnectedCurtainsNoTimeStamps.sort(sortbyName);
    unConnectedCurtainsWithTimeStamps.sort(sortbyLastConnected);
    return connectedCurtainsWithTimeStamps.concat(connectedCurtainsNoTimeStamps).concat(unConnectedCurtainsWithTimeStamps).concat(unConnectedCurtainsNoTimeStamps);
};
export function isTimeStampLast24hrs(timestamp) {
    const date = new Date();
    date.setTime(timestamp * 1000);
    const yesterday = getDateMinusRange(RANGES.ONE_DAY, dateToUTCDate(new Date()));
    yesterday.setHours(0);
    return date > yesterday;
}
export const LANDING_PAGE = 'content/dashboard';
export const ROLES = {
    OWNER: 'Owner',
    USER: 'User',
    REP: 'Rep'
};
export const ROLES_TO_LABELS = new Map([
    [ROLES.OWNER, 'Owner'],
    [ROLES.USER, 'User'],
    [ROLES.REP, 'Representative']
]);
export const ORGANIZATION_TABS = {
    OVERVIEW: 'OVERVIEW',
    AIRCURTAINS: 'AIRCURTAINS'
};
export const MODAL_ACTIONS = {
    DELETE_CURTAIN: 'DELETE_CURTAIN',
    DELETE_SITE: 'DELETE_SITE',
    SEND_INVITE: 'SEND_INVITE'
};
export const RANGE_VALUES_TO_LABELS = new Map([
    ['one_day', '24 hours'],
    ['two_days', '48 hours'],
    ['weekly', 'Weekly'],
    ['monthly', 'Monthly'],
    ['yearly', 'Yearly'],
]);
export const MEASUREMENT_VALUES_TO_LABELS = new Map([
    ['avgTemperature', 'Average Temperature'],
    ['maxTemperature', 'Max Temperature'],
    ['minTemperature', 'Min Temperature'],
    ['doorOpenCount', 'Door Open Count'],
    ['btu', 'BTU'],
    ['tonGainRate', 'Ton Gain Rate'],
    ['loss', 'Loss'],
    ['grossSaving', 'Gross Saving'],
    ['costToOperate', 'Cost To Operate'],
    ['savings', 'Savings'],
    ['doorOpenTime', 'Door Open Time'],
    ['efficiency', 'Efficiency'],
]);
export const UNITS_SPACE_PREFIX = ["min", "hour", "Btu", "TR"];
export const UNITS_PLURAL = ["min", "hour"];
export const UNITS_PRECEDING_VALUE = ["$"];
export const validatePasswordString = (passwordInput) => {
    return Boolean(passwordInput.trim()) && passwordInput.trim().length > 6;
};
export const validateEmailString = (emailInput) => {
    if (!emailInput.trim())
        return false;
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailInput.match(mailFormat) !== null;
};
export const validateAddress = (inputText) => {
    return Boolean(inputText === null || inputText === void 0 ? void 0 : inputText.trim()) && (inputText === null || inputText === void 0 ? void 0 : inputText.trim().length) >= 5;
};
export const APP_STATES = {
    INITIAL_SIGNUP: 'INITIAL_SIGNUP',
    LOGGING_IN: 'LOGGING_IN',
    LOGGED_IN: 'LOGGED_IN',
    PASSWORD_RESET: 'PASSWORD_RESET',
    PASSWORD_RESET_VALIDATE: 'PASSWORD_RESET_VALIDATE',
    CREATE_ORG: 'CREATE_ORG'
};
export const EVENTS = {
    CLICK_OUT: 'CLICK_OUT',
    CLOSE_MODAL: 'CLOSE_MODAL',
    NAVIGATE: 'NAVIGATE',
    SITE_SELECTED: 'SITE_SELECTED',
    CURTAIN_SELECTED: 'CURTAIN_SELECTED',
    NAV_BAR_COLLAPSE_CHANGE: 'NAV_BAR_COLLAPSE_CHANGE',
    CHART_RENDERED: 'CHART_RENDERED',
    MINIMAL_DATA_CACHED: 'MINIMAL_DATA_CACHED',
    ALL_DATA_CACHED: 'ALL_DATA_CACHED',
    DATA_UPDATED: 'DATA_UPDATED',
    SHOW_REMOVE_CURTAIN: 'SHOW_REMOVE_CURTAIN',
    SHOW_UPDATE_CURTAIN: 'SHOW_UPDATE_CURTAIN',
    SHOW_CURTAIN_INFO: 'SHOW_CURTAIN_INFO',
    CHANGE_TEMP_UNIT: 'CHANGE_TEMP_UNIT'
};
export var POWER_CYCLES;
(function (POWER_CYCLES) {
    POWER_CYCLES[POWER_CYCLES["FIFTY_HZ"] = 0] = "FIFTY_HZ";
    POWER_CYCLES[POWER_CYCLES["SIXTY_HZ"] = 1] = "SIXTY_HZ";
})(POWER_CYCLES || (POWER_CYCLES = {}));
export const SORT_DIRECTIONS = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
};
export var APPLICATION_TYPES;
(function (APPLICATION_TYPES) {
    APPLICATION_TYPES[APPLICATION_TYPES["OUTSIDE"] = 1] = "OUTSIDE";
    APPLICATION_TYPES[APPLICATION_TYPES["UNCONDITIONED"] = 2] = "UNCONDITIONED";
})(APPLICATION_TYPES || (APPLICATION_TYPES = {}));
export var ENERGY_TYPES;
(function (ENERGY_TYPES) {
    ENERGY_TYPES["ELECTRIC"] = "e";
    ENERGY_TYPES["GAS"] = "g";
    ENERGY_TYPES["OIL"] = "o";
})(ENERGY_TYPES || (ENERGY_TYPES = {}));
export function closeDropdownMenus(shadowRoot) {
    const dropDownBtns = shadowRoot.querySelectorAll('.dropdown-toggle');
    for (const btn of dropDownBtns) {
        btn.setAttribute('aria-expanded', 'false');
    }
    const dropDownMenus = shadowRoot.querySelectorAll('.dropdown-menu');
    for (const menu of dropDownMenus) {
        menu.classList.remove('show');
    }
}
export async function inviteUser(userName, email, objectManager) {
    const siteGroup = objectManager.getSiteGroup();
    const site = objectManager.getSite(siteGroup.sites[0]);
    const user = objectManager.createUser(userName, '', '');
    const userGroup = objectManager.getUserGroup(site.userGroups[0]);
    const roles = objectManager.getAllRoles();
    const userRoleIndex = roles.findIndex((role) => {
        return role.name == 'User';
    });
    // link role to user
    objectManager.addRoleToUser(user, roles[userRoleIndex]);
    // link user to user group
    objectManager.addUserToUserGroup(userGroup, user);
    // save modified objects
    const userSaved = await user.save();
    if (!userSaved) {
        console.error(user.lastError);
        await user.delete();
        return false;
    }
    const userGroupSaved = await userGroup.save();
    if (!userGroupSaved) {
        console.error(userGroup.lastError);
        await user.delete();
        return false;
    }
    user.headObjectID = site.siteID;
    user.headObjectType = 'site';
    const invitationResult = await objectManager.invite(user, email);
    if (!invitationResult.result) {
        objectManager.removeUserFromUserGroup(userGroup, user);
        await user.delete();
        await userGroup.save();
        setLastError(invitationResult.error);
    }
    return invitationResult.result;
}
export async function getLatLngByPlaceId(placeId) {
    var _a, _b;
    let response;
    let errorMessage = undefined;
    try {
        response = await geocoder.geocode({ placeId: placeId });
    }
    catch (error) {
        errorMessage = error;
        console.error(error);
    }
    const result = response === null || response === void 0 ? void 0 : response.results[0];
    if (errorMessage || !((_b = (_a = result === null || result === void 0 ? void 0 : result.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat)) {
        return {
            error: errorMessage,
            lat_lng: { lat: undefined, lng: undefined }
        };
    }
    else {
        return {
            error: undefined,
            lat_lng: {
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng(),
            }
        };
    }
}
export async function getAddressPredictions(partialAddress) {
    var _a;
    let response;
    let errorMessage = undefined;
    try {
        response = await autocompleteService.getPlacePredictions({ input: partialAddress });
    }
    catch (error) {
        errorMessage = error;
        console.error(error);
    }
    if (errorMessage || !((_a = response === null || response === void 0 ? void 0 : response.predictions) === null || _a === void 0 ? void 0 : _a.length)) {
        return {
            error: errorMessage,
            predictions: [{
                    description: undefined,
                    place_id: undefined
                }]
        };
    }
    else {
        const predictions = [];
        response.predictions.forEach((prediction) => {
            predictions.push({
                description: prediction.description,
                place_id: prediction.place_id
            });
        });
        return {
            error: "",
            predictions: predictions
        };
    }
}
export async function getCurrentIdentity(objectManager) {
    const session = await Auth.currentSession();
    const identityID = session.getAccessToken().payload.sub;
    const identity = await objectManager.getIdentity(identityID);
    if (!identity) {
        console.warn(objectManager.lastError);
    }
    return identity;
}
export async function getCurrentUser(objectManager) {
    const identity = await getCurrentIdentity(objectManager);
    if (identity) {
        // currently 1-1 identity to user
        const userId = identity.users[0];
        const user = await objectManager.getUser(userId);
        if (!user) {
            console.warn(objectManager.lastError);
        }
        return user;
    }
    else {
        console.warn(objectManager.lastError);
        return null;
    }
}
export async function updateSiteCosts(objectManager, siteId, gasRate, electricityRate, oilRate, powerCycles) {
    const site = objectManager.getSite(siteId);
    const siteGroup = objectManager.getSiteGroup();
    const location = await objectManager.getLocation(site.siteID);
    location.naturalGasRate = gasRate;
    location.electricityRate = electricityRate;
    location.oilRate = oilRate;
    location.powerCycles = powerCycles === POWER_CYCLES.FIFTY_HZ ? 50 : 60;
    const locationSaved = await location.save();
    if (!locationSaved) {
        _lastError = location.lastError;
        return false;
    }
    const siteSaved = await site.save();
    if (!siteSaved) {
        _lastError = site.lastError;
        return false;
    }
    const siteGroupSaved = await siteGroup.save();
    if (!siteGroupSaved) {
        _lastError = siteGroup.lastError;
        return false;
    }
    return true;
}
export async function updateSite(objectManager, site, siteName, gasRate, electricityRate, oilRate, lat, lng, address, powerCycles) {
    site.name = siteName;
    const location = await objectManager.getLocation(site.siteID);
    location.naturalGasRate = gasRate;
    location.electricityRate = electricityRate;
    location.oilRate = oilRate;
    location.powerCycles = powerCycles === POWER_CYCLES.FIFTY_HZ ? 50 : 60;
    location.latitude = lat;
    location.longitude = lng;
    location.postalCode = address;
    const locationSaved = await location.save();
    if (!locationSaved) {
        _lastError = location.lastError;
        return false;
    }
    const siteSaved = await site.save();
    if (!siteSaved) {
        _lastError = site.lastError;
        return false;
    }
    return true;
}
export async function overwriteDefaultSite(objectManager, siteName, gasRate, electricityRate, oilRate, lat, lng, address, powerCycles) {
    const siteGroup = objectManager.getSiteGroup();
    const siteGroupLoaded = await siteGroup.load();
    if (!siteGroupLoaded) {
        _lastError = siteGroup.lastError;
        return false;
    }
    let defaultSite;
    for (const siteId of siteGroup.sites) {
        const site = objectManager.getSite(siteId);
        const siteLoaded = await site.load();
        if (!siteLoaded) {
            _lastError = siteGroup.lastError;
            return false;
        }
        if (site.name === "default site") {
            defaultSite = site;
        }
    }
    if (defaultSite) {
        defaultSite.name = siteName;
        const defaultSiteLocation = objectManager.createLocation(defaultSite.siteID, lat, lng, gasRate, electricityRate, oilRate, POWER_CYCLES.FIFTY_HZ ? 50 : 60, address);
        const savedLocation = await defaultSiteLocation.save();
        if (!savedLocation) {
            _lastError = defaultSiteLocation.lastError;
            return false;
        }
        const savedSite = await defaultSite.save();
        if (!savedSite) {
            _lastError = defaultSite.lastError;
            return false;
        }
        return true;
    }
    else {
        return false;
    }
}
export async function createSite(objectManager, siteName, gasRate, electricityRate, oilRate, lat, lng, address, powerCycles) {
    const airCurtainGroup = AirCurtainGroup.createNew();
    const userGroup = UserGroup.createNew();
    const siteGroup = objectManager.getSiteGroup();
    const account = objectManager.getAccount();
    const site = objectManager.createSite(siteName);
    const organization = objectManager.getOrganization();
    airCurtainGroup.accountID = account.accountID;
    airCurtainGroup.name = 'Air Curtains';
    airCurtainGroup.parentObjectID = site.siteID;
    airCurtainGroup.parentObjectType = 'site';
    site.addAirCurtainGroup(airCurtainGroup);
    userGroup.accountID = account.accountID;
    userGroup.name = 'Users';
    userGroup.parentObjectID = site.siteID;
    userGroup.parentObjectType = 'site';
    site.addUserGroup(userGroup);
    // add new site to site group
    objectManager.addSiteToSiteGroup(siteGroup, site);
    const airCurtainGroupSaved = await airCurtainGroup.save();
    if (!airCurtainGroupSaved) {
        _lastError = airCurtainGroup.lastError;
        return false;
    }
    const userGroupSaved = await userGroup.save();
    if (!userGroupSaved) {
        _lastError = userGroup.lastError;
        return false;
    }
    const siteSaved = await site.save();
    if (!siteSaved) {
        _lastError = site.lastError;
        return false;
    }
    const siteGroupSaved = await siteGroup.save();
    if (!siteGroupSaved) {
        _lastError = siteGroup.lastError;
        return false;
    }
    const location = objectManager.createLocation(site.siteID, lat, lng, gasRate, electricityRate, oilRate, POWER_CYCLES.FIFTY_HZ ? 50 : 60, address);
    const locationSaved = await location.save();
    if (!locationSaved) {
        _lastError = location.lastError;
        return false;
    }
    const organizationSaved = await organization.save();
    if (!organizationSaved) {
        _lastError = organization.lastError;
        return false;
    }
    const initObjectManager = await objectManager.initialize();
    if (!initObjectManager) {
        console.error('failed to re-initialize object Manager');
    }
    return true;
}
export const updateTableData = (direction, data, slicedData, state, sortField) => {
    // if no direction just present unsorted data
    if (direction) {
        data.sort((a, b) => {
            if (a[sortField] === b[sortField]) {
                return 0;
            }
            if (direction === SORT_DIRECTIONS.ASCENDING) {
                return a[sortField] < b[sortField] ? -1 : 1;
            }
            else {
                return a[sortField] > b[sortField] ? -1 : 1;
            }
        });
    }
    slicedData = data.slice(0, state.linesPerPage);
    return {
        slicedData,
        data,
    };
};
let lastTime = 0;
// eslint-disable-next-line @typescript-eslint/ban-types
export function throttle(func, timeFrame) {
    return function () {
        const now = new Date().getTime();
        if (now - lastTime >= timeFrame) {
            func();
            lastTime = now;
        }
    };
}
export function loadStyle(cssUrl, shadowRoot) {
    return new Promise((resolve) => {
        const link = shadowRoot.getElementById('css-target');
        if (!link) {
            console.error(`failed to find link element when loading: ${cssUrl}`);
            resolve(false);
        }
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.onload = () => {
            shadowRoot
                .getElementById('root')
                .classList.remove('css-loading');
            resolve(true);
        };
        link.onerror = () => {
            console.error('error loading: ', cssUrl);
            resolve(false);
        };
        link.href = cssUrl;
        const headScript = shadowRoot.getElementById('root');
        if (headScript) {
            // reset splash screen body image (np if already done)
            const body = document.body;
            body.style.backgroundColor = 'white';
            body.style.backgroundImage = '';
            body.style.backgroundSize = 'auto';
            body.style.backgroundPosition = '0% 0%';
            headScript.parentNode.insertBefore(link, headScript);
            const logo = document.getElementById('loading-berner-logo');
            if (logo) {
                logo.remove();
            }
        }
        else {
            console.error(`failed to find root element when loading: ${cssUrl}`);
            resolve(false);
        }
    });
}
export function tempUnitButtonClickHandler(e) {
    setTempUnitCelsiusSelected(!_tempUnitCelsiusSelected);
    e.stopPropagation();
}
export function dropDownClickHandler(e, shadowRoot) {
    const targetDropDown = e.target.parentElement.querySelector('.dropdown-menu');
    const isShown = targetDropDown.classList.contains('show');
    const dropDowns = shadowRoot.querySelectorAll('.dropdown-menu');
    dropDowns.forEach((dropDown) => {
        dropDown.classList.remove('show');
    });
    if (isShown) {
        targetDropDown.classList.remove('show');
    }
    else {
        targetDropDown.classList.add('show');
    }
    e.stopPropagation();
}
export function renderDropDown(_valuesToLabels, values, selected, hideCaret, forceOpen, id, onSelected, shadowRoot) {
    const itemClickHandler = (e) => {
        const selection = e.target.id.split(/-(.+)/)[1];
        onSelected(selection);
        e.target.parentNode.classList.remove('show');
        e.stopPropagation();
    };
    const label = _valuesToLabels.get(selected);
    return html `<div class="filter-item">
        <div class="btn-group ml-1">
            <button
                id=${id + "-button"}
                type="button"
                class=${classMap({
        'btn': true,
        'btn-light': true,
        'dropdown-toggle': !hideCaret,
        'd-none': forceOpen
    })}
                data-toggle="dropdown"
                aria-expanded="false"
                @click="${(e) => dropDownClickHandler(e, shadowRoot)}"
            >
                ${label} <span class="caret"></span>
            </button>
            <div class=${classMap({
        "dropdown-menu": true
    })} id=${id}>
                ${html `${repeat(values, (rangeValue) => html `<span
                            class="dropdown-item"
                            id=${'option-' + rangeValue}
                            @click=${itemClickHandler}
                            >${_valuesToLabels.get(rangeValue)}</span>`)}`}
            </div>
        </div>
    </div>`;
}
