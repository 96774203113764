import { getObjects, createObjects, updateObjects, deleteObjects, } from './../api/webApplicationApi';
import { v4 as uuidv4 } from 'uuid';
const objectType = 'user';
export class User {
    constructor(userID) {
        this._saveMethod = createObjects;
        this._object = {
            userID: userID,
            userGroups: [],
            accountID: undefined,
            identityID: undefined,
            headObjectID: undefined,
            headObjectType: undefined,
            name: undefined,
            firstName: undefined,
            lastName: undefined,
            airCurtains: [],
            roles: [],
            invitationID: undefined,
            acceptedInvitationDate: undefined,
        };
    }
    static createNew() {
        return new User(uuidv4());
    }
    static createFromObject(user) {
        const obj = new User(user.userID);
        obj._object = user;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.userID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('user not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        // if (this._object.name == undefined) {
        //     this._lastError = new Error('Name is undefined');
        //     return false;
        // }
        if (this._object.accountID == undefined) {
            this._lastError = new Error('Account ID is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknow server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get userID() {
        return this._object.userID;
    }
    get accountID() {
        return this._object.accountID;
    }
    set accountID(accountID) {
        this._object.accountID = accountID;
    }
    get identityID() {
        return this._object.identityID;
    }
    set identityID(identityID) {
        this._object.identityID = identityID;
    }
    get name() {
        return this._object.name;
    }
    set name(name) {
        this._object.name = name;
    }
    get firstName() {
        return this._object.firstName;
    }
    set firstName(firstName) {
        this._object.firstName = firstName;
    }
    get lastName() {
        return this._object.lastName;
    }
    set lastName(lastName) {
        this._object.lastName = lastName;
    }
    get headObjectType() {
        return this._object.headObjectType;
    }
    set headObjectType(headObjectType) {
        this._object.headObjectType = headObjectType;
    }
    get headObjectID() {
        return this._object.headObjectID;
    }
    set headObjectID(headObjectID) {
        this._object.headObjectID = headObjectID;
    }
    get userGroups() {
        return this._object.userGroups;
    }
    get invitationID() {
        return this._object.invitationID;
    }
    set invitationID(invitationID) {
        this._object.invitationID = invitationID;
    }
    get acceptedInvitationDate() {
        return this._object.acceptedInvitationDate;
    }
    set acceptedInvitationDate(acceptedInvitationDate) {
        this._object.acceptedInvitationDate = acceptedInvitationDate;
    }
    addUserGroup(userGroup) {
        if (this._object.userGroups.includes(userGroup.userGroupID))
            return;
        this._object.userGroups.push(userGroup.userGroupID);
    }
    deleteUserGroup(userGroup) {
        const index = this._object.userGroups.indexOf(userGroup.userGroupID);
        if (index > -1) {
            this._object.userGroups.splice(index, 1);
        }
    }
    get roles() {
        return this._object.roles;
    }
    addRole(role) {
        if (this._object.roles.includes(role.roleID))
            return;
        this._object.roles.push(role.roleID);
    }
    deleteRole(role) {
        const index = this._object.roles.indexOf(role.roleID);
        if (index > -1) {
            this._object.roles.splice(index, 1);
        }
    }
    get airCurtains() {
        return this._object.airCurtains;
    }
    addAirCurtain(airCurtain) {
        if (this._object.airCurtains.includes(airCurtain.airCurtainID))
            return;
        this._object.airCurtains.push(airCurtain.airCurtainID);
    }
    deleteAirCurtain(airCurtain) {
        const index = this._object.airCurtains.indexOf(airCurtain.airCurtainID);
        if (index > -1) {
            this._object.airCurtains.splice(index, 1);
        }
    }
}
