import { getObjects, createObjects, updateObjects, deleteObjects, sendInvitationEmail as sendInvitationEmailAPI, } from './../api/webApplicationApi';
const objectType = 'invitation';
export class Invitation {
    constructor(email) {
        this._saveMethod = createObjects;
        this._object = {
            invitationID: email,
            organizationID: undefined,
            accountID: undefined,
            userID: undefined,
            invitationDate: undefined,
            fromUserID: undefined,
        };
    }
    static createFromObject(invitation) {
        const obj = new Invitation(invitation.invitationID);
        obj._object = invitation;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.invitationID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('invitation not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.fromUserID == undefined) {
            this._lastError = new Error('From User ID is undefined');
            return false;
        }
        if (this._object.organizationID == undefined) {
            this._lastError = new Error('Organization ID is undefined');
            return false;
        }
        if (this._object.invitationDate == undefined) {
            this._lastError = new Error('Invitation Date is undefined');
            return false;
        }
        if (this._object.accountID == undefined) {
            this._lastError = new Error('Account ID Date is undefined');
            return false;
        }
        if (this._object.userID == undefined) {
            this._lastError = new Error('User ID is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknow server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async sendInvitationEmail() {
        // has to save first so the backend can retreive the Invitation object
        if (this._saveMethod == createObjects) {
            return false;
        }
        const sendInvitationEmailRequest = {
            invitationID: this.invitationID,
        };
        const response = await sendInvitationEmailAPI(sendInvitationEmailRequest);
        return response;
    }
    get lastError() {
        return this._lastError;
    }
    get invitationID() {
        return this._object.invitationID;
    }
    get accountID() {
        return this._object.accountID;
    }
    set accountID(accountID) {
        this._object.accountID = accountID;
    }
    get organizationID() {
        return this._object.organizationID;
    }
    set organizationID(organizationID) {
        this._object.organizationID = organizationID;
    }
    get fromUserID() {
        return this._object.fromUserID;
    }
    set fromUserID(fromUserID) {
        this._object.fromUserID = fromUserID;
    }
    get invitationDate() {
        return this._object.invitationDate;
    }
    set invitationDate(invitationDate) {
        this._object.invitationDate = invitationDate;
    }
    get userID() {
        return this._object.userID;
    }
    set userID(userID) {
        this._object.userID = userID;
    }
}
