import { MEASUREMENTS, INTERVALS } from '../api/reporting';
import { getCachedData, MEASUREMENT_TO_FUNC } from '../api/dataManagementApi';
import { isTimeStampLast24hrs, getTempUnitCelsiusSelected } from './ui';
import { models } from '../../assets/air_curtain_models.json';
export const AGGREGATES_KEY = 'aggregates';
export const COLUMN_CHART_MEASUREMENTS = [MEASUREMENTS.BTU, MEASUREMENTS.LOSS, MEASUREMENTS.COST_TO_OPERATE,
    MEASUREMENTS.GROSS_SAVING, MEASUREMENTS.SAVING, MEASUREMENTS.TON_GAIN_RATE, MEASUREMENTS.DOOR_OPEN_TIME, MEASUREMENTS.DOOR_OPEN_COUNT];
export const AREA_GRAPH_MEASUREMENTS = [MEASUREMENTS.AVERAGE_TEMP, MEASUREMENTS.MAX_TEMP, MEASUREMENTS.MIN_TEMP, MEASUREMENTS.WEATHER_AVG_TEMP, MEASUREMENTS.EFFICIENCY];
export const MEASUREMENTS_LACKING_24HR_DATA = [MEASUREMENTS.EFFICIENCY, MEASUREMENTS.SAVING, MEASUREMENTS.GROSS_SAVING, MEASUREMENTS.BTU, MEASUREMENTS.TON_GAIN_RATE,
    MEASUREMENTS.LOSS, MEASUREMENTS.COST_TO_OPERATE, MEASUREMENTS.DOOR_OPEN_TIME, MEASUREMENTS.WEATHER_AVG_TEMP];
export const LOCALLY_CALCULATED_MEASUREMENTS = [MEASUREMENTS.LOSS, MEASUREMENTS.COST_TO_OPERATE, MEASUREMENTS.SAVING, MEASUREMENTS.GROSS_SAVING];
export const SITE_SPECIFIC_MEASUREMENTS = [MEASUREMENTS.WEATHER_AVG_TEMP];
export async function getInfographicDataAggregated(selectedRange, measurement, average, airCurtains) {
    var _a, _b, _c;
    const aircurtainIds = airCurtains.map((aircurtain) => {
        return aircurtain.airCurtainID;
    });
    const aggMeasurementKey = MEASUREMENT_TO_FUNC.get(measurement) + '(' + measurement + ')';
    const oldData = await getCachedData(selectedRange, aircurtainIds, [measurement], true, true, "");
    // Data from the period preceding the range, i.e. week before last, to check change amount
    const oldDataAggregated = (_a = oldData.get(AGGREGATES_KEY)) === null || _a === void 0 ? void 0 : _a.get(aggMeasurementKey);
    let oldValue = (_b = getTotal(oldDataAggregated)) !== null && _b !== void 0 ? _b : 0;
    if (average) {
        oldValue = (oldDataAggregated === null || oldDataAggregated === void 0 ? void 0 : oldDataAggregated.length) ? oldValue / oldDataAggregated.length : 0;
    }
    //Data from the current range, i.e. last week
    const newData = await getCachedData(selectedRange, aircurtainIds, [measurement], true, false, "");
    const newDataAggregated = (_c = newData.get(AGGREGATES_KEY)) === null || _c === void 0 ? void 0 : _c.get(aggMeasurementKey);
    let value = (newDataAggregated === null || newDataAggregated === void 0 ? void 0 : newDataAggregated.length) ? Math.ceil(getTotal(newDataAggregated)) : undefined;
    if (average) {
        value = (newDataAggregated === null || newDataAggregated === void 0 ? void 0 : newDataAggregated.length) ? value / newDataAggregated.length : undefined;
    }
    // TODO: shouldn't return a value until old data is cached
    return {
        value,
        change: value === undefined ? 0 : value - oldValue,
    };
}
export async function getInfographicData(selectedRange, measurement, average, airCurtains) {
    const aircurtainIds = airCurtains.map((aircurtain) => {
        return aircurtain.airCurtainID;
    });
    const results = new Map();
    for (const deviceId of aircurtainIds) {
        const dataByMeasurement = await getCachedData(selectedRange, [deviceId], [measurement], false, false, "");
        const oldDataByMeasurement = await getCachedData(selectedRange, [deviceId], [measurement], false, true, "");
        results.set(deviceId, null);
        const deviceData = dataByMeasurement.get(deviceId);
        const oldDeviceData = oldDataByMeasurement.get(deviceId);
        if (deviceData) {
            const data = deviceData.get(measurement);
            const oldData = oldDeviceData === null || oldDeviceData === void 0 ? void 0 : oldDeviceData.get(measurement);
            if (data && data.length) {
                let value = Math.ceil(getTotal(data));
                let oldValue = 0;
                if (average) {
                    value = value / Object.keys(data).length;
                }
                if (oldData) {
                    oldValue = Math.ceil(getTotal(oldData));
                    if (average) {
                        oldValue = oldValue / Object.keys(oldData).length;
                    }
                }
                const infoGraphicData = {
                    value,
                    change: value === undefined ? 0 : value - oldValue,
                };
                results.set(deviceId, infoGraphicData);
            }
        }
    }
    return results;
}
export function getTotal(graphData) {
    let value = 0;
    value = graphData === null || graphData === void 0 ? void 0 : graphData.reduce((accumulator, currentValue, currentIndex, data) => {
        return accumulator + data[currentIndex].value;
    }, 0);
    return value;
}
export function getAverage(data, measurement, numDates) {
    let average = 0;
    let values = [];
    let lastValidValue = 0;
    values = data === null || data === void 0 ? void 0 : data.map((gData) => {
        // compensate for data not yet processed within last 24 hrs
        let value = gData.value;
        if (isTimeStampLast24hrs(gData.timestamp) && MEASUREMENTS_LACKING_24HR_DATA.includes(measurement)) {
            value = lastValidValue;
        }
        else {
            lastValidValue = gData.value;
        }
        return getDisplayValue(measurement, value);
    });
    const divisor = (numDates > 0 ? numDates : values.length);
    if (values === null || values === void 0 ? void 0 : values.length) {
        average = values.reduce((acc, current) => {
            return (acc += current);
        }) / divisor;
    }
    return average;
}
export function getMaxMin(data, measurement) {
    let values = [];
    let lastValidValue = 0;
    values = data === null || data === void 0 ? void 0 : data.map((gData) => {
        let value = gData.value;
        // compensate for data not yet processed within last 24 hrs
        if (isTimeStampLast24hrs(gData.timestamp) && MEASUREMENTS_LACKING_24HR_DATA.includes(measurement)) {
            value = lastValidValue;
        }
        else {
            lastValidValue = gData.value;
        }
        return getDisplayValue(measurement, value);
    });
    const sorted = (values === null || values === void 0 ? void 0 : values.length) ? values.sort((a, b) => { return a - b; }) : [0, 0];
    return { max: sorted[sorted.length - 1], min: sorted[0] };
}
export function getCoolingCost(electricityRate, naturalGasRate, oilRate, energyType) {
    switch (energyType) {
        case 'e':
            return electricityRate;
        case 'g':
            return naturalGasRate * 0.974966278 / 0.99;
        case 'o':
            return 0;
    }
}
export function getHeatingCost(electricityRate, naturalGasRate, oilRate, energyType) {
    switch (energyType) {
        case 'e':
            return electricityRate * 1000 / 3.413;
        case 'g':
            return naturalGasRate * 0.974966278;
        case 'o':
            return oilRate * 0.172323997;
    }
}
function getLoss(btu, tonGainRate, coolingCost, heatingCost) {
    return (btu * heatingCost + tonGainRate * coolingCost);
}
function getCostToOperate(modelId, electricityRate, doorOpenHours) {
    const modelsStruct = JSON.parse(JSON.stringify(models));
    const modelData = modelsStruct[`${modelId}`];
    if (modelData === undefined) {
        return 0;
    }
    const totalHorsePower = modelData.totalHorsePower;
    if (totalHorsePower === undefined) {
        return 0;
    }
    return (0.746 * totalHorsePower * electricityRate * doorOpenHours);
}
function getSaving(grossSaving, costToOperate) {
    return grossSaving - costToOperate;
}
function precise_round(num, decimals) {
    const sign = num >= 0 ? 1 : -1;
    return (Math.round((num * Math.pow(10, decimals)) + (sign * 0.001)) / Math.pow(10, decimals)).toFixed(decimals);
}
export function getSavingData(grossSavingData, costToOperateData) {
    var _a, _b;
    const timeStamps = grossSavingData === null || grossSavingData === void 0 ? void 0 : grossSavingData.map((graphData) => { return graphData.timestamp; });
    const results = [];
    for (const timestamp of timeStamps) {
        const grossSaving = (_a = grossSavingData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _a === void 0 ? void 0 : _a.value;
        const costToOperate = (_b = costToOperateData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _b === void 0 ? void 0 : _b.value;
        if (grossSaving !== undefined && costToOperate !== undefined) {
            const value = getSaving(grossSaving, costToOperate);
            results.push({ timestamp, value });
        }
    }
    return results;
}
export function getCostToOperateData(doorOpenTimeData, modelId, electricityRate) {
    var _a;
    const timeStamps = doorOpenTimeData === null || doorOpenTimeData === void 0 ? void 0 : doorOpenTimeData.map((doorOpenTimeData) => { return doorOpenTimeData.timestamp; });
    const results = [];
    for (const timestamp of timeStamps) {
        const doorOpenHours = (_a = doorOpenTimeData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _a === void 0 ? void 0 : _a.value;
        if (doorOpenHours !== undefined) {
            const value = getCostToOperate(modelId, electricityRate, doorOpenHours);
            results.push({ timestamp, value });
        }
    }
    return results;
}
export function getLossData(btuData, tonGainRateData, coolingCost, heatingCost) {
    var _a, _b;
    const timeStamps = btuData === null || btuData === void 0 ? void 0 : btuData.map((graphData) => { return graphData.timestamp; });
    const results = [];
    for (const timestamp of timeStamps) {
        const btu = (_a = btuData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _a === void 0 ? void 0 : _a.value;
        const tonGainRate = (_b = tonGainRateData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _b === void 0 ? void 0 : _b.value;
        if (btu !== undefined && tonGainRate !== undefined) {
            const value = getLoss(btu, tonGainRate, coolingCost, heatingCost);
            results.push({ timestamp, value });
        }
    }
    return results;
}
export function getGrossSavingData(efficiencyData, btuData, tonGainRateData, coolingCost, heatingCost) {
    var _a, _b, _c;
    const timeStamps = efficiencyData === null || efficiencyData === void 0 ? void 0 : efficiencyData.map((graphData) => { return graphData.timestamp; });
    const results = [];
    for (const timestamp of timeStamps) {
        const btu = (_a = btuData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _a === void 0 ? void 0 : _a.value;
        const tonGainRate = (_b = tonGainRateData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _b === void 0 ? void 0 : _b.value;
        const efficiency = (_c = efficiencyData.find((graphData) => {
            return graphData.timestamp === timestamp;
        })) === null || _c === void 0 ? void 0 : _c.value;
        if (btu !== undefined && tonGainRate !== undefined && efficiency !== undefined) {
            const loss = getLoss(btu, tonGainRate, coolingCost, heatingCost);
            const value = loss * efficiency;
            if (value !== undefined) {
                results.push({ timestamp, value });
            }
        }
    }
    return results;
}
export function getConvertedTemperature(value, decimalPoints = 1) {
    if (getTempUnitCelsiusSelected()) {
        return convertFahrenheitToCelsius(value, decimalPoints);
    }
    else {
        return +precise_round(value, decimalPoints);
    }
}
function convertFahrenheitToCelsius(value, decimalPoints = 1) {
    const result = ((value - 32) * .5556);
    return +precise_round(result, decimalPoints);
}
export function convertCelsiusToFahrenheit(value) {
    const result = ((value * 9 / 5) + 32);
    return +precise_round(result, 1);
}
export function getDisplayValue(measurement, value) {
    switch (measurement) {
        case MEASUREMENTS.EFFICIENCY:
            return value * 100;
        case MEASUREMENTS.DOOR_OPEN_COUNT:
            return Math.ceil(value);
        case MEASUREMENTS.DOOR_OPEN_TIME:
            return (value < 1) ? Math.ceil(value * 60) : Math.ceil(value);
        case MEASUREMENTS.SAVING:
        case MEASUREMENTS.GROSS_SAVING:
            // if location / installation are misconfigured negative values are possible
            return value < 0 ? 0 : value;
        case MEASUREMENTS.AVERAGE_TEMP:
        case MEASUREMENTS.MIN_TEMP:
        case MEASUREMENTS.MAX_TEMP:
        case MEASUREMENTS.WEATHER_AVG_TEMP:
            return getConvertedTemperature(value);
        default:
            return value;
    }
}
export function getMeasurementUI(measurement, interval = '') {
    const graphUI = {
        label: '',
        yAxisLabel: '',
        color: 'lightblue',
        colorSecondary: 'midnightblue',
        colorAlpha: 'lightblue',
        unit: '',
        decimals: 0,
        icon: '',
        subtitle: '',
        yAxisMin: 0,
        yAxisMax: 0,
        yAxisRangePercentLimit: 0,
        displayTotal: false,
    };
    const redAlpha = 'rgba(255,95,76,0.05)';
    const blueAlpha = 'rgba(74,144,226,0.05)';
    const greenAlpha = 'rgba(67,185,41,0.05)';
    const greyAlpha = 'rgba(80,80,80,0.05)';
    const orangeAlpha = 'rgba(255,165,0,0.05)';
    switch (measurement) {
        case MEASUREMENTS.AVERAGE_TEMP:
            graphUI.label = 'Temperature';
            graphUI.color = '#ff5f4c';
            graphUI.colorSecondary = 'tomato';
            graphUI.colorAlpha = redAlpha;
            graphUI.yAxisLabel = 'Degrees';
            graphUI.unit = getTempUnitCelsiusSelected() ? '°C' : '°F';
            graphUI.yAxisRangePercentLimit = 5;
            graphUI.decimals = 1;
            break;
        case MEASUREMENTS.MAX_TEMP:
            graphUI.label = 'Max Temperature';
            graphUI.color = '#ff5f4c';
            graphUI.colorSecondary = 'tomato';
            graphUI.colorAlpha = redAlpha;
            graphUI.yAxisLabel = 'Degrees';
            graphUI.unit = getTempUnitCelsiusSelected() ? '°C' : '°F';
            graphUI.yAxisRangePercentLimit = 5;
            graphUI.decimals = 1;
            break;
        case MEASUREMENTS.MIN_TEMP:
            graphUI.label = 'Min Temperature';
            graphUI.color = '#ff5f4c';
            graphUI.colorSecondary = 'tomato';
            graphUI.colorAlpha = redAlpha;
            graphUI.yAxisLabel = 'Degrees';
            graphUI.unit = getTempUnitCelsiusSelected() ? '°C' : '°F';
            graphUI.yAxisRangePercentLimit = 5;
            graphUI.decimals = 1;
            break;
        case MEASUREMENTS.WEATHER_AVG_TEMP:
            graphUI.label = 'Outside Temperature';
            graphUI.color = 'orange';
            graphUI.colorSecondary = 'red';
            graphUI.colorAlpha = orangeAlpha;
            graphUI.yAxisLabel = 'Degrees';
            graphUI.unit = getTempUnitCelsiusSelected() ? '°C' : '°F';
            graphUI.yAxisRangePercentLimit = 5;
            graphUI.decimals = 1;
            break;
        case MEASUREMENTS.EFFICIENCY:
            graphUI.label = 'Energy Saving';
            graphUI.color = '#43b929';
            graphUI.colorSecondary = 'mediumseagreen';
            graphUI.colorAlpha = greenAlpha;
            graphUI.yAxisLabel = 'Percent';
            graphUI.unit = '%';
            graphUI.yAxisMax = 100;
            break;
        case MEASUREMENTS.SAVING:
            graphUI.label = 'Saving';
            graphUI.color = 'royalblue';
            graphUI.colorSecondary = 'midnightblue';
            graphUI.colorAlpha = blueAlpha;
            graphUI.decimals = 2;
            graphUI.yAxisLabel = 'Dollars';
            graphUI.unit = '$';
            graphUI.displayTotal = true;
            break;
        case MEASUREMENTS.COST_TO_OPERATE:
            graphUI.label = 'Cost to Operate';
            graphUI.color = 'royalblue';
            graphUI.colorSecondary = 'midnightblue';
            graphUI.colorAlpha = blueAlpha;
            graphUI.decimals = 2;
            graphUI.yAxisLabel = 'Dollars';
            graphUI.unit = '$';
            graphUI.yAxisRangePercentLimit = 5;
            graphUI.displayTotal = true;
            break;
        case MEASUREMENTS.GROSS_SAVING:
            graphUI.label = 'Gross Saving';
            graphUI.color = 'royalblue';
            graphUI.colorSecondary = 'midnightblue';
            graphUI.colorAlpha = blueAlpha;
            graphUI.decimals = 2;
            graphUI.yAxisLabel = 'Dollars';
            graphUI.unit = '$';
            graphUI.yAxisRangePercentLimit = 5;
            graphUI.displayTotal = true;
            break;
        case MEASUREMENTS.BTU:
            graphUI.label = 'BTU';
            graphUI.color = 'pink';
            graphUI.colorSecondary = 'tomato';
            graphUI.colorAlpha = redAlpha;
            graphUI.decimals = 6;
            graphUI.yAxisLabel = 'BTU';
            graphUI.unit = 'Btu';
            graphUI.subtitle = 'Heat energy saved by the air curtain(s)';
            break;
        case MEASUREMENTS.TON_GAIN_RATE:
            graphUI.label = 'Ton Gain Rate';
            graphUI.color = 'pink';
            graphUI.colorSecondary = 'tomato';
            graphUI.colorAlpha = redAlpha;
            graphUI.decimals = 3;
            graphUI.yAxisLabel = '';
            graphUI.unit = 'TR';
            graphUI.subtitle = 'Cooling energy lost';
            graphUI.yAxisRangePercentLimit = 5;
            break;
        case MEASUREMENTS.LOSS:
            graphUI.label = 'Loss';
            graphUI.color = 'lightsteelblue';
            graphUI.colorSecondary = 'lightslategrey';
            graphUI.colorAlpha = greyAlpha;
            graphUI.decimals = 2;
            graphUI.yAxisLabel = 'Dollars';
            graphUI.unit = '$';
            graphUI.displayTotal = true;
            // graphUI.subtitle = 'Combined cooling and heating cost saved by the air curtain(s)';
            break;
        case MEASUREMENTS.DOOR_OPEN_TIME:
            graphUI.label = 'Door Open Time';
            graphUI.color = 'royalblue';
            graphUI.yAxisMax = 100;
            (graphUI.yAxisLabel = 'Time'),
                (graphUI.unit = interval === INTERVALS.HOURLY ? 'min' : 'hour');
            break;
        case MEASUREMENTS.DOOR_OPEN_COUNT:
            graphUI.label = 'Doors Open';
            graphUI.color = '#4a90e2';
            (graphUI.colorSecondary = 'cornflowerblue'),
                (graphUI.yAxisLabel = 'Number');
            graphUI.unit = '';
            graphUI.displayTotal = true;
            break;
    }
    return graphUI;
}
