/* eslint-env browser */
import { Router } from '@vaadin/router';
import './components/berner-frame';
import applicationText from '../assets/text_en.json';
import { setAppText } from './redux/actions';
import { store } from './redux/store';
import './components/berner-start';
import './components/berner-content-navigation';
import './components/berner-auth-navigation';
import './components/screens/berner-dashboard';
const routes = [
    {
        path: '/',
        component: 'berner-frame',
        children: [
            {
                path: '',
                component: 'berner-start',
            },
            {
                path: 'auth',
                component: 'berner-auth-navigation',
                children: [
                    {
                        path: '',
                        component: 'berner-login',
                        action: async () => {
                            await import('./components/screens/berner-login');
                        },
                    },
                    {
                        path: '/login',
                        component: 'berner-login',
                        action: async () => {
                            await import('./components/screens/berner-login');
                        },
                    },
                    {
                        path: '/signup',
                        component: 'berner-signup',
                        action: async () => {
                            await import('./components/screens/berner-signup');
                        },
                    },
                    {
                        path: '/password-reset',
                        component: 'berner-password-reset',
                        action: async () => {
                            await import('./components/screens/berner-password-reset');
                        },
                    },
                    {
                        path: '/password-reset-validate',
                        component: 'berner-password-reset-validate',
                        action: async () => {
                            await import('./components/screens/berner-password-reset-validate');
                        },
                    },
                    {
                        path: '/create-org',
                        component: 'berner-create-org',
                        action: async () => {
                            await import('./components/screens/berner-create-org');
                        },
                    },
                    {
                        path: '/create-first-site',
                        component: 'berner-overwrite-default-site',
                        action: async () => {
                            await import('./components/screens/berner-overwrite-default-site');
                        },
                    },
                ],
            },
            {
                path: 'content',
                component: 'berner-content-navigation',
                children: [
                    {
                        path: '',
                        component: 'berner-dashboard',
                    },
                    {
                        path: '/dashboard',
                        component: 'berner-dashboard',
                    },
                    {
                        path: '/organization',
                        component: 'berner-organization',
                        action: async () => {
                            await import('./components/screens/berner-organization');
                        },
                    },
                    {
                        path: '/profile',
                        component: 'berner-profile',
                        action: async () => {
                            await import('./components/screens/berner-profile');
                        },
                    }
                ],
            },
        ],
    },
    {
        path: '/webview',
        component: 'berner-graph-webview',
        action: async () => {
            await import('./components/screens/berner-graph-webview');
        },
    },
];
store.dispatch(setAppText(applicationText));
const outlet = document.getElementById('outlet');
export const router = new Router(outlet);
router.setRoutes(routes);
