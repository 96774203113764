import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map';
import { loadStyle } from '../api/ui';
let Bernerinfographic = class Bernerinfographic extends LitElement {
    constructor() {
        super(...arguments);
        this._initialized = false;
        this.subtitle = '';
        this.colour = '';
        this.value = '';
        this.displayChange = '';
        this.change = '';
        this.negativeChange = '';
        this.noData = '';
        this.curtainOffline = '';
        this.firstUpdated = async () => {
            await loadStyle('./css/berner-infographic.css', this.shadowRoot);
            this._initialized = true;
            this.requestUpdate();
        };
    }
    get negative() {
        return this.negativeChange === 'true';
    }
    get noDataAvailable() {
        return this.noData === 'true';
    }
    get isCurtainOffline() {
        return this.curtainOffline === 'true';
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    renderContent() {
        const UP_ARROW = '\u25B2';
        const DOWN_ARROW = '\u25BC';
        const CHANGE_PREFIX = this.negative ? DOWN_ARROW : UP_ARROW;
        const parsedChange = parseFloat(this.change);
        const showPrefix = !Number.isNaN(parsedChange) && parsedChange !== 0;
        return html `
            <section class="row mt-4">
                <div class="col-7 left-element">
                    <p class="title ${this.colour}">${this.title}</p>
                    <p class="subtitle">${this.subtitle}</p>
                </div>
                <div class="col-5 right-element">
                    <p class=${classMap({ "value": !this.noDataAvailable, "value--small": this.noDataAvailable })}>${this.value}</p>
                    <p class="subtitle">
                        <span class=${classMap({ "change": true, "d-none": this.noDataAvailable })}
                            >${showPrefix ? CHANGE_PREFIX : ""} ${this.displayChange}</span
                        >
                        <span class=${classMap({ "d-none": !this.isCurtainOffline || !this.noDataAvailable })}>offline device</span>
                    </p>
                </div>
            </section>
        `;
    }
    render() {
        return html `
        <link id="css-target">
        <div class="css-loading" id="root">
            <div class="col-lg-12 mx-auto my-3 no-gutters">
                ${this._initialized ? html `${this.renderContent()}` : html ``}
            </div>
        </div>`;
    }
};
__decorate([
    property()
], Bernerinfographic.prototype, "subtitle", void 0);
__decorate([
    property()
], Bernerinfographic.prototype, "colour", void 0);
__decorate([
    property()
], Bernerinfographic.prototype, "value", void 0);
__decorate([
    property({ attribute: 'display-change' })
], Bernerinfographic.prototype, "displayChange", void 0);
__decorate([
    property()
], Bernerinfographic.prototype, "change", void 0);
__decorate([
    property({ attribute: 'negative-change' })
], Bernerinfographic.prototype, "negativeChange", void 0);
__decorate([
    property({ attribute: 'no-data' })
], Bernerinfographic.prototype, "noData", void 0);
__decorate([
    property({ attribute: 'curtain-offline' })
], Bernerinfographic.prototype, "curtainOffline", void 0);
Bernerinfographic = __decorate([
    customElement('berner-infographic')
], Bernerinfographic);
export { Bernerinfographic };
