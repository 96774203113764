import { __decorate } from "tslib";
import { customElement } from 'lit/decorators.js';
import { Router } from '@vaadin/router';
import { BernerScreenBase } from './berner-screen-base';
import { LANDING_PAGE } from '../api/ui';
let BernerStart = class BernerStart extends BernerScreenBase {
    async firstUpdated() {
        const initialized = await super.checkInitialized();
        if (initialized) {
            Router.go(LANDING_PAGE);
        }
    }
};
BernerStart = __decorate([
    customElement('berner-start')
], BernerStart);
export { BernerStart };
