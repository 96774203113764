import { getInstallations, saveInstallations, deleteInstallations, } from './../api/webApplicationApi';
export class Installation {
    constructor(airCurtainID, siteID) {
        this._installation = {
            thingName: airCurtainID,
            siteID: siteID,
            applicationType: undefined,
            openingWidth: undefined,
            openingHeight: undefined,
            doorMountingHeight: undefined,
            interiorTemperatureSetPointWinter: undefined,
            interiorTemperatureSetPointSummer: undefined,
            interiorRelativeHumidityWinter: undefined,
            interiorRelativeHumiditySummer: undefined,
            model: undefined,
            heatingEnergyType: undefined,
            coolingEnergyType: undefined,
            calculateHeatingSaving: undefined,
            calculateCoolingSaving: undefined,
            hasHeating: undefined,
            hasTemperatureSensor: undefined,
            heatingPower: undefined,
            unconditionedHeatingTemperatureSetPoint: undefined,
        };
    }
    static createFromObject(installation) {
        const obj = new Installation(installation.thingName, installation.siteID);
        obj._installation = installation;
        return obj;
    }
    async load() {
        const response = await getInstallations([this._installation]);
        if (response.length > 0) {
            this._installation = response[0];
            return true;
        }
        return false;
    }
    async save() {
        if (this._installation.siteID == undefined) {
            this._lastError = new Error('Site ID is undefined');
            return false;
        }
        if (this._installation.applicationType == undefined) {
            this._lastError = new Error('Application Type is undefined');
            return false;
        }
        if (this._installation.openingWidth == undefined) {
            this._lastError = new Error('Opening Width is undefined');
            return false;
        }
        if (this._installation.openingHeight == undefined) {
            this._lastError = new Error('Opening Height is undefined');
            return false;
        }
        if (this._installation.doorMountingHeight == undefined) {
            this._lastError = new Error('Door Mounting Height is undefined');
            return false;
        }
        if (this._installation.interiorTemperatureSetPointWinter == undefined) {
            this._lastError = new Error('Interior Temperature Set Point Winter is undefined');
            return false;
        }
        if (this._installation.interiorTemperatureSetPointSummer == undefined) {
            this._lastError = new Error('Interior Temperature Set Point Summer is undefined');
            return false;
        }
        if (this._installation.interiorRelativeHumidityWinter == undefined) {
            this._lastError = new Error('Interior Relative Humidity Winter Type is undefined');
            return false;
        }
        if (this._installation.interiorRelativeHumiditySummer == undefined) {
            this._lastError = new Error('Interior Relative Humidity Summer is undefined');
            return false;
        }
        if (this._installation.model == undefined) {
            this._lastError = new Error('Model is undefined');
            return false;
        }
        if (this._installation.heatingEnergyType == undefined) {
            this._lastError = new Error('Heating Energy Type is undefined');
            return false;
        }
        if (this._installation.coolingEnergyType == undefined) {
            this._lastError = new Error('Cooling Energy Type is undefined');
            return false;
        }
        if (this._installation.calculateHeatingSaving == undefined) {
            this._lastError = new Error('Calculate Heating Saving is undefined');
            return false;
        }
        if (this._installation.calculateCoolingSaving == undefined) {
            this._lastError = new Error('Calculate Cooling Saving is undefined');
            return false;
        }
        if (this._installation.hasHeating == undefined) {
            this._lastError = new Error('Has Heating is undefined');
            return false;
        }
        if (this._installation.hasTemperatureSensor == undefined) {
            this._lastError = new Error('Has Temperature Sensor is undefined');
            return false;
        }
        if (this._installation.heatingPower == undefined) {
            this._lastError = new Error('Heating Power is undefined');
            return false;
        }
        if (this._installation.unconditionedHeatingTemperatureSetPoint ==
            undefined) {
            this._lastError = new Error('Unconditioned Heating Temperature Set Point is undefined');
            return false;
        }
        this._lastError = undefined;
        try {
            const response = await saveInstallations([this._installation]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        this._lastError = undefined;
        try {
            const response = await deleteInstallations([this._installation]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get siteID() {
        return this._installation.siteID;
    }
    set siteID(siteID) {
        this._installation.siteID = siteID;
    }
    get applicationType() {
        return this._installation.applicationType;
    }
    set applicationType(applicationType) {
        this._installation.applicationType = applicationType;
    }
    get openingWidth() {
        return this._installation.openingWidth;
    }
    set openingWidth(openingWidth) {
        this._installation.openingWidth = openingWidth;
    }
    get openingHeight() {
        return this._installation.openingHeight;
    }
    set openingHeight(openingHeight) {
        this._installation.openingHeight = openingHeight;
    }
    get doorMountingHeight() {
        return this._installation.doorMountingHeight;
    }
    set doorMountingHeight(doorMountingHeight) {
        this._installation.doorMountingHeight = doorMountingHeight;
    }
    get interiorTemperatureSetPointWinter() {
        return this._installation.interiorTemperatureSetPointWinter;
    }
    set interiorTemperatureSetPointWinter(interiorTemperatureSetPointWinter) {
        this._installation.interiorTemperatureSetPointWinter = interiorTemperatureSetPointWinter;
    }
    get interiorTemperatureSetPointSummer() {
        return this._installation.interiorTemperatureSetPointSummer;
    }
    set interiorTemperatureSetPointSummer(interiorTemperatureSetPointSummer) {
        this._installation.interiorTemperatureSetPointSummer = interiorTemperatureSetPointSummer;
    }
    get interiorRelativeHumidityWinter() {
        return this._installation.interiorRelativeHumidityWinter;
    }
    set interiorRelativeHumidityWinter(interiorRelativeHumidityWinter) {
        this._installation.interiorRelativeHumidityWinter = interiorRelativeHumidityWinter;
    }
    get interiorRelativeHumiditySummer() {
        return this._installation.interiorRelativeHumiditySummer;
    }
    set interiorRelativeHumiditySummer(interiorRelativeHumiditySummer) {
        this._installation.interiorRelativeHumiditySummer = interiorRelativeHumiditySummer;
    }
    get model() {
        return this._installation.model;
    }
    set model(model) {
        this._installation.model = model;
    }
    get heatingEnergyType() {
        return this._installation.heatingEnergyType;
    }
    set heatingEnergyType(heatingEnergyType) {
        this._installation.heatingEnergyType = heatingEnergyType;
    }
    get coolingEnergyType() {
        return this._installation.coolingEnergyType;
    }
    set coolingEnergyType(coolingEnergyType) {
        this._installation.coolingEnergyType = coolingEnergyType;
    }
    get calculateHeatingSaving() {
        return this._installation.calculateHeatingSaving;
    }
    set calculateHeatingSaving(calculateHeatingSaving) {
        this._installation.calculateHeatingSaving = calculateHeatingSaving;
    }
    get calculateCoolingSaving() {
        return this._installation.calculateCoolingSaving;
    }
    set calculateCoolingSaving(calculateCoolingSaving) {
        this._installation.calculateCoolingSaving = calculateCoolingSaving;
    }
    get hasHeating() {
        return this._installation.hasHeating;
    }
    set hasHeating(hasHeating) {
        this._installation.hasHeating = hasHeating;
    }
    get hasTemperatureSensor() {
        return this._installation.hasTemperatureSensor;
    }
    set hasTemperatureSensor(hasTemperatureSensor) {
        this._installation.hasTemperatureSensor = hasTemperatureSensor;
    }
    get heatingPower() {
        return this._installation.heatingPower;
    }
    set heatingPower(heatingPower) {
        this._installation.heatingPower = heatingPower;
    }
    get unconditionedHeatingTemperatureSetPoint() {
        return this._installation.unconditionedHeatingTemperatureSetPoint;
    }
    set unconditionedHeatingTemperatureSetPoint(unconditionedHeatingTemperatureSetPoint) {
        this._installation.unconditionedHeatingTemperatureSetPoint = unconditionedHeatingTemperatureSetPoint;
    }
}
