import { Auth, API } from 'aws-amplify';
import { key as X_API_KEY } from './x-api-key-air-curtain.json';
const API_NAME = 'AirCurtainClientAPI';
const APP_VERSION = '2.0';
const PROTOCAL_VERSION = '0.1';
export const getListAirCurtainUserRelation = async () => {
    try {
        const response = await post('getlistaircurtainuserrelation', {
            GetDetails: true,
        });
        if (response && response.data.statusCode == 200) {
            return response.data.body;
        }
        else {
            return [];
        }
    }
    catch (error) {
        console.error(error);
        return [];
    }
};
export const saveAirCurtain = async (airCurtain) => {
    try {
        const response = await post('updateaircurtain', airCurtain);
        return response.data.statusCode == 200;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
export const removeAirCurtain = async (aircurtainId, wcsVersion) => {
    try {
        await post('removeaircurtainuserrelation', {
            airCurtainId: aircurtainId,
            wcsVersion: wcsVersion,
        });
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
export const getAirCurtain = async (aircurtainId, wcsVersion) => {
    try {
        return await post('getaircurtain', {
            airCurtainId: aircurtainId,
            wcsVersion: wcsVersion,
        });
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
const post = async (endpoint, body) => {
    const session = await Auth.currentSession();
    const mergedBody = Object.assign({}, {
        AppVersion: APP_VERSION,
        ProtocolVersion: PROTOCAL_VERSION,
    }, body);
    const init = {
        headers: {
            Authorization: session.getIdToken().getJwtToken(),
            'x-api-key': X_API_KEY,
        },
        response: true,
        body: mergedBody,
    };
    const response = await API.post(API_NAME, '/dev/' + endpoint, init).catch((err) => {
        console.log(err);
    });
    // console.log('post response: ', response);
    return response;
};
