import { __decorate } from "tslib";
import { html, } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map';
import { BernerScreenBase } from '../berner-screen-base';
import { Router } from '@vaadin/router';
import { ORGANIZATION_TABS, EVENTS, loadStyle, RANGE_VALUES_TO_LABELS } from '../../api/ui';
import { RANGES, RATIOS, MEASUREMENTS } from '../../api/reporting';
import { MEASUREMENTS_LACKING_24HR_DATA, getConvertedTemperature } from '../../api/graphDataApi';
import { getInfographicDataAggregated, getInfographicData, } from '../../api/graphDataApi';
import { store } from '../../redux/store';
import '../berner-curtains';
import '../berner-curtain';
import '../berner-sites';
import '../berner-weather';
import '../graphs/berner-graph';
import '../berner-infographic';
import '../modals/berner-update-site-costs-modal';
let BernerDashboard = class BernerDashboard extends BernerScreenBase {
    constructor() {
        super();
        this._initialized = false;
        this._selectedSite = '';
        this._navBarCollapsed = false;
        this.refreshingData = false;
        this._measurementDataCached = false;
        this._weeklyMeasurementDataCached = false;
        this._showUpdateCostModal = false;
        this._selectedCurtain = '';
        this._selectedRange = RANGES.WEEKLY;
        this._showMetrics = true;
        this._weatherSiteId = '';
        this._sites = [];
        this._locationsBySite = new Map();
        this._airCurtainsBySite = new Map();
        this._savingsDataAgg = {
            value: 0,
            change: 0,
        };
        this._temperatureDataAgg = {
            value: 0,
            change: 0,
        };
        this._doorsOpenDataAgg = {
            value: 0,
            change: 0,
        };
        this._savingsDataByDevice = new Map();
        this._temperatureDataByDevice = new Map();
        this._doorsOpenDataByDevice = new Map();
        this.onTempUnitChanged = () => {
            this.requestUpdate();
        };
        this.onSiteSelected = async (e) => {
            this.selectedSite = e.detail.site;
        };
        this.onAirCurtainSelected = async (e) => {
            const selectedCurtainId = e.detail.curtain;
            const airCurtainSite = e.detail.site;
            this.selectedSite = airCurtainSite;
            this.selectedCurtain = selectedCurtainId;
        };
        this.onNavBarCollapseChange = async () => {
            const { navBarCollapsedState } = store.getState();
            this._navBarCollapsed = navBarCollapsedState;
        };
        this.onMinimalDataCached = async () => {
            this._weeklyMeasurementDataCached = true;
            this.refreshData(this.selectedRange);
        };
        this.onAllDataCached = async () => {
            this._measurementDataCached = true;
            this.refreshData(this.selectedRange);
        };
        this.onDataUpdated = async () => {
            this.refreshData(this.selectedRange);
        };
        this.onCostButton = async () => {
            this._showUpdateCostModal = true;
        };
        this.onCloseModal = async () => {
            this._showUpdateCostModal = false;
        };
        this.init = async () => {
            var _a;
            const accountInitialized = await super.checkInitialized();
            if (accountInitialized) {
                const allAirCurtains = await this._objectManager.getAllAirCurtains();
                this._sites = this._objectManager.getAllSites();
                this._weatherSiteId = (_a = this._sites[0]) === null || _a === void 0 ? void 0 : _a.siteID;
                for (const site of this._sites) {
                    const location = await this._objectManager.getLocation(site.siteID);
                    this._locationsBySite.set(site.siteID, location);
                    const siteCurtains = new Map();
                    for (const curtainGroupId of site.airCurtainGroups) {
                        const airCurtainGroup = this._objectManager.getAirCurtainGroup(curtainGroupId);
                        const groupAirCurtainIds = airCurtainGroup.airCurtains;
                        for (const id of groupAirCurtainIds) {
                            const airCurtain = allAirCurtains.find(curtain => curtain.airCurtainID === id);
                            siteCurtains.set(id, airCurtain);
                        }
                    }
                    this._airCurtainsBySite.set(site.siteID, siteCurtains);
                }
                this.refreshData(this._selectedRange);
                const { navBarCollapsedState, measurementDataCachedState } = store.getState();
                this._measurementDataCached = measurementDataCachedState;
                this._navBarCollapsed = navBarCollapsedState;
                this._initialized = true;
            }
        };
        this.firstUpdated = async () => {
            await loadStyle('./css/screens/berner-dashboard.css', this.shadowRoot);
        };
        this.init();
    }
    get selectedSite() {
        return this._selectedSite;
    }
    set selectedSite(value) {
        this._selectedSite = value;
        // reset curtain here to avoid refreshing twice
        this._selectedCurtain = '';
        (async () => {
            await this.refreshData(this.selectedRange);
        })();
        // set up weather data
        if (!this.selectedSite) {
            if (this._sites.length) {
                let i = 0;
                this._weatherSiteId = this._sites[i].siteID;
                this._weatherTickerTimeout = setInterval(() => {
                    i++;
                    if (i >= this._sites.length) {
                        i = 0;
                    }
                    this._weatherSiteId = this._sites[i].siteID;
                    this.requestUpdate();
                }, 60 * 1000);
            }
        }
        else {
            if (this._weatherTickerTimeout) {
                clearInterval(this._weatherTickerTimeout);
            }
            this._weatherSiteId = this.selectedSite;
        }
        this.requestUpdate();
    }
    get selectedCurtain() {
        return this._selectedCurtain;
    }
    set selectedCurtain(value) {
        this._selectedCurtain = value;
        this.refreshData(this.selectedRange);
    }
    get selectedRange() {
        return this._selectedRange;
    }
    set selectedRange(value) {
        this._selectedRange = value;
        this.refreshData(this.selectedRange);
    }
    async refreshData(selectedRange) {
        var _a;
        this.refreshingData = true;
        let airCurtainsToRepresent = [];
        if (this.selectedSite) {
            const siteCurtains = this._airCurtainsBySite.get(this.selectedSite);
            airCurtainsToRepresent = !this.selectedCurtain ? Array.from(siteCurtains.values()) : [siteCurtains.get(this.selectedCurtain)];
        }
        else {
            let allAirCurtains = [];
            for (const site of this._sites) {
                const siteCurtains = (_a = this._airCurtainsBySite.get(site.siteID)) === null || _a === void 0 ? void 0 : _a.values();
                if (siteCurtains) {
                    allAirCurtains = allAirCurtains.concat(Array.from(siteCurtains));
                }
            }
            airCurtainsToRepresent = !this.selectedCurtain ? allAirCurtains : [allAirCurtains.find(curtain => curtain.airCurtainID === this.selectedCurtain)];
        }
        this._savingsDataByDevice = await getInfographicData(selectedRange, MEASUREMENTS.SAVING, false, airCurtainsToRepresent);
        this._temperatureDataByDevice = await getInfographicData(selectedRange, MEASUREMENTS.AVERAGE_TEMP, true, airCurtainsToRepresent);
        this._doorsOpenDataByDevice = await getInfographicData(selectedRange, MEASUREMENTS.DOOR_OPEN_COUNT, false, airCurtainsToRepresent);
        this._savingsDataAgg = await getInfographicDataAggregated(selectedRange, MEASUREMENTS.SAVING, false, airCurtainsToRepresent);
        this._temperatureDataAgg = await getInfographicDataAggregated(selectedRange, MEASUREMENTS.AVERAGE_TEMP, true, airCurtainsToRepresent);
        this._doorsOpenDataAgg = await getInfographicDataAggregated(selectedRange, MEASUREMENTS.DOOR_OPEN_COUNT, false, airCurtainsToRepresent);
        this.refreshingData = false;
    }
    connectedCallback() {
        window.addEventListener(EVENTS.SITE_SELECTED, this.onSiteSelected);
        window.addEventListener(EVENTS.CURTAIN_SELECTED, this.onAirCurtainSelected);
        window.addEventListener(EVENTS.NAV_BAR_COLLAPSE_CHANGE, this.onNavBarCollapseChange);
        window.addEventListener(EVENTS.MINIMAL_DATA_CACHED, this.onMinimalDataCached);
        window.addEventListener(EVENTS.ALL_DATA_CACHED, this.onAllDataCached);
        window.addEventListener(EVENTS.DATA_UPDATED, this.onDataUpdated);
        window.addEventListener(EVENTS.CLOSE_MODAL, this.onCloseModal);
        window.addEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged);
        super.connectedCallback();
    }
    disconnectedCallback() {
        window.removeEventListener(EVENTS.SITE_SELECTED, this.onSiteSelected);
        window.removeEventListener(EVENTS.CURTAIN_SELECTED, this.onAirCurtainSelected);
        window.removeEventListener(EVENTS.NAV_BAR_COLLAPSE_CHANGE, this.onNavBarCollapseChange);
        window.removeEventListener(EVENTS.MINIMAL_DATA_CACHED, this.onMinimalDataCached);
        window.removeEventListener(EVENTS.ALL_DATA_CACHED, this.onAllDataCached);
        window.removeEventListener(EVENTS.DATA_UPDATED, this.onDataUpdated);
        window.removeEventListener(EVENTS.CLOSE_MODAL, this.onCloseModal);
        window.removeEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged);
        super.disconnectedCallback();
    }
    renderUpdateCostModal() {
        return html `<berner-update-site-costs-modal site-id="${this.selectedSite}"></berner-update-site-costs-modal>`;
    }
    renderSpinner() {
        return html `
        <div class="mx-auto my-auto pt-5 pb-5 spinner-container">
            <div
                class="spinner-border spinner-loader mx-auto"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>`;
    }
    renderGraph(measurementOne, measurementTwo, siteId = "") {
        if (!this._initialized)
            return html ``;
        let aircurtainIds = [];
        if (this.selectedCurtain) {
            aircurtainIds.push(this.selectedCurtain);
        }
        else if (this.selectedSite) {
            aircurtainIds = Array.from(this._airCurtainsBySite
                .get(this.selectedSite).keys());
        }
        else {
            for (const site of this._sites) {
                aircurtainIds = aircurtainIds.concat(Array.from(this._airCurtainsBySite
                    .get(site.siteID).keys()));
            }
        }
        const graphDevices = aircurtainIds.join(',');
        return html `
            ${MEASUREMENTS_LACKING_24HR_DATA.includes(measurementOne) && this.selectedRange === RANGES.ONE_DAY ?
            html `
            <section class="inner row mx-2">
                <span class="daily-data-unavailable mx-auto">
                    <p>Data is still being accumulated for the last 24 hours</p>
                </span>
            </section>`
            : html `
            <div class=${'col-xl-12 graph-container'}>
                ${this.refreshingData ? html `${this.renderSpinner()}` : html `
                    <berner-graph
                        device-ids=${graphDevices}
                        measurement-one=${measurementOne}
                        measurement-two=${measurementTwo}
                        plot-averages=${false}
                        aggregate=${!this.selectedCurtain}
                        sum=${measurementOne === MEASUREMENTS.DOOR_OPEN_COUNT}
                        minimal-ui=${true}
                        show-title=${measurementOne === MEASUREMENTS.EFFICIENCY}
                        title-position=${measurementOne === MEASUREMENTS.EFFICIENCY ? 'left' : ''}
                        range=${this._selectedRange}
                        ratio=${RATIOS.SIXTEEN_BY_NINE_NO_BORDER}
                        site-id=${siteId}
                    ></berner-graph>
                `}
            </div>`}`;
    }
    renderInfographic(measurement, value, change, selectedCurtainDisconnected) {
        const no_data = "No data available";
        let title = "";
        let subtitle = "";
        let colour = "";
        let displayValue = "";
        let displayChange = "";
        if (measurement === MEASUREMENTS.GROSS_SAVING || measurement === MEASUREMENTS.SAVING) {
            title = 'Cost Saving';
            subtitle = 'Cooling and heating';
            colour = 'mediumseagreen';
            displayValue = value === null ? no_data : `$${value === null || value === void 0 ? void 0 : value.toFixed(2)}`;
            displayChange = change === null ? no_data : change < 0 ? `-$${change}` : `+$${change}`;
        }
        else if (measurement === MEASUREMENTS.DOOR_OPEN_COUNT) {
            title = 'Traffic';
            subtitle = 'Data from door sensors';
            colour = 'royalblue';
            displayValue = value === null ? no_data : `${Math.ceil(value)}`;
            const absTrafficChange = Math.ceil(Math.abs(change));
            displayChange = change === null ? no_data : change < 0 ? `-${absTrafficChange}` : `+${absTrafficChange}`;
        }
        else if (measurement === MEASUREMENTS.AVERAGE_TEMP) {
            title = 'Temperature';
            subtitle = 'Average across all doors';
            colour = 'tomato';
            value = value !== null ? getConvertedTemperature(value) : null;
            change = change !== null ? getConvertedTemperature(change) : null;
            displayValue = value === null ? no_data : (value === null || value === void 0 ? void 0 : value.toLocaleString("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 })) + "\u00B0";
            displayChange = change === null ? no_data : change < 0 ? `${change === null || change === void 0 ? void 0 : change.toLocaleString("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 })}\u00B0` :
                `+${change === null || change === void 0 ? void 0 : change.toLocaleString("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 })}\u00B0`;
        }
        return html `
            <berner-infographic
                style="max-height: 100px;"
                title=${title}
                subtitle=${subtitle}
                value=${this.refreshingData ? "" : displayValue}
                change=${this.refreshingData ? "" : change}
                display-change=${this.refreshingData ? "" : displayChange}
                negative-change=${change < 0}
                colour=${colour}
                no-data=${value === null}
                curtain-offline=${selectedCurtainDisconnected}
            ></berner-infographic>`;
    }
    renderAirCurtains(height) {
        if (!this._initialized)
            return html ``;
        return html `
        <berner-curtains
            selected-site=${this.selectedSite}
            selected-air-curtain=${this.selectedCurtain}
            style="min-height: ${height}px; max-height: ${height}px;"
        ></berner-curtains>`;
    }
    renderSites(height) {
        if (!this._initialized)
            return html ``;
        return html `
        <berner-sites
            selected-site=${this.selectedSite}
            style="min-height: ${height}px; max-height: ${height}px"
            ;
        ></berner-sites>`;
    }
    renderWeather(height) {
        if (!this._initialized || !this._sites.length)
            return html ``;
        return html `
        <berner-weather
            sites=${this._sites.map((site) => { return site.siteID; }).join(':')}
            site-id=${this._weatherSiteId}
            style="min-height: ${height}px; max-height: ${height}px;"
        ></berner-weather>`;
    }
    renderSitesRow(title) {
        const onClickSites = () => {
            const event = new CustomEvent(EVENTS.NAVIGATE, {
                detail: { pageName: 'organization' },
            });
            window.dispatchEvent(event);
            Router.go(`content/organization?tab=${ORGANIZATION_TABS.OVERVIEW}`);
        };
        const onClickAirCurtains = () => {
            const event = new CustomEvent(EVENTS.NAVIGATE, {
                detail: { pageName: 'organization' },
            });
            window.dispatchEvent(event);
            Router.go(`content/organization?tab=${ORGANIZATION_TABS.AIRCURTAINS}`);
        };
        return html `
            <article class="row content-row pt-5">
                <span class="selection-title site-title mx-auto mb-2"
                    >${title}</span
                >
            </article>

            <section class="row content-row">
                <article class="col-xl-4 col-lg-12">
                    <div class="cardsites card mt-1">
                        <section class="card-body">
                            <div class="row content-row header">
                                <div class="col text-left">
                                    <div class="row">
                                        <div class="icon col-2">
                                            <img
                                                src="./assets/reports_icon_sites_black.svg"
                                                alt="sites"
                                            />
                                        </div>
                                        <span class="card-title title col-9">
                                            Sites
                                        </span>
                                    </div>
                                </div>
                                <div class="col nav-arrow text-right">
                                    <img
                                        src="./assets/icon_right_arrow.svg"
                                        @click=${onClickSites}
                                        alt="go to air sites"
                                    />
                                </div>
                            </div>
                            ${this.renderSites(160)}
                        </section>
                    </div>
                </article>

                <article class="col-xl-4 col-lg-12">
                    <div class="card mt-1">
                        <section class="card-body">
                            <div class="row content-row header">
                                <div class="col text-left">
                                    <div class="row">
                                        <div class="icon col-2">
                                            <img
                                                src="./assets/reports_icon_air_curtains_black.svg"
                                                alt="users"
                                            />
                                        </div>
                                        <span class="card-title title col-9">
                                            Air Curtains
                                        </span>
                                    </div>
                                </div>
                                <div class="col nav-arrow text-right">
                                    <img
                                        src="./assets/icon_right_arrow.svg"
                                        @click=${onClickAirCurtains}
                                        alt="go to air curtains"
                                    />
                                </div>
                            </div>
                            ${this.renderAirCurtains(160)}
                        </section>
                    </div>
                </article>

                <article class="col-xl-4 col-lg-12 mt-1">
                    ${this.renderWeather(252)}
                </article>
            </section>`;
    }
    renderSavings(savingsValue) {
        var _a, _b;
        let energyCostsEntered = false;
        let siteName = "";
        if (this.selectedSite) {
            const location = this._locationsBySite.get(this._selectedSite);
            energyCostsEntered = (location === null || location === void 0 ? void 0 : location.electricityRate) > 0 || (location === null || location === void 0 ? void 0 : location.oilRate) > 0 || location.naturalGasRate > 0;
            siteName = (_a = this._sites.find((site) => { return site.siteID === this.selectedSite; })) === null || _a === void 0 ? void 0 : _a.name;
        }
        return html `
            ${savingsValue !== null ? html `${this.renderGraph(MEASUREMENTS.EFFICIENCY, '')}` :
            this.selectedSite ?
                html `${energyCostsEntered ? html `<div class="savings-missing-container"><span class="my-auto">Not enough data for ${siteName}. Please check again later</span></div>`
                    : html `<div class="energy-button-container">
                        <button class="mdc-button mdc-button--raised custom-text-button" @click=${this.onCostButton}>
                            <span class="mdc-button__label">Update Energy Costs</span>
                        </button>
                    </div>
                `}` :
                html `<div class="savings-missing-container"><span class="my-auto">Not enough data for ${(_b = this._objectManager.getOrganization()) === null || _b === void 0 ? void 0 : _b.name}. Please check again later</span></div>`}
        `;
    }
    renderGraphRow() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        let selectedCurtainDisconnected = false;
        let currentCurtain;
        if (this.selectedCurtain) {
            for (const site of this._sites) {
                currentCurtain = this._airCurtainsBySite.get(site.siteID).get(this.selectedCurtain);
                if (currentCurtain) {
                    break;
                }
            }
            selectedCurtainDisconnected = !currentCurtain.connected;
        }
        const savingsValue = this.selectedCurtain
            ? (_b = (_a = this._savingsDataByDevice.get(this.selectedCurtain)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null
            : (_d = (_c = this._savingsDataAgg) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : null;
        const savingsChange = this.selectedCurtain
            ? (_f = (_e = this._savingsDataByDevice.get(this.selectedCurtain)) === null || _e === void 0 ? void 0 : _e.change) !== null && _f !== void 0 ? _f : null
            : (_g = this._savingsDataAgg.change) !== null && _g !== void 0 ? _g : null;
        const trafficValue = this.selectedCurtain
            ? (_j = (_h = this._doorsOpenDataByDevice.get(this.selectedCurtain)) === null || _h === void 0 ? void 0 : _h.value) !== null && _j !== void 0 ? _j : null
            : (_k = this._doorsOpenDataAgg.value) !== null && _k !== void 0 ? _k : null;
        const trafficChange = this.selectedCurtain
            ? (_m = (_l = this._doorsOpenDataByDevice.get(this.selectedCurtain)) === null || _l === void 0 ? void 0 : _l.change) !== null && _m !== void 0 ? _m : null
            : (_o = this._doorsOpenDataAgg.change) !== null && _o !== void 0 ? _o : null;
        const tempValue = this.selectedCurtain
            ? (_q = (_p = this._temperatureDataByDevice.get(this.selectedCurtain)) === null || _p === void 0 ? void 0 : _p.value) !== null && _q !== void 0 ? _q : null
            : (_r = this._temperatureDataAgg.value) !== null && _r !== void 0 ? _r : null;
        const tempChange = this.selectedCurtain
            ? (_t = (_s = this._temperatureDataByDevice.get(this.selectedCurtain)) === null || _s === void 0 ? void 0 : _s.change) !== null && _t !== void 0 ? _t : null
            : (_u = this._temperatureDataAgg.change) !== null && _u !== void 0 ? _u : null;
        const curtainsTitle = 'Viewing ' +
            (this.selectedCurtain
                ? currentCurtain.name
                : 'all air curtains');
        const temperatureSecondMeasurement = this.selectedRange === RANGES.ONE_DAY ? "" :
            this.selectedCurtain ? "" : this.selectedSite ? MEASUREMENTS.WEATHER_AVG_TEMP : "";
        return html `
        <section class="row content-row graph-row">
            <span class="selection-title mx-auto mt-3 mt-xl-0">
                ${curtainsTitle}
            </span>
            <section class="inner row mx-2">
                <article class="col-xl-4">
                    <div class="info-container my-3 mx-3">
                        ${this.selectedSite ? html `<button class=${classMap({ "cost-button": true, "selected": this._showUpdateCostModal })} @click=${this.onCostButton}>$</button>`
            : html ``}
                        ${this.renderInfographic(MEASUREMENTS.SAVING, savingsValue, savingsChange, selectedCurtainDisconnected)}
                    </div>
                    ${this.renderSavings(savingsValue)}
                </article>
                <article class="col-xl-4">
                    <div class="info-container my-3 mx-3">
                        ${this.renderInfographic(MEASUREMENTS.DOOR_OPEN_COUNT, trafficValue, trafficChange, selectedCurtainDisconnected)}
                    </div>
                    ${this.renderGraph(MEASUREMENTS.DOOR_OPEN_COUNT, '', '')}
                </article>
                <article class="col-xl-4">
                    <div class="info-container my-3 mx-3">
                        ${this.renderInfographic(MEASUREMENTS.AVERAGE_TEMP, tempValue, tempChange, selectedCurtainDisconnected)}
                    </div>
                    ${this.renderGraph(MEASUREMENTS.AVERAGE_TEMP, temperatureSecondMeasurement, this.selectedSite)}
                </article>
            </section>
        </section>`;
    }
    renderTitleRow() {
        var _a, _b;
        let pageTitle;
        const organizationTitle = (_b = (_a = this._objectManager) === null || _a === void 0 ? void 0 : _a.getOrganization()) === null || _b === void 0 ? void 0 : _b.name;
        if (organizationTitle) {
            if (organizationTitle.slice(-1).toLowerCase() === 's') {
                pageTitle =
                    organizationTitle.slice(0, organizationTitle.length - 1) +
                        "'s Dashboard";
            }
            else {
                pageTitle = organizationTitle + "'s Dashboard";
            }
        }
        return html ` <section
            class=${classMap({
            row: true,
            'content-row': true,
            'filter-row': true,
            header: true,
            'mb-3': true,
            'mb-xl-0': true,
            'col-12': true,
            'nav-bar-collapsed': this._navBarCollapsed,
        })}
        >
            <div class="title row col-lg-4 col-md-12 my-auto pt-2 pl-0">
                <div
                    class=${classMap({
            'mx-auto': true,
            'ml-lg-2': true,
            'd-none': !pageTitle,
        })}
                >
                    <img
                        src="./assets/reports_icon_dashboard-dark.svg"
                        alt="dashboard"
                    /><span>${pageTitle}</span>
                </div>
            </div>

            <div class="row date-buttons-container my-auto col-lg-8 col-md-12">
                <section class="date-buttons">
                    <button
                        type="button"
                        class="${classMap({
            selected: this.selectedRange === RANGES.ONE_DAY,
        })}"
                        @click=${() => {
            this.selectedRange = RANGES.ONE_DAY;
        }}
                    >
                        <span class="button-label">${RANGE_VALUES_TO_LABELS.get('one_day')}</span>
                    </button>

                    <button
                        type="button"
                        class="${classMap({
            selected: this.selectedRange === RANGES.TWO_DAYS,
        })}"
                        @click=${() => {
            this.selectedRange = RANGES.TWO_DAYS;
        }}
                    >
                        <span class="button-label">${RANGE_VALUES_TO_LABELS.get('two_days')}</span>
                    </button>

                    <button
                        type="button"
                        class="${classMap({
            selected: this.selectedRange === RANGES.WEEKLY,
        })}"
                        @click=${() => {
            this.selectedRange = RANGES.WEEKLY;
        }}
                    >
                        <span class="button-label">${RANGE_VALUES_TO_LABELS.get('weekly')}</span>
                    </button>

                    <button
                        type="button"
                        class="${classMap({
            selected: this.selectedRange === RANGES.MONTHLY,
        })}"
                        @click=${() => {
            this.selectedRange = RANGES.MONTHLY;
        }}
                    >
                        <span class="button-label">${RANGE_VALUES_TO_LABELS.get('monthly')}</span>
                    </button>

                    <button
                        type="button"
                        class="${classMap({
            selected: this.selectedRange === RANGES.YEARLY,
        })}"
                        @click=${() => {
            this.selectedRange = RANGES.YEARLY;
        }}
                    >
                        <span class="button-label">${RANGE_VALUES_TO_LABELS.get('yearly')}</span>
                    </button>
                </section>
            </div>
        </section>`;
    }
    renderContent() {
        const shouldRenderSpinner = this.selectedRange === RANGES.WEEKLY
            ? !this._weeklyMeasurementDataCached && !this._measurementDataCached : !this._measurementDataCached;
        const titularSite = this._sites.find((site) => {
            return site.siteID === this.selectedSite;
        });
        const title = 'Viewing ' + (titularSite ? `${titularSite.name}` : 'all sites');
        return html `
        ${this._showUpdateCostModal ? this.renderUpdateCostModal() : ''}
        ${this.renderSitesRow(title)}
        ${shouldRenderSpinner ? this.renderSpinner() : this.renderGraphRow()}`;
    }
    render() {
        return html `
        <link id="css-target">
        <div class="css-loading" id="root">
            ${this._objectManager ? this.renderTitleRow() : html ``}
            ${this._initialized ? this.renderContent() : html ``}
        </div>`;
    }
};
__decorate([
    state()
], BernerDashboard.prototype, "_initialized", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "_selectedSite", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "_navBarCollapsed", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "refreshingData", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "_measurementDataCached", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "_weeklyMeasurementDataCached", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "_showUpdateCostModal", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "_selectedCurtain", void 0);
__decorate([
    state()
], BernerDashboard.prototype, "selectedRange", null);
__decorate([
    state()
], BernerDashboard.prototype, "_showMetrics", void 0);
BernerDashboard = __decorate([
    customElement('berner-dashboard')
], BernerDashboard);
export { BernerDashboard };
