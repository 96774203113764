import Amplify from 'aws-amplify';
import amplifyConfig from './aws-exports.json';
import { Auth } from 'aws-amplify';
import { authFailed, setUserEmail } from '../redux/actions';
import { store } from '../redux/store';
Amplify.configure(amplifyConfig);
export default class Authenticate {
    static async authenticate(username, password) {
        console.log('authenticate called for u: ', username);
        let user;
        try {
            user = await Auth.signIn(username, password);
        }
        catch (e) {
            console.log('Auth.signIn failed: ', e);
            store.dispatch(authFailed(e));
        }
        if (user) {
            const currentCredentials = await Auth.currentCredentials();
            await Auth.updateUserAttributes(user, {
                'custom:cognitoIdentityId': currentCredentials.identityId,
            });
            store.dispatch(setUserEmail(username));
            return true;
        }
        else {
            return false;
        }
    }
    static async signup(username, password) {
        console.log('signup called for u: ', username);
        let signUpResult;
        try {
            signUpResult = await Auth.signUp(username, password, username);
        }
        catch (e) {
            console.log('Auth.signup failed: ', e);
            store.dispatch(authFailed(e));
            return undefined;
        }
        if (signUpResult) {
            store.dispatch(setUserEmail(username));
            return true;
        }
        else {
            return false;
        }
    }
    static async passwordResetRequest(username) {
        console.log('passwordReset called for u: ', username);
        let user;
        try {
            user = await Auth.forgotPassword(username);
        }
        catch (e) {
            console.log('Auth.forgotPassword failed: ', e);
            store.dispatch(authFailed(e));
        }
        if (user) {
            store.dispatch(setUserEmail(username));
            return true;
        }
        else {
            return false;
        }
    }
    static async passwordResetValidate(password, validationCode) {
        const { userEmail: passwordResetEmail } = store.getState();
        console.log('passwordResetValidate called for u: ', passwordResetEmail);
        try {
            await Auth.forgotPasswordSubmit(passwordResetEmail, validationCode, password);
        }
        catch (e) {
            console.log('Auth.forgotPasswordSubmit failed: ', e);
            store.dispatch(authFailed(e));
            return false;
        }
        return true;
    }
    static getAuthenticationError() {
        const { authError } = store.getState();
        return authError;
    }
    static async signOut() {
        try {
            await Auth.signOut();
            return true;
        }
        catch (e) {
            console.log('Auth.signOut failed: ', e);
            store.dispatch(authFailed(e));
            return false;
        }
    }
    static async getCurrentUserEmail() {
        var _a;
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false,
            });
            return (_a = user === null || user === void 0 ? void 0 : user.attributes) === null || _a === void 0 ? void 0 : _a.email;
        }
        catch (error) {
            console.error(error);
            return '';
        }
    }
}
Authenticate.USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';
Authenticate.NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException';
Authenticate.USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException';
Authenticate.CODE_MISMATCH_EXCEPTION = 'CodeMismatchException';
