import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { store } from '../redux/store';
import { router } from '../index';
import { loadStyle } from '../api/ui';
let BernerAuthNavigation = class BernerAuthNavigation extends LitElement {
    constructor() {
        super(...arguments);
        this.location = router.location;
        this._title = '';
        this._message = '';
        this._pageName = '';
        this.firstUpdated = async () => {
            const state = store.getState();
            const applicationText = state.applicationText;
            const path = location.pathname.split('/');
            this._pageName = path[path.length - 1];
            console.log('_pageName: ', this._pageName);
            if (this._pageName === 'auth')
                this._pageName = 'login';
            if (applicationText !== null &&
                applicationText['sidebar'][this._pageName]) {
                this._title = applicationText['sidebar'][this._pageName]['title'];
                this._message =
                    applicationText['sidebar'][this._pageName]['message'];
            }
            await loadStyle('./css/berner-auth-navigation.css', this.shadowRoot);
            this.requestUpdate();
        };
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    renderSideBar() {
        return html ` <main class="side-main">
            <div class="content-container">
                <section class="content-row pt-0 pt-lg-2 content-row-0">
                    <div class="icons-container">
                        <div class="logo float-left">
                            <img
                                src="./assets/logo_white.svg"
                                alt="Berner logo"
                            />
                        </div>

                        <div class="icons float-right mt-2">
                            <a
                                href="https://www.facebook.com/bernerinternational/"
                                target="_blank"
                            >
                                <img
                                    src="./assets/login_icon_facebook.svg"
                                    alt="Facebook icon"
                                    class="mr-3"
                                    ;
                                />
                            </a>

                            <a
                                href="https://twitter.com/BernerAirDoors"
                                target="_blank"
                            >
                                <img
                                    src="./assets/login_icon_twitter.svg"
                                    alt="Twitter icon"
                                    class="mr-3"
                                    ;
                                />
                            </a>

                            <a
                                href="https://www.linkedin.com/company/bernerinternational"
                                target="_blank"
                            >
                                <img
                                    src="./assets/login_icon_linkedin.svg"
                                    alt="Linkedin icon"
                                />
                            </a>
                        </div>
                    </div>
                </section>
                <section class="content-row d-none d-lg-flex content-row-2">
                    <span>
                        <h4>${this._title}</h4>
                        <p>${this._message}</p>
                    </span>
                    ${this._pageName == 'login' || this._pageName == 'create-org' || this._pageName == 'create-first-site'
            ? html `<a href="https://berner.com" target="_blank"
                              >Read more here</a
                          >`
            : html ``}
                </section>
                <section class="content-row d-none d-lg-flex content-row-3">
                    <span>
                        @2022 Berner International |
                        <a href="https://berner.com" target="_blank"
                            >Privacy Policy </a
                        >|<a href="https://berner.com" target="_blank">
                            Contact Us
                        </a>
                    </span>
                </section>
            </div>
        </main>`;
    }
    render() {
        return html `
            <link id="css-target">
            <div class="css-loading" id="root">
                <main class="container-fluid">
                    <div class="row content-row">
                        <aside id="sidebar" class="col-lg-4 p-0">
                            ${this.renderSideBar()}
                        </aside>

                        <div class="col-lg-8">
                            <slot></slot>
                        </div>
                    </div>
                </main>
            </div>
        `;
    }
};
__decorate([
    property({ type: Object })
], BernerAuthNavigation.prototype, "location", void 0);
__decorate([
    state()
], BernerAuthNavigation.prototype, "_title", void 0);
__decorate([
    state()
], BernerAuthNavigation.prototype, "_message", void 0);
__decorate([
    state()
], BernerAuthNavigation.prototype, "_pageName", void 0);
BernerAuthNavigation = __decorate([
    customElement('berner-auth-navigation')
], BernerAuthNavigation);
export { BernerAuthNavigation };
