import { AirCurtain } from './airCurtain';
import { Account } from './account';
import { AirCurtainGroup } from './airCurtainGroup';
import { Identity } from './identity';
import { Organization } from './organization';
import { Role } from './role';
import { Site } from './site';
import { SiteGroup } from './siteGroup';
import { System } from './system';
import { User } from './user';
import { UserGroup } from './userGroup';
import { Invitation } from './invitation';
import { Location } from './location';
import { Installation } from './installation';
import { getUserPolicies, getObjects, getShadow, getInstallations, getUnitIDs, getCognitoUser as getCognitoUserAPI, } from './../api/webApplicationApi';
import { getListAirCurtainUserRelation } from './../api/airCurtainClientApi';
import { Bin } from './bin';
const systemID = '15140447-f0c3-405e-a789-6f040bf2d754';
const ownerRoleID = '40c461cb-8866-468c-8dfc-1a0bf28e5b9a';
export class ObjectManager {
    constructor(identityID, email) {
        this._users = new Map();
        this._identities = new Map();
        this._sites = new Map();
        this._userGroups = new Map();
        this._airCurtainGroups = new Map();
        this._airCurtains = new Map();
        this._roles = new Map();
        this._invitations = new Map();
        this._locations = new Map();
        this._installations = new Map();
        this._lastError = undefined;
        this._identityID = identityID;
        this._email = email;
    }
    async initialize() {
        this._lastError = undefined;
        // concurrent load of System, Identity
        const getSystemRequest = {
            objectType: 'system',
            keys: [systemID],
        };
        const getIdentityRequest = {
            objectType: 'identity',
            keys: [this._identityID],
        };
        const getInviteRequest = {
            objectType: 'invitation',
            keys: [this._email],
        };
        let invitation = undefined;
        let identity = undefined;
        let responses = await getObjects([
            getSystemRequest,
            getIdentityRequest,
            getInviteRequest,
        ]);
        responses.forEach((response) => {
            switch (response.objectType) {
                case 'system':
                    this._system = System.createFromObject(response.objects[0]);
                    break;
                case 'identity':
                    if (response.objects.length > 0) {
                        identity = Identity.createFromObject(response.objects[0]);
                    }
                    break;
                case 'invitation':
                    if (response.objects.length > 0) {
                        invitation = Invitation.createFromObject(response.objects[0]);
                    }
                    break;
            }
        });
        if (invitation != undefined) {
            if (identity == undefined) {
                //accept invitation
                const result = await this.acceptInvitation(invitation, this._identityID);
                if (result != undefined) {
                    identity = result;
                }
            }
            else {
                //delete invitation
                await this.deleteInvitation(invitation);
            }
        }
        // load roles
        if (identity != undefined) {
            this._identity = identity;
            //identity found load user and system roles
            // in simple scenario, one user per identity
            const getUserRequest = {
                objectType: 'user',
                keys: [this._identity.users[0]],
            };
            const getSystemRolesRequest = {
                objectType: 'role',
                keys: this._system.roles,
            };
            responses = await getObjects([
                getUserRequest,
                getSystemRolesRequest,
            ]);
            responses.forEach((response) => {
                switch (response.objectType) {
                    case 'user':
                        if (response.objects.length > 0) {
                            this._user = User.createFromObject(response.objects[0]);
                        }
                        break;
                    case 'role':
                        response.objects.forEach((obj) => {
                            this._roles.set(obj.roleID, Role.createFromObject(obj));
                        });
                        break;
                }
            });
            if (!this._user) {
                this._lastError = new Error('identity: ' +
                    this._identityID +
                    ' is denied access to the system');
                return false;
            }
            await this.loadAccountAndAll();
            return true;
        }
        //no identity found, IT SHOULD HAVE BEEN CREATED ON INDIVIDUAL ACCEPTING INVITE
        this._lastError = new Error('identity: ' +
            this._identityID +
            ' is unknown. Please check your email for an invitation');
        return false;
    }
    async acceptInvitation(invitation, identityID) {
        // get user from invite
        const user = new User(invitation.userID);
        let result = await user.load();
        if (!result)
            return undefined;
        // some validation
        if (user.invitationID != invitation.invitationID)
            return undefined;
        if (user.accountID != invitation.accountID)
            return undefined;
        //create identity
        const identity = new Identity(identityID);
        identity.email = invitation.invitationID;
        identity.addUser(user);
        result = await identity.save();
        if (!result)
            return undefined;
        // user mods (removing the reference to the invitationID will activate a trigger on the objUsers table)
        user.invitationID = undefined;
        user.identityID = identity.identityID;
        user.acceptedInvitationDate = new Date();
        result = await user.save();
        if (!result)
            return undefined;
        return identity;
    }
    async deleteInvitation(invitation) {
        // get user from invite
        const user = new User(invitation.userID);
        let result = await user.load();
        if (!result)
            return false;
        // some validation
        if (user.invitationID != invitation.invitationID)
            return false;
        if (user.accountID != invitation.accountID)
            return false;
        // user mods (removing the reference to the invitationID will activate a trigger on the objUsers table)
        user.invitationID = undefined;
        result = await user.save();
        if (!result)
            return false;
        return true;
    }
    get lastError() {
        return this._lastError;
    }
    createAccount(name) {
        const account = Account.createNew();
        account.name = name;
        return account;
    }
    createUser(name, firstName, lastName) {
        const user = User.createNew();
        user.name = name;
        user.firstName = firstName;
        user.lastName = lastName;
        user.accountID = this._account.accountID;
        return user;
    }
    createBin() {
        const bin = Bin.createNew();
        bin.accountID = this._account.accountID;
        return bin;
    }
    createLocation(siteID, latitude, longitude, naturalGasRate, electricityRate, oilRate, powerCycles, address) {
        const location = new Location(siteID);
        location.latitude = latitude;
        location.longitude = longitude;
        location.naturalGasRate = naturalGasRate;
        location.electricityRate = electricityRate;
        location.oilRate = oilRate;
        location.powerCycles = powerCycles;
        location.postalCode = address;
        this._locations.set(siteID, location);
        return location;
    }
    createOrganization(name) {
        const organization = Organization.createNew();
        organization.name = name;
        organization.accountID = this._account.accountID;
        return organization;
    }
    createUserGroup(name) {
        const userGroup = UserGroup.createNew();
        userGroup.name = name;
        userGroup.accountID = this._account.accountID;
        return userGroup;
    }
    createSiteGroup(name) {
        const siteGroup = SiteGroup.createNew();
        siteGroup.name = name;
        siteGroup.accountID = this._account.accountID;
        return siteGroup;
    }
    createSite(name) {
        const site = Site.createNew();
        site.name = name;
        site.accountID = this._account.accountID;
        return site;
    }
    createAirCurtainGroup(name) {
        const airCurtainGroup = AirCurtainGroup.createNew();
        airCurtainGroup.name = name;
        airCurtainGroup.accountID = this._account.accountID;
        return airCurtainGroup;
    }
    linkOwnerToAccount(owner) {
        if (owner.accountID != this._account.accountID) {
            throw new Error("user doesn't belong to account");
        }
        this._account.ownerUserID = owner.userID;
        owner.headObjectType = 'account';
        owner.headObjectID = this._account.accountID;
    }
    linkBinToAccount(bin) {
        if (bin.accountID != this._account.accountID) {
            throw new Error("bin doesn't belong to account");
        }
        this._account.binID = bin.binID;
    }
    linkOrganizationToAccount(organization) {
        if (organization.accountID != this._account.accountID) {
            throw new Error("organization doesn't belong to account");
        }
        this._account.organizationID = organization.organizationId;
    }
    addSiteGroupToOrganization(organization, siteGroup) {
        organization.addSiteGroup(siteGroup);
        siteGroup.parentObjectType = 'organization';
        siteGroup.parentObjectID = organization.organizationId;
    }
    removeSiteGroupFromOrganization(organization, siteGroup) {
        organization.deleteSiteGroup(siteGroup);
        siteGroup.parentObjectType = undefined;
        siteGroup.parentObjectID = undefined;
    }
    addUserGroupToSite(site, userGroup) {
        site.addUserGroup(userGroup);
        userGroup.parentObjectType = 'site';
        userGroup.parentObjectID = site.siteID;
        userGroup.headObjectType = 'site';
        userGroup.headObjectID = site.siteID;
    }
    removeUserGroupFromSite(site, userGroup) {
        site.deleteUserGroup(userGroup);
        userGroup.parentObjectType = undefined;
        userGroup.parentObjectID = undefined;
    }
    addUserToIdentity(identity, user) {
        identity.addUser(user);
        user.identityID = identity.identityID;
    }
    removeUserFromIdentity(identity, user) {
        identity.deleteUser(user);
        user.identityID = undefined;
    }
    addSiteToSiteGroup(siteGroup, site) {
        siteGroup.addSite(site);
        site.siteGroupID = siteGroup.siteGroupID;
    }
    removeSiteFromSiteGroup(siteGroup, site) {
        siteGroup.deleteSite(site);
        site.siteGroupID = undefined;
    }
    addAirCurtainGroupToSite(site, airCurtainGroup) {
        site.addAirCurtainGroup(airCurtainGroup);
        airCurtainGroup.parentObjectType = 'site';
        airCurtainGroup.parentObjectID = site.siteID;
    }
    removeAirCurtainGroupFromSite(site, airCurtainGroup) {
        site.deleteAirCurtainGroup(airCurtainGroup);
        airCurtainGroup.parentObjectType = undefined;
        airCurtainGroup.parentObjectID = undefined;
    }
    addUserToUserGroup(userGroup, user) {
        userGroup.addUser(user);
        user.addUserGroup(userGroup);
    }
    removeUserFromUserGroup(userGroup, user) {
        userGroup.deleteUser(user);
        user.deleteUserGroup(userGroup);
    }
    addAirCurtainToAirCurtainGroup(airCurtainGroup, airCurtain) {
        airCurtainGroup.addAirCurtain(airCurtain);
        airCurtain.addAirCurtainGroup(airCurtainGroup);
    }
    removeAirCurtainFromAirCurtainGroup(airCurtainGroup, airCurtain) {
        airCurtainGroup.deleteAirCurtain(airCurtain);
        airCurtain.deleteAirCurtainGroup(airCurtainGroup);
    }
    addAirCurtainToUser(user, airCurtain) {
        user.addAirCurtain(airCurtain);
    }
    removeAirCurtainFromUser(user, airCurtain) {
        user.deleteAirCurtain(airCurtain);
    }
    addRoleToUser(user, role) {
        user.addRole(role);
    }
    removeRoleFromUser(user, role) {
        user.deleteRole(role);
    }
    getSystem() {
        return this._system;
    }
    getOrganization() {
        return this._organization;
    }
    getAccount() {
        return this._account;
    }
    isOwner() {
        return this._isOwner;
    }
    getOwner() {
        return this._owner;
    }
    getCurrentIdentity() {
        return this._identity;
    }
    getSiteGroup() {
        return this._siteGroup;
    }
    getSite(siteID) {
        if (this._sites.has(siteID)) {
            return this._sites.get(siteID);
        }
        return undefined;
    }
    getAllSites() {
        return Array.from(this._sites, ([key, value]) => value);
    }
    getUserGroup(siteGroupID) {
        if (this._userGroups.has(siteGroupID)) {
            return this._userGroups.get(siteGroupID);
        }
        return undefined;
    }
    async getUser(userID) {
        if (this._users.has(userID)) {
            return this._users.get(userID);
        }
        else {
            const user = new User(userID);
            const result = await user.load();
            if (result) {
                this._users.set(userID, user);
                return user;
            }
            return undefined;
        }
    }
    async getAllUsers() {
        const users = [];
        this._userGroups.forEach((userGroup) => {
            userGroup.users.forEach((userId) => {
                users.push(userId);
            });
        });
        await this.loadUsers(users);
        return Array.from(this._users, ([key, value]) => value);
    }
    async getAllUsersFromUserGroup(userGroup) {
        await this.loadUsers(userGroup.users);
        const users = Array.from(this._users, ([key, value]) => value);
        return users.filter((user) => {
            return user.userGroups.includes(userGroup.userGroupID);
        });
    }
    async getIdentity(identityID) {
        if (this._identities.has(identityID)) {
            return this._identities.get(identityID);
        }
        else {
            const identity = new Identity(identityID);
            const result = await identity.load();
            if (result) {
                this._identities.set(identityID, identity);
                return identity;
            }
            return undefined;
        }
    }
    async getAllIdentities() {
        const identities = [];
        this._users.forEach((user) => {
            //invitees have no identityID
            if (user.identityID) {
                identities.push(user.identityID);
            }
        });
        await this.loadIdentities(identities);
        return Array.from(this._identities, ([key, value]) => value);
    }
    async getIdentities(identityIDs) {
        await this.loadIdentities(identityIDs);
        const identities = Array.from(this._identities, ([key, value]) => value);
        return identities.filter((identity) => {
            return identityIDs.includes(identity.identityID);
        });
    }
    getAirCurtainGroup(airCurtainID) {
        if (this._airCurtainGroups.has(airCurtainID)) {
            return this._airCurtainGroups.get(airCurtainID);
        }
        return undefined;
    }
    async getAirCurtain(airCurtainID) {
        if (this._airCurtains.has(airCurtainID)) {
            return this._airCurtains.get(airCurtainID);
        }
        else {
            const airCurtain = new AirCurtain(airCurtainID);
            const result = await airCurtain.load();
            if (result) {
                this._airCurtains.set(airCurtainID, airCurtain);
                return airCurtain;
            }
            return undefined;
        }
    }
    async getAllAirCurtains() {
        const airCurtains = [];
        this._airCurtainGroups.forEach((airCurtainGroup) => {
            airCurtainGroup.airCurtains.forEach((airCurtainId) => {
                if (!airCurtains.includes(airCurtainId)) {
                    airCurtains.push(airCurtainId);
                }
            });
        });
        await this.loadAirCurtains(airCurtains);
        return Array.from(this._airCurtains, ([key, value]) => value);
    }
    async getAllAirCurtainsFromAirCurtainGroup(airCurtainGroup) {
        await this.loadAirCurtains(airCurtainGroup.airCurtains);
        const airCurtains = Array.from(this._airCurtains, ([key, value]) => value);
        return airCurtains.filter((airCurtain) => {
            var _a;
            //TODO check: newly assigned air curtains have airCurtainGroupID but no airCurtainGroups array
            return (_a = airCurtain.airCurtainGroups) === null || _a === void 0 ? void 0 : _a.includes(airCurtainGroup.airCurtainGroupID);
        });
    }
    getAllRoles() {
        return Array.from(this._roles, ([key, value]) => value);
    }
    getRole(roleID) {
        if (this._roles.has(roleID)) {
            return this._roles.get(roleID);
        }
        return undefined;
    }
    getUserPolicies() {
        return this._userPolicies;
    }
    // public getAllTrashesFromBin():Array<omTrash> {
    //     return this._bin.trashes;
    // }
    // public async addTrashToBin(trash:omTrash):Promise<boolean>{
    //     this._bin.addTrash(trash);
    //     return await this._bin.save();
    // }
    // public async removeTrashFromBin(trash:omTrash):Promise<boolean>{
    //     this._bin.deleteTrash(trash);
    //     return await this._bin.save();
    // }
    get bin() {
        return this._bin;
    }
    async getInvitation(invitationID) {
        if (this._invitations.has(invitationID)) {
            return this._invitations.get(invitationID);
        }
        else {
            const invitation = new Invitation(invitationID);
            const result = await invitation.load();
            if (result) {
                this._invitations.set(invitationID, invitation);
                return invitation;
            }
            return undefined;
        }
    }
    async getUnitIDs(orderId) {
        return await getUnitIDs(orderId);
    }
    async getInstallations(requestedInstallations) {
        const requestInstallationObjects = [];
        for (const requestedInstallation of requestedInstallations) {
            const key = requestedInstallation.siteID + "|" + requestedInstallation.thingName;
            if (!this._installations.has(key)) {
                const installation = {
                    thingName: requestedInstallation.thingName,
                    siteID: requestedInstallation.siteID,
                    applicationType: undefined,
                    openingWidth: undefined,
                    openingHeight: undefined,
                    doorMountingHeight: undefined,
                    interiorTemperatureSetPointWinter: undefined,
                    interiorTemperatureSetPointSummer: undefined,
                    interiorRelativeHumidityWinter: undefined,
                    interiorRelativeHumiditySummer: undefined,
                    model: undefined,
                    heatingEnergyType: undefined,
                    coolingEnergyType: undefined,
                    calculateHeatingSaving: undefined,
                    calculateCoolingSaving: undefined,
                    hasHeating: undefined,
                    hasTemperatureSensor: undefined,
                    heatingPower: undefined,
                    unconditionedHeatingTemperatureSetPoint: undefined,
                };
                requestInstallationObjects.push(installation);
            }
        }
        if (requestInstallationObjects.length) {
            const responseInstallations = await getInstallations(requestInstallationObjects);
            for (const installation of responseInstallations) {
                const key = installation.siteID + "|" + installation.thingName;
                this._installations.set(key, Installation.createFromObject(installation));
            }
        }
        return this._installations;
    }
    async getInstallation(siteID, airCurtainID) {
        const key = siteID + "|" + airCurtainID;
        if (this._installations.has(key)) {
            return this._installations.get(key);
        }
        else {
            const installation = new Installation(airCurtainID, siteID);
            const result = await installation.load();
            if (result) {
                this._installations.set(key, installation);
                return installation;
            }
            return installation;
        }
    }
    async getLocation(siteID) {
        if (this._locations.has(siteID)) {
            return this._locations.get(siteID);
        }
        else {
            const location = new Location(siteID);
            const result = await location.load();
            if (result) {
                this._locations.set(siteID, location);
                return location;
            }
            return undefined;
        }
    }
    async createDefaultAccount(email, accountName, userName, userFirstName, userLastName, organizationName, siteName) {
        const promises = []; //will save all in batch
        if (this._roles.size == 0) {
            const getSystemRolesRequest = {
                objectType: 'role',
                keys: this._system.roles,
            };
            const responses = await getObjects([getSystemRolesRequest]);
            responses[0].objects.forEach((obj) => {
                this._roles.set(obj.roleID, Role.createFromObject(obj));
            });
        }
        // create identity
        this._identity = new Identity(this._identityID);
        this._identity.email = email;
        // create account
        this._account = this.createAccount(accountName);
        // create bin
        this._bin = this.createBin();
        // create user (owner)
        this._user = this.createUser(userName, userFirstName, userLastName);
        //link identity with user
        this.addUserToIdentity(this._identity, this._user);
        //link admin role to user
        this.addRoleToUser(this._user, this._roles.get(ownerRoleID));
        //link account with owner
        this.linkOwnerToAccount(this._user);
        //link account to bin
        this.linkBinToAccount(this._bin);
        //save user to have permissions set on backend
        let result = await this._identity.save(); // create identity first, api will check that we create a user for this identity
        if (!result)
            console.log(this._identity.lastError);
        // await this.sleep(1000);
        result = await this._user.save();
        if (!result)
            console.log(this._user.lastError);
        this._userPolicies = await getUserPolicies([]);
        this._users.set(this._user.userID, this._user);
        this._owner = this._user;
        this._isOwner = true;
        // create organization
        this._organization = this.createOrganization(organizationName);
        // create site group
        this._siteGroup = this.createSiteGroup('Sites');
        // create default site
        const defaultSite = this.createSite(siteName);
        this._sites.set(defaultSite.siteID, defaultSite);
        // create default user group
        const defaultUserGroup = this.createUserGroup('Users');
        this._userGroups.set(defaultUserGroup.userGroupID, defaultUserGroup);
        // create default air curtain group
        const defaultAirCurtainGroup = this.createAirCurtainGroup('Air Curtains');
        this._airCurtainGroups.set(defaultAirCurtainGroup.airCurtainGroupID, defaultAirCurtainGroup);
        //link user to user group
        this.addUserToUserGroup(defaultUserGroup, this._user);
        //link account with organization
        this.linkOrganizationToAccount(this._organization);
        //link organization with site group
        this.addSiteGroupToOrganization(this._organization, this._siteGroup);
        //link site to site group
        this.addSiteToSiteGroup(this._siteGroup, defaultSite);
        //link air curtain group to site
        this.addAirCurtainGroupToSite(defaultSite, defaultAirCurtainGroup);
        //link user group to site
        this.addUserGroupToSite(defaultSite, defaultUserGroup);
        /*
        //get all user air curtains from mobile api, attach to user and to default air curtain group
        if (!this._identity.email.includes('berner.com')) {
            // berner users can't attach air curtains to their own account, else they will be unavaillable to others as we all use the sames
            // admin will attach them manually if required
            const airCurtains = await this.getUserAirCurtainsFromMobileAPI();
            airCurtains.forEach((airCurtain) => {
                this._airCurtains.set(airCurtain.airCurtainID, airCurtain);
                this.addAirCurtainToUser(this._user, airCurtain);
                this.addAirCurtainToAirCurtainGroup(
                    defaultAirCurtainGroup,
                    airCurtain
                );
                promises.push(airCurtain.saveProperties());
            });
        }
        */
        //save all
        promises.push(this._account.save());
        promises.push(this._bin.save());
        promises.push(this._user.save());
        promises.push(this._organization.save());
        promises.push(defaultUserGroup.save());
        promises.push(this._siteGroup.save());
        promises.push(defaultSite.save());
        promises.push(defaultAirCurtainGroup.save());
        const results = await Promise.all(promises);
        //load shadows
        promises.length = 0;
        if (this._airCurtains.size > 0) {
            this._airCurtains.forEach((airCurtain) => {
                promises.push(airCurtain.refreshShadow());
            });
            await Promise.all(promises);
        }
        return !results.includes(false);
    }
    async loadAccountAndAll() {
        const promises = [];
        // concurrently load account, usr policies and user air curtains
        const getAccountRequest = {
            objectType: 'account',
            keys: [this._user.accountID],
        };
        const getUserAirCurtainsRequest = {
            objectType: 'airCurtain',
            keys: this._user.airCurtains,
        };
        promises.push(getObjects([getAccountRequest, getUserAirCurtainsRequest]));
        promises.push(getUserPolicies([]));
        this._user.airCurtains.forEach((airCurtainID) => {
            const getShadowRequest = { airCurtainID };
            promises.push(getShadow(getShadowRequest));
        });
        let responses = await Promise.all(promises);
        let getObjectResponses = responses[0];
        this._userPolicies = responses[1];
        const shadows = new Map();
        for (let i = 2; i < responses.length - 1; i++) {
            shadows.set(responses[i].airCurtainID, responses[i]);
        }
        getObjectResponses.forEach((response) => {
            switch (response.objectType) {
                case 'account':
                    this._account = Account.createFromObject(response.objects[0]);
                    break;
                case 'airCurtain':
                    response.objects.forEach((obj) => {
                        this._airCurtains.set(obj.airCurtainID, AirCurtain.createFromObject(obj, shadows.get(obj.airCurtainID)));
                    });
                    break;
            }
        });
        //is owner logged in?
        this._isOwner = this._account.ownerUserID == this._user.userID;
        if (this._isOwner) {
            this._owner = this._user;
        }
        else {
            this._owner = new User(this._account.ownerUserID);
            await this._owner.load();
            this._users.set(this._account.ownerUserID, this._owner);
        }
        //load organization
        const organizationID = this._account.organizationID;
        this._organization = new Organization(organizationID);
        await this._organization.load();
        //load site group
        const siteGroupID = this._organization.siteGroups[0]; // in simple scenario, one site group only
        this._siteGroup = new SiteGroup(siteGroupID);
        await this._siteGroup.load();
        //load sites
        promises.length = 0;
        const getSitesRequest = {
            objectType: 'site',
            keys: this._siteGroup.sites,
        };
        promises.push(getObjects([getSitesRequest]));
        responses = await Promise.all(promises);
        const getSitesResponse = responses[0];
        getSitesResponse.forEach((response) => {
            response.objects.forEach((obj) => {
                this._sites.set(obj.siteID, Site.createFromObject(obj));
            });
        });
        const getSiteGroupsRequest = {
            objectType: 'airCurtainGroup',
            keys: [],
        };
        const getUserGroupsRequest = {
            objectType: 'userGroup',
            keys: [],
        };
        const getBinRequest = {
            objectType: 'bin',
            keys: [this._account.binID],
        };
        promises.length = 0;
        this._sites.forEach((site) => {
            getSiteGroupsRequest.keys.push(site.airCurtainGroups[0]); // in simple scenario, one air curtain group per site
            getUserGroupsRequest.keys.push(site.userGroups[0]); // in simple scenario, one user group per site
        });
        const getObjectsRequest = [];
        getObjectsRequest.push(getSiteGroupsRequest);
        getObjectsRequest.push(getUserGroupsRequest);
        if (this._account.binID != undefined) {
            getObjectsRequest.push(getBinRequest);
        }
        getObjectResponses = await getObjects(getObjectsRequest);
        getObjectResponses.forEach((response) => {
            switch (response.objectType) {
                case 'airCurtainGroup':
                    response.objects.forEach((obj) => {
                        this._airCurtainGroups.set(obj.airCurtainGroupID, AirCurtainGroup.createFromObject(obj));
                    });
                    break;
                case 'userGroup':
                    response.objects.forEach((obj) => {
                        this._userGroups.set(obj.userGroupID, UserGroup.createFromObject(obj));
                    });
                    break;
                case 'bin':
                    if (response.objects.length > 0) {
                        this._bin = Bin.createFromObject(response.objects[0]);
                    }
                    break;
            }
        });
    }
    async loadAirCurtains(airCurtainIDs) {
        // load in batch of 100 the air curtains ID passed to the function
        const promises = [];
        const keys = [];
        airCurtainIDs.forEach((airCurtainID) => {
            if (!this._airCurtains.has(airCurtainID)) {
                keys.push(airCurtainID);
                if (keys.length == 100) {
                    const getAirCurtainsRequest = {
                        objectType: 'airCurtain',
                        keys: [...keys],
                    };
                    promises.push(getObjects([getAirCurtainsRequest]));
                    keys.length = 0;
                }
            }
        });
        if (keys.length > 0) {
            const getAirCurtainsRequest = {
                objectType: 'airCurtain',
                keys: [...keys],
            };
            promises.push(getObjects([getAirCurtainsRequest]));
            keys.length = 0;
        }
        if (promises.length > 0) {
            const responses = await Promise.all(promises);
            for (let i = 0; i < responses.length; i++) {
                const response = responses[i][0];
                if (response) {
                    response.objects.forEach((obj) => {
                        if (!this._airCurtains.has(obj.airCurtainID)) {
                            this._airCurtains.set(obj.airCurtainID, AirCurtain.createFromObject(obj, undefined));
                        }
                    });
                }
            }
        }
        return true;
    }
    async loadUsers(userIDs) {
        // load in batch of 100 the user ID passed to the function
        const promises = [];
        const keys = [];
        userIDs.forEach((userID) => {
            if (!this._users.has(userID)) {
                keys.push(userID);
                if (keys.length == 100) {
                    const getUsersRequest = {
                        objectType: 'user',
                        keys: [...keys],
                    };
                    promises.push(getObjects([getUsersRequest]));
                    keys.length = 0;
                }
            }
        });
        if (keys.length > 0) {
            const getUsersRequest = {
                objectType: 'user',
                keys: [...keys],
            };
            promises.push(getObjects([getUsersRequest]));
            keys.length = 0;
        }
        if (promises.length > 0) {
            const responses = await Promise.all(promises);
            for (let i = 0; i < responses.length; i++) {
                const response = responses[i][0];
                if (response) {
                    response.objects.forEach((obj) => {
                        if (!this._users.has(obj.userID)) {
                            this._users.set(obj.userID, User.createFromObject(obj));
                        }
                    });
                }
            }
        }
        return true;
    }
    async loadIdentities(identityIDs) {
        // load in batch of 100 the identity IDs passed to the function
        const promises = [];
        const keys = [];
        identityIDs.forEach((identityID) => {
            if (!this._identities.has(identityID)) {
                keys.push(identityID);
                if (keys.length == 100) {
                    const getIdentitiesRequest = {
                        objectType: 'identity',
                        keys: [...keys],
                    };
                    promises.push(getObjects([getIdentitiesRequest]));
                    keys.length = 0;
                }
            }
        });
        if (keys.length > 0) {
            const getIdentitiesRequest = {
                objectType: 'identity',
                keys: [...keys],
            };
            promises.push(getObjects([getIdentitiesRequest]));
            keys.length = 0;
        }
        if (promises.length > 0) {
            const responses = await Promise.all(promises);
            for (let i = 0; i < responses.length; i++) {
                const response = responses[i][0];
                if (response) {
                    response.objects.forEach((obj) => {
                        if (!this._identities.has(obj.identityID)) {
                            this._identities.set(obj.identityID, Identity.createFromObject(obj));
                        }
                    });
                }
            }
        }
        return true;
    }
    // public async loadAllLazyObjects(): Promise<boolean> {
    //     const promises: Array<Promise<any>> = [];
    //     let getUsers = false;
    //     const getUsersRequest: GetRequest = {
    //         objectType: 'user',
    //         keys: [],
    //     };
    //     this._userGroups.forEach((userGroup) => {
    //         userGroup.users.forEach((userID) => {
    //             if (
    //                 !getUsersRequest.keys.includes(userID) &&
    //                 !this._users.has(userID)
    //             ) {
    //                 getUsersRequest.keys.push(userID);
    //                 getUsers = true;
    //             }
    //         });
    //     });
    //     let getAirCurtains = false;
    //     const getAirCurtainsRequest: GetRequest = {
    //         objectType: 'airCurtain',
    //         keys: [],
    //     };
    //     this._airCurtainGroups.forEach((airCurtainGroup) => {
    //         airCurtainGroup.airCurtains.forEach((airCurtainID) => {
    //             if (
    //                 !getAirCurtainsRequest.keys.includes(airCurtainID) &&
    //                 !this._airCurtains.has(airCurtainID)
    //             ) {
    //                 const getShadowRequest: GetShadowRequest = { airCurtainID };
    //                 promises.push(getShadow(getShadowRequest));
    //                 getAirCurtainsRequest.keys.push(airCurtainID);
    //                 getAirCurtains = true;
    //             }
    //         });
    //     });
    //     let getInvitations = false;
    //     const getInvitationsRequest: GetRequest = {
    //         objectType: 'invitation',
    //         keys: [],
    //     };
    //     this._organization.invitations.forEach((invitationID) => {
    //         if (!getInvitationsRequest.keys.includes(invitationID)) {
    //             getInvitationsRequest.keys.push(invitationID);
    //             getInvitations = true;
    //         }
    //     });
    //     const requests: Array<GetRequest> = [];
    //     if (getUsers) requests.push(getUsersRequest);
    //     if (getAirCurtains) requests.push(getAirCurtainsRequest);
    //     if (getInvitations) requests.push(getInvitationsRequest);
    //     if (requests.length == 0) return true;
    //     promises.push(getObjects(requests));
    //     const responses = await Promise.all(promises);
    //     const shadows: Map<string, AirCurtainShadow2> = new Map<
    //         string,
    //         AirCurtainShadow2
    //     >();
    //     if (getAirCurtains) {
    //         for (let i = 0; i < responses.length - 1; i++) {
    //             shadows.set(responses[i].airCurtainID, responses[i]);
    //         }
    //     }
    //     const getObjectsResponses = responses[responses.length - 1]; // last element
    //     getObjectsResponses.forEach((response: any) => {
    //         switch (response.objectType) {
    //             case 'user':
    //                 response.objects.forEach((obj: omUser) => {
    //                     this._users.set(obj.userID, User.createFromObject(obj));
    //                 });
    //                 break;
    //             case 'airCurtain':
    //                 response.objects.forEach((obj: omAirCurtain) => {
    //                     if (!this._airCurtains.has(obj.airCurtainID)) {
    //                         this._airCurtains.set(
    //                             obj.airCurtainID,
    //                             AirCurtain.createFromObject(
    //                                 obj,
    //                                 shadows.get(obj.airCurtainID),
    //                                 undefined
    //                             )
    //                         ); // installation can't exist because the air curtain is only in mobile app
    //                     }
    //                 });
    //                 break;
    //             case 'invitation':
    //                 response.objects.forEach((obj: omInvitation) => {
    //                     this._invitations.set(
    //                         obj.invitationID,
    //                         Invitation.createFromObject(obj)
    //                     );
    //                 });
    //                 break;
    //         }
    //     });
    //     return true;
    // }
    async getCognitoUser(email) {
        const response = await getCognitoUserAPI({ email });
        if (response.result) {
            return response.user;
        }
        console.log(response.error);
        return undefined;
    }
    async invite(user, email) {
        console.log('inviting ' + email + ' to join organization');
        if (user.invitationID != undefined) {
            return {
                result: false,
                error: 'user already has an active invitation',
                invitationID: user.invitationID,
            };
        }
        const cognitoUser = await this.getCognitoUser(email);
        if (cognitoUser != undefined) {
            return {
                result: false,
                error: "cognito user arleady using this email exists, \
                load identity associated to it. if it doesn't exists you may create it and link it with user \
                else check if account is the same as current one, \
                if so you may link the identity with the user, else, \
                reject the invitation as the cognito user is in another account",
                cognitoUser: cognitoUser,
            };
        }
        // create invitation
        const invitation = new Invitation(email);
        invitation.userID = user.userID;
        invitation.accountID = this._account.accountID;
        invitation.organizationID = this._organization.organizationId;
        invitation.fromUserID = this._user.userID;
        invitation.invitationDate = new Date();
        // link to user and organization
        user.invitationID = invitation.invitationID;
        this._organization.addInvitation(invitation);
        //save invitation, user and organization
        const promises = [];
        promises.push(invitation.save());
        promises.push(this._organization.save());
        promises.push(user.save());
        const results = await Promise.all(promises);
        if (!results[0])
            return { result: false, error: "couldn't save invitation" };
        if (!results[1])
            return { result: false, error: "couldn't save organization" };
        if (!results[2])
            return { result: false, error: "couldn't save user" };
        // send email
        const result = await invitation.sendInvitationEmail();
        if (result) {
            return {
                result: true,
                invitationID: invitation.invitationID,
            };
        }
        return { result: false, error: "couldn't send email" };
    }
    getUserAirCurtainsFromMobileAPI() {
        return new Promise((resolve) => {
            getListAirCurtainUserRelation().then((airCurtainShadows) => {
                const airCurtains = [];
                if (airCurtainShadows) {
                    for (const airCurtainID in airCurtainShadows) {
                        const airCurtainShdow = airCurtainShadows[airCurtainID];
                        const airCurtain = new AirCurtain(airCurtainID);
                        airCurtain.model = airCurtainShdow.model;
                        airCurtain.name = airCurtainShdow.name;
                        airCurtain.heaterPower = airCurtainShdow.heaterPower;
                        airCurtains.push(airCurtain);
                    }
                }
                resolve(airCurtains);
            });
        });
    }
}
