import { getObjects, createObjects, updateObjects, deleteObjects, } from './../api/webApplicationApi';
import { v4 as uuidv4 } from 'uuid';
const objectType = 'airCurtainGroup';
export class AirCurtainGroup {
    constructor(airCurtainGroupID) {
        this._saveMethod = createObjects;
        this._object = {
            airCurtainGroupID: airCurtainGroupID,
            accountID: undefined,
            name: undefined,
            parentObjectType: undefined,
            parentObjectID: undefined,
            airCurtains: [],
        };
    }
    static createNew() {
        return new AirCurtainGroup(uuidv4());
    }
    static createFromObject(airCurtainGroup) {
        const obj = new AirCurtainGroup(airCurtainGroup.airCurtainGroupID);
        obj._object = airCurtainGroup;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.airCurtainGroupID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('air curtain group not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.name == undefined) {
            this._lastError = new Error('Name is undefined');
            return false;
        }
        if (this._object.accountID == undefined) {
            this._lastError = new Error('Account ID is undefined');
            return false;
        }
        if (this._object.parentObjectType == undefined) {
            this._lastError = new Error('Parent Group Type is undefined');
            return false;
        }
        if (this._object.parentObjectID == undefined) {
            this._lastError = new Error('Parent Group ID is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknown server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get airCurtainGroupID() {
        return this._object.airCurtainGroupID;
    }
    get accountID() {
        return this._object.accountID;
    }
    set accountID(accountID) {
        this._object.accountID = accountID;
    }
    get name() {
        return this._object.name;
    }
    set name(name) {
        this._object.name = name;
    }
    get parentObjectType() {
        return this._object.parentObjectType;
    }
    set parentObjectType(parentObjectType) {
        this._object.parentObjectType = parentObjectType;
    }
    get parentObjectID() {
        return this._object.parentObjectID;
    }
    set parentObjectID(parentObjectID) {
        this._object.parentObjectID = parentObjectID;
    }
    get airCurtains() {
        return this._object.airCurtains;
    }
    addAirCurtain(airCurtain) {
        if (this._object.airCurtains.includes(airCurtain.airCurtainID))
            return;
        this._object.airCurtains.push(airCurtain.airCurtainID);
    }
    deleteAirCurtain(airCurtain) {
        const index = this._object.airCurtains.indexOf(airCurtain.airCurtainID);
        if (index > -1) {
            this._object.airCurtains.splice(index, 1);
        }
    }
}
