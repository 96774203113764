import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map';
import { repeat } from 'lit/directives/repeat.js';
import { store } from '../redux/store';
import { EVENTS, loadStyle, orderAirCurtains, getTimeElapsed } from '../api/ui';
let BernerCurtains = class BernerCurtains extends LitElement {
    constructor() {
        super();
        // if set only show aircurtains for the site in question
        this.selectedSite = '';
        this._sites = [];
        this._airCurtainsBySite = new Map();
        this._allAirCurtains = [];
        this._lessThan480px = false;
        this._initialized = false;
        this._selectedAirCurtain = '';
        this.init = async () => {
            var _a;
            const { objectManager } = store.getState();
            const siteGroup = objectManager.getSiteGroup();
            for (const siteId of siteGroup.sites) {
                const site = objectManager.getSite(siteId);
                this._sites.push(site);
                const siteCurtainsMap = new Map();
                for (const curtainGroupId of site.airCurtainGroups) {
                    const airCurtainGroup = objectManager.getAirCurtainGroup(curtainGroupId);
                    const groupAirCurtains = await objectManager.getAllAirCurtainsFromAirCurtainGroup(airCurtainGroup);
                    let siteCurtains = (_a = siteCurtainsMap.get(siteId)) !== null && _a !== void 0 ? _a : [];
                    siteCurtains = siteCurtains.concat(groupAirCurtains);
                    siteCurtainsMap.set(siteId, siteCurtains);
                }
                const orderedSiteCurtains = orderAirCurtains(siteCurtainsMap);
                const siteCurtains = orderedSiteCurtains === null || orderedSiteCurtains === void 0 ? void 0 : orderedSiteCurtains.map((obj) => { return obj.curtain; });
                this._airCurtainsBySite.set(site.siteID, siteCurtains);
            }
            this._allAirCurtains = orderAirCurtains(this._airCurtainsBySite);
            this._initialized = true;
        };
        this.firstUpdated = async () => {
            await loadStyle('./css/berner-curtains.css', this.shadowRoot);
            if (window.matchMedia('(max-width: 479.98px)').matches) {
                this._lessThan480px = true;
            }
            else {
                this._lessThan480px = false;
            }
            this.requestUpdate();
        };
        this.onResize = async () => {
            const wasLessThan480px = this._lessThan480px;
            if (window.matchMedia('(max-width: 479.98px)').matches) {
                this._lessThan480px = true;
            }
            else {
                this._lessThan480px = false;
            }
            if (wasLessThan480px !== this._lessThan480px) {
                this.requestUpdate();
            }
        };
        this.onClickCurtain = (airCurtainId, siteId) => {
            if (airCurtainId === this.selectedAirCurtain) {
                this.selectedAirCurtain = '';
            }
            else {
                this.selectedAirCurtain = airCurtainId;
            }
            const event = new CustomEvent(EVENTS.CURTAIN_SELECTED, {
                detail: { curtain: this.selectedAirCurtain, site: siteId },
            });
            window.dispatchEvent(event);
        };
        this.init();
    }
    get selectedAirCurtain() {
        return this._selectedAirCurtain.toString();
    }
    set selectedAirCurtain(value) {
        this._selectedAirCurtain = value;
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('resize', this.onResize);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('resize', this.onResize);
    }
    renderNoAirCurtains() {
        return html `
            <div class="col-12">
                <p class="mx-auto pt-3 my-auto label">
                    This site does not have any air curtains yet.
                </p>
            </div>
        `;
    }
    renderAirCurtain(curtain, siteId = '') {
        const connectivityPrescript = (curtain === null || curtain === void 0 ? void 0 : curtain.connected) ? 'Connected: ' : 'Online: ';
        const connectivityMessage = (curtain === null || curtain === void 0 ? void 0 : curtain.connectivityTimestamp) ? connectivityPrescript + getTimeElapsed(curtain === null || curtain === void 0 ? void 0 : curtain.connectivityTimestamp) : '';
        return html `
        <div class=${classMap({ "row": true, "py-3": true, "air-curtain": true, "selected": this.selectedAirCurtain === curtain.airCurtainID })} @click=${(e) => {
            this.onClickCurtain(curtain.airCurtainID, siteId);
            e.stopPropagation();
        }}>
            <span class='col-1'>
                <span class=${classMap({ 'dot': curtain === null || curtain === void 0 ? void 0 : curtain.connected, 'dot--red': !(curtain === null || curtain === void 0 ? void 0 : curtain.connected) })}></span>
            </span>
            ${curtain.connectivityTimestamp && !this._lessThan480px ?
            html `
                <span class="col-5 curtain-name"> ${curtain === null || curtain === void 0 ? void 0 : curtain.name}</span>
                <span class="col-5 curtain-last-online">${connectivityMessage}</span>`
            : html `<span class=${classMap({ "curtain-name": true, "col-11": !this._lessThan480px, "col-10": this._lessThan480px })}> ${curtain === null || curtain === void 0 ? void 0 : curtain.name}</span>`}
        </div>`;
    }
    renderSiteCurtains(siteId) {
        const siteAirCurtains = this._airCurtainsBySite.get(siteId);
        if (!siteAirCurtains.length) {
            return html `${this.renderNoAirCurtains()}`;
        }
        return html `
        ${repeat(siteAirCurtains, (curtain) => {
            return html `${this.renderAirCurtain(curtain, siteId)}`;
        })}`;
    }
    renderAllAirCurtains() {
        if (!this._allAirCurtains.length)
            return html ``;
        return html `
            <ul class="curtains-list p-0 content">
                ${repeat(this._allAirCurtains, (obj) => {
            return html ` ${this.renderAirCurtain(obj.curtain, obj.site)} `;
        })}
            </ul>
        `;
    }
    renderAirCurtains() {
        if (!this._airCurtainsBySite.size)
            return html ``;
        if (this.selectedSite) {
            return html `
                <ul class="curtains-list p-0 content">
                    ${this.renderSiteCurtains(this.selectedSite)}
                </ul>`;
        }
        else {
            return html `${this.renderAllAirCurtains()}`;
        }
    }
    render() {
        return html `
        <link id="css-target">
        <div class="css-loading" id="root">
            <div class="col-lg-12 p-0">
                ${this._initialized ? this.renderAirCurtains() : html ``}
            </div>
        </div>`;
    }
};
__decorate([
    property({ attribute: 'selected-site' })
], BernerCurtains.prototype, "selectedSite", void 0);
__decorate([
    state()
], BernerCurtains.prototype, "_initialized", void 0);
__decorate([
    property({ attribute: 'selected-air-curtain' })
], BernerCurtains.prototype, "_selectedAirCurtain", void 0);
__decorate([
    state()
], BernerCurtains.prototype, "selectedAirCurtain", null);
BernerCurtains = __decorate([
    customElement('berner-curtains')
], BernerCurtains);
export { BernerCurtains };
