import { Auth, API } from 'aws-amplify';
import { key as X_API_KEY } from './x-api-key-reporting.json';
const API_NAME = 'ReportingAPI';
const APP_VERSION = '2.0';
const PROTOCOL_VERSION = '0.1';
export const RANGES = {
    ONE_DAY: 'one_day',
    TWO_DAYS: 'two_days',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
};
export const INTERVALS = {
    HOURLY: 'hourly',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
};
const rangeToIntervalDefault = new Map([
    [RANGES.ONE_DAY, INTERVALS.HOURLY],
    [RANGES.TWO_DAYS, INTERVALS.HOURLY],
    [RANGES.WEEKLY, INTERVALS.HOURLY],
    [RANGES.MONTHLY, INTERVALS.HOURLY],
    [RANGES.YEARLY, INTERVALS.DAILY],
]);
const rangeToIntervalBarChart = new Map([
    [RANGES.ONE_DAY, INTERVALS.HOURLY],
    [RANGES.TWO_DAYS, INTERVALS.DAILY],
    [RANGES.WEEKLY, INTERVALS.DAILY],
    [RANGES.MONTHLY, INTERVALS.WEEKLY],
    [RANGES.YEARLY, INTERVALS.MONTHLY],
]);
const rangeToIntervalHeatMap = new Map([
    [RANGES.ONE_DAY, INTERVALS.HOURLY],
    [RANGES.TWO_DAYS, INTERVALS.HOURLY],
    [RANGES.WEEKLY, INTERVALS.HOURLY],
    [RANGES.MONTHLY, INTERVALS.HOURLY],
    [RANGES.YEARLY, INTERVALS.MONTHLY],
]);
function getSundayOfWeek(date) {
    const first = date.getDate() - date.getDay() + 1;
    const last = first + 6;
    const sunday = new Date(date.setDate(last));
    return sunday;
}
export const RATIOS = {
    TEN_BY_NINE: 'container-10-9',
    FIFTEEN_BY_NINE: 'container-15-9',
    SIXTEEN_BY_NINE: 'container-16-9',
    SIXTEEN_BY_NINE_NO_BORDER: 'container-16-9-no-border',
    TWENTYTHREE_BY_TEN: 'container-23-10',
    THIRTYTWO_BY_NINE: 'container-32-9',
    THREE_BY_TEN: 'container-3-10',
    SQUARE: 'container-square'
};
export const ORIENTATIONS = {
    VERTICAL: 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL',
};
export function getIntervalFromRange(range, graphType) {
    if (graphType === GRAPH_TYPES.BAR || graphType === GRAPH_TYPES.COLUMN) {
        return rangeToIntervalBarChart.get(range);
    }
    else if (graphType === GRAPH_TYPES.HEAT_MAP) {
        return rangeToIntervalHeatMap.get(range);
    }
    else {
        return rangeToIntervalDefault.get(range);
    }
}
export function getDatesInRange(range) {
    const dates = [];
    let date = dateToUTCDate(new Date());
    date.setMinutes(0);
    date.setSeconds(0);
    if (range === RANGES.ONE_DAY) {
        const count = 24;
        for (let i = 0; i < count; i++) {
            date = getDateMinusRange("one_hour", new Date(date.getTime()));
            dates.push(date);
        }
    }
    else if (range === RANGES.TWO_DAYS || range === RANGES.WEEKLY) {
        const count = range === RANGES.WEEKLY ? 7 : 2;
        for (let i = 0; i < count; i++) {
            date = getDateMinusRange(RANGES.ONE_DAY, new Date(date.getTime()));
            dates.push(date);
        }
    }
    else if (range === RANGES.MONTHLY) {
        const count = 4;
        // Weekly data is each Sunday
        date = getSundayOfWeek(date);
        for (let i = 0; i < count; i++) {
            date = getDateMinusRange(RANGES.WEEKLY, new Date(date.getTime()));
            dates.push(date);
        }
    }
    else if (range === RANGES.YEARLY) {
        const count = 12;
        for (let i = 0; i < count; i++) {
            date = getDateMinusRange(RANGES.MONTHLY, new Date(date.getTime()));
            dates.push(date);
        }
    }
    return dates;
}
export function dateToUTCDate(date) {
    const date_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(date_utc);
}
export function getDateMinusRange(range, date) {
    const oldDate = date;
    if (range === RANGES.YEARLY) {
        oldDate.setFullYear(date.getFullYear() - 1);
        oldDate.setHours(0);
    }
    else if (range === RANGES.MONTHLY) {
        oldDate.setMonth(date.getMonth() - 1);
        oldDate.setHours(0);
    }
    else if (range === RANGES.WEEKLY) {
        oldDate.setDate(date.getDate() - 7);
        oldDate.setHours(0);
    }
    else if (range === RANGES.TWO_DAYS) {
        oldDate.setDate(date.getDate() - 2);
        oldDate.setHours(0);
    }
    else if (range === RANGES.ONE_DAY) {
        oldDate.setDate(date.getDate() - 1);
        oldDate.setHours(0);
    }
    else if (range === 'one_hour') {
        const time = date.getTime();
        const minusOneHour = time - (3600 * 1000);
        oldDate.setTime(minusOneHour);
    }
    return oldDate;
}
export function getDateStringsFromRange(range, endDate) {
    const startDate = getStartDateFromRange(range, endDate);
    return {
        startDate: (startDate.getTime() / 1000).toFixed().toString(),
        endDate: (endDate.getTime() / 1000).toFixed().toString(),
    };
}
export function getStartDateFromRange(range, endDate) {
    const startDate = getDateMinusRange(range, dateToUTCDate(endDate));
    return startDate;
}
export const MEASUREMENTS = {
    AVERAGE_TEMP: 'avgTemperature',
    MAX_TEMP: 'maxTemperature',
    MIN_TEMP: 'minTemperature',
    WEATHER_AVG_TEMP: 'weatherAvgTemperature',
    DOOR_OPEN_COUNT: 'doorOpenCount',
    BTU: 'btu',
    TON_GAIN_RATE: 'tonGainRate',
    LOSS: 'loss',
    GROSS_SAVING: 'grossSaving',
    COST_TO_OPERATE: 'costToOperate',
    SAVING: 'savings',
    DOOR_OPEN_TIME: 'doorOpenTime',
    EFFICIENCY: 'efficiency',
};
export const GRAPH_TYPES = {
    AREA: 'area',
    AREASPLINE: 'areaspline',
    BAR: 'bar',
    COLUMN: 'column',
    HEAT_MAP: 'heatmap',
};
export const getAirCurtainData = async (thingNames, siteIDThingNamePairs, intervals, measurements, startTimestamp, endTimestamp) => {
    return await post('getdata', {
        SiteIDThingNamePairs: siteIDThingNamePairs,
        ThingNames: thingNames,
        Intervals: intervals,
        Measurements: measurements,
        StartTimestamp: startTimestamp,
        EndTimestamp: endTimestamp,
    });
};
export const getSiteWeatherData = async (siteIds, intervals, measurements, startTimestamp, endTimestamp) => {
    return await post('getdata', {
        SiteIDs: siteIds,
        Intervals: intervals,
        Measurements: measurements,
        StartTimestamp: startTimestamp,
        EndTimestamp: endTimestamp,
    });
};
const post = async (endpoint, body) => {
    const session = await Auth.currentSession();
    const mergedBody = Object.assign({}, {
        AppVersion: APP_VERSION,
        ProtocolVersion: PROTOCOL_VERSION,
    }, body);
    const init = {
        headers: {
            Authorization: session.getIdToken().getJwtToken(),
            'x-api-key': X_API_KEY,
        },
        response: true,
        body: mergedBody,
    };
    const response = await API.post(API_NAME, '/prod/' + endpoint, init).catch((err) => {
        console.log('post exception: ', err);
    });
    // console.log('post response: ', response);
    if (response && response.data.statusCode == 200) {
        return response.data.body;
    }
    else {
        return '';
    }
};
