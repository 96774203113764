import { getObjects, createObjects, updateObjects, deleteObjects, } from './../api/webApplicationApi';
import { v4 as uuidv4 } from 'uuid';
const objectType = 'bin';
export class Bin {
    constructor(binID) {
        this._saveMethod = createObjects;
        this._object = {
            binID: binID,
            accountID: undefined,
            trashes: [],
        };
    }
    static createNew() {
        return new Bin(uuidv4());
    }
    static createFromObject(bin) {
        const obj = new Bin(bin.binID);
        obj._object = bin;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.binID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('bin not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.accountID == undefined) {
            this._lastError = new Error('Account ID is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknow server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get binID() {
        return this._object.binID;
    }
    get accountID() {
        return this._object.accountID;
    }
    set accountID(accountID) {
        this._object.accountID = accountID;
    }
    get trashes() {
        return this._object.trashes;
    }
    addTrash(trash) {
        if (this._object.trashes.findIndex((item) => {
            return item.objectID == trash.objectID;
        }) >= 0)
            return;
        this._object.trashes.push(trash);
    }
    deleteTrash(trash) {
        const index = this._object.trashes.findIndex((item) => {
            return item.objectID == trash.objectID;
        });
        if (index > -1) {
            this._object.trashes.splice(index, 1);
        }
    }
}
