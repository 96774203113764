import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { classMap } from 'lit/directives/class-map';
import { EVENTS, loadStyle } from '../api/ui';
import { store } from '../redux/store';
let BernerSites = class BernerSites extends LitElement {
    constructor() {
        super();
        this._sites = [];
        this._airCurtainsBySite = new Map();
        this._selectedSite = '';
        this._initialized = false;
        this.init = async () => {
            const { objectManager } = store.getState();
            this._objectManager = objectManager;
            const siteGroup = this._objectManager.getSiteGroup();
            this.selectedSite = '';
            for (const siteId of siteGroup.sites) {
                const site = this._objectManager.getSite(siteId);
                this._sites.push(site);
            }
            this.loadSiteResources();
            this._initialized = true;
        };
        this.loadSiteResources = async () => {
            for (const site of this._sites) {
                let siteCurtains = [];
                for (const curtainGroupId of site.airCurtainGroups) {
                    const airCurtainGroup = this._objectManager.getAirCurtainGroup(curtainGroupId);
                    const groupAirCurtains = await this._objectManager.getAllAirCurtainsFromAirCurtainGroup(airCurtainGroup);
                    siteCurtains = siteCurtains.concat(groupAirCurtains);
                }
                this._airCurtainsBySite.set(site.siteID, siteCurtains);
            }
            this.requestUpdate();
        };
        this.firstUpdated = async () => {
            await loadStyle('./css/berner-sites.css', this.shadowRoot);
        };
        this.init();
    }
    get selectedSite() {
        return this._selectedSite.toString();
    }
    set selectedSite(value) {
        this._selectedSite = value;
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    updated() {
        var _a;
        (_a = this.shadowRoot.getElementById(this._selectedSite)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
    renderNoSites() {
        return html `
            <div class="col-12">
                <div class="pt-5">
                    <p class="mx-auto mb-2 label">There are no sites yet.</p>
                </div>
            </div>
        `;
    }
    renderSites() {
        if (!this._sites.length) {
            return html `${this.renderNoSites()}`;
        }
        const onClickSite = (siteId, e) => {
            if (siteId === this.selectedSite) {
                this.selectedSite = '';
            }
            else {
                this.selectedSite = siteId;
            }
            const event = new CustomEvent(EVENTS.SITE_SELECTED, {
                detail: { site: this._selectedSite },
            });
            e.stopPropagation();
            window.dispatchEvent(event);
        };
        return html `
            <ul class="sites-list px-0 pb-2 content">
                ${repeat(this._sites, (site) => {
            var _a;
            const siteName = site.name;
            const numCurtains = (_a = this._airCurtainsBySite.get(site.siteID)) === null || _a === void 0 ? void 0 : _a.length;
            return html `
                        <div class="col-12 p-0 m-0">
                            <section
                                class="${classMap({
                row: true,
                selected: this.selectedSite === site.siteID,
                'py-2': true,
                'mx-0': true,
            })}"
                                id=${site.siteID}
                                @click=${(e) => onClickSite(site.siteID, e)}>
                                <div class="site-name col-6">
                                    <p>${siteName}</p>
                                </div>
                                <div class="col-6 row pr-0 air-curtains-container">
                                    <span class="float-right mr-1">
                                        <img
                                            src="./assets/reports_icon_air_curtains_grey.svg"
                                            alt="air curtains"
                                        />
                                    </span>

                                    <span class="float-right">
                                        ${numCurtains}
                                    </span>
                                </div>
                            </section>
                        </div>
                    `;
        })}
            </ul>
        `;
    }
    render() {
        return html `
        <link id="css-target">
        <div class="css-loading ml-n1" id="root">
            <div class="col-lg-12 px-2 mx-auto">
                ${this._initialized ? this.renderSites() : html ``}
            </div>
        </div>`;
    }
};
__decorate([
    property({ attribute: 'selected-site' })
], BernerSites.prototype, "_selectedSite", void 0);
__decorate([
    state()
], BernerSites.prototype, "selectedSite", null);
__decorate([
    state()
], BernerSites.prototype, "_initialized", void 0);
BernerSites = __decorate([
    customElement('berner-sites')
], BernerSites);
export { BernerSites };
