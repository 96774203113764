import { __decorate } from "tslib";
import { LitElement, css, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map';
import { repeat } from 'lit/directives/repeat.js';
import { store } from '../redux/store';
import { loadStyle, getTimeElapsed, dropDownClickHandler, EVENTS } from '../api/ui';
import { getConvertedTemperature } from '../api/graphDataApi';
let BernerCurtain = class BernerCurtain extends LitElement {
    constructor() {
        super(...arguments);
        this._selected = false;
        this._doorSensor = false;
        this._mode = -1;
        this.onModeClicked = (e) => {
            this.shadowRoot
                .querySelector('.mdc-menu-surface')
                .classList.add('show-menu');
            e.stopPropagation();
        };
        this.onModeItemClicked = (e) => {
            this._mode = parseInt(e.currentTarget.id.split('-')[1]);
            this.shadowRoot
                .querySelector('.mdc-menu-surface')
                .classList.remove('show-menu');
            e.stopPropagation();
        };
        this.onDoorClicked = (e) => {
            this._doorSensor = !this._doorSensor;
            e.stopPropagation();
        };
    }
    get selected() {
        return this._selected.toString();
    }
    set selected(value) {
        this._selected = value === 'true' ? true : false;
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    connectedCallback() {
        window.addEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged.bind(this));
        super.connectedCallback();
    }
    disconnectedCallback() {
        window.removeEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged);
        super.disconnectedCallback();
    }
    onTempUnitChanged(e) {
        this.requestUpdate();
    }
    async firstUpdated() {
        const { objectManager } = store.getState();
        // assigned id is <airCurtainID>:<siteID>
        this._airCurtain = await objectManager.getAirCurtain(this.id.split(':')[0]);
        if (!this._airCurtain.isShadowLoaded) {
            await this._airCurtain.refreshShadow();
        }
        const rowContainer = this.shadowRoot.getElementById('row-container');
        rowContainer.addEventListener('mouseover', () => {
            rowContainer.classList.add('mouse-over');
        });
        rowContainer.addEventListener('mouseout', () => {
            rowContainer.classList.remove('mouse-over');
        });
        this._doorSensor = this._airCurtain.doorOpenCount > 0;
        this._mode = this._airCurtain.mode;
        await loadStyle('./css/berner-curtain.css', this.shadowRoot);
        this.requestUpdate();
    }
    getNextEvent() {
        var _a, _b, _c, _d;
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
        if (!((_b = (_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.schedule) === null || _b === void 0 ? void 0 : _b.enabled)) {
            return '';
        }
        else {
            if (((_c = this._airCurtain) === null || _c === void 0 ? void 0 : _c.schedule.events.length) == 0)
                return '';
            const event = (_d = this._airCurtain) === null || _d === void 0 ? void 0 : _d.schedule.events[0];
            const hours = event.dayMinute / 60;
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            const rminutes = Math.round(minutes);
            const paddedMins = rminutes < 10 ? '0' + rminutes : rminutes;
            const day = days[event.weekDay];
            return `${day} ${rhours}:${paddedMins}`;
        }
    }
    renderMode() {
        var _a;
        if (!((_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.mode))
            return html ``;
        // TODO: handle pureMode
        const modes = [
            { name: 'Off', icon: './assets/icon_mode_off.png' },
            { name: 'On', icon: './assets/icon_mode_on.png' },
            { name: 'Auto', icon: './assets/icon_mode_auto.png' },
            {
                name: 'Comfort Plus',
                icon: './assets/icon_mode_comfort_plus.png',
            },
        ];
        return html ` <div class="item mode">
            <h3>Mode</h3>

            <button
                class="mdc-button mdc-button--raised mdc-button--primary mdc-tab mdc-ripple-upgraded"
                role="tab"
            >
                <span class="button-contents">
                    <img src=${modes[this._mode].icon} alt="selected mode" />
                    <span>${modes[this._mode].name}</span>
                </span>
            </button>

            <div class="mdc-menu mdc-menu-surface">
                <ul
                    class="mdc-list"
                    role="menu"
                    aria-hidden="true"
                    aria-orientation="vertical"
                    tabindex="-1"
                >
                    ${repeat(modes, (mode, index) => html `
                        <li
                            id="mode-${index}"
                            class="mdc-list-item"
                            role="menuitem"
                            @click=${this.onModeItemClicked}
                        >
                            <span class="mdc-list-item__ripple"></span>
                            <span class="mdc-list-item__text">
                                <img
                                    src=${mode.icon}
                                    alt="mode ${mode.name}"
                                />
                                <span>${mode.name}</span>
                            </span>
                        </li>
                        `)}
                </ul>
            </div>
        </div>`;
    }
    renderDropDownButton() {
        const ItemClickHandler = async (option, e) => {
            if (option === 'curtain-remove') {
                const event = new CustomEvent(EVENTS.SHOW_REMOVE_CURTAIN, { detail: { id: this.id } });
                window.dispatchEvent(event);
            }
            else if (option === 'curtain-update') {
                const event = new CustomEvent(EVENTS.SHOW_UPDATE_CURTAIN, { detail: { id: this.id } });
                window.dispatchEvent(event);
            }
            else if (option === 'curtain-info') {
                const event = new CustomEvent(EVENTS.SHOW_CURTAIN_INFO, { detail: { id: this.id } });
                window.dispatchEvent(event);
            }
            e.stopPropagation();
        };
        const options = html `
        <span
            class="dropdown-item"
            id="curtain-info"
            @click=${(e) => { ItemClickHandler('curtain-info', e); }}
        >
            <img src="./assets/icon_info.svg" alt="air curtain info"/>
            <span class="">Air curtain info</span>
        </span>
        <span
            class="dropdown-item"
            id="curtain-update"
            @click=${(e) => { ItemClickHandler('curtain-update', e); }}
        >
            <img src="./assets/icon_gear.svg" alt="update installation"/>
            <span>Update installation</span>
        </span>
        <span
            class="dropdown-item"
            id="curtain-remove"
            @click=${(e) => { ItemClickHandler('curtain-remove', e); }}
        >
            <img src="./assets/icon_trash.svg" alt="remove from site"/>
            <span>Remove from site</span>
        </span>
        `;
        return html `
        <div class="btn-group dropleft" .hidden=${!this._showMenu}>
            <button
                class="kebab"
                type="button"
                class="btn btn-light py-0"
                data-toggle="dropdown"
                aria-expanded="false"
                @click=${(e) => dropDownClickHandler(e, this.shadowRoot)}
            >
                &#x22EE;
            </button>
            <div class="dropdown-menu dropdown-menu-right">${options}</div>
        </div>
        `;
    }
    renderModel() {
        var _a;
        return html `
        <div class="col-12 col-md-3 col-lg-2 item label--model">
            <span>${(_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.model}</span>
        </div>`;
    }
    renderElapsedTime() {
        var _a, _b, _c;
        const connectivityPrescript = ((_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.connected) ? 'Connected: ' : 'Online: ';
        const message = ((_b = this._airCurtain) === null || _b === void 0 ? void 0 : _b.connectivityTimestamp) ? connectivityPrescript + getTimeElapsed((_c = this._airCurtain) === null || _c === void 0 ? void 0 : _c.connectivityTimestamp) : '';
        return html `
        <div class="col-12 col-md-4 col-lg-2">
            <div class="item label small elapsed-time">
                <span>${message}</span>
            </div>
        </div>`;
    }
    renderCalender() {
        return html `
        <div class="col-3 col-lg-1 item--calender">
            <img src="./assets/icon_calendar.svg" alt="calender" />
            <span class="label--calender">${this.getNextEvent()}</span>
        </div>`;
    }
    renderTempSetPoint() {
        var _a, _b;
        return html `
        <div class="col-md-1 col-sm-2 item">
            <img
                src="./assets/icon_unit_heating_set_to.svg"
                alt="heating"
            />

            <span class="label">
                ${((_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.temperatureSetPoint) !== undefined
            ? getConvertedTemperature((_b = this._airCurtain) === null || _b === void 0 ? void 0 : _b.temperatureSetPoint, 0) + `\u00B0`
            : ''}
            </span>
        </div>`;
    }
    renderDoorOpenCount() {
        var _a, _b;
        return html `
        <div class="col-md-1 col-sm-2 item">
            <img src="./assets/icon_unit_times_door_open_active.svg" alt="doors">

            <span class="label">
                ${((_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.doorOpenCount)
            ? (_b = this._airCurtain) === null || _b === void 0 ? void 0 : _b.doorOpenCount
            : ''}
            </span>
        </div>`;
    }
    renderFanSpeed() {
        var _a, _b;
        return html `
        <div class="col-md-1 col-sm-2 item">

            <img
                src="./assets/icon_unit_current_fan_speed_active.svg"
                alt="fan"
            />


            <span class="label">
                ${((_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.fanSpeed)
            ? (_b = this._airCurtain) === null || _b === void 0 ? void 0 : _b.fanSpeed
            : ''}
            </span>
        </div>`;
    }
    renderCurrentTemperature() {
        var _a, _b;
        return html `
        <div class="col-md-1 col-sm-2 item">
            <img
                src="./assets/icon_unit_current_temperature.svg"
                alt="temp"
            />
            <span class="label">
                ${((_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.sensorTemperature) !== undefined
            ? getConvertedTemperature((_b = this._airCurtain) === null || _b === void 0 ? void 0 : _b.sensorTemperature, 0) + `\u00B0`
            : ''}
            </span>
        </div>`;
    }
    render() {
        var _a, _b, _c;
        return html `
        <link id="css-target">
        <div class="css-loading" id="root" 
            @mouseover="${() => this._showMenu = true}"
            @mouseleave="${() => this._showMenu = false}">
            <div
                id="row-container"
                class="${classMap({
            'row-container': true,
            selected: this.selected === 'true',
        })}"
            >
                <section
                    class=${classMap({
            row: true,
            'no-gutters': true,
            'content-row': true,
            'col-12': true,
        })}
                >
                    <div class="col-12 col-md-7 col-lg-2 item">
                        <span class=${classMap({ 'dot': (_a = this._airCurtain) === null || _a === void 0 ? void 0 : _a.connected, 'dot--red': !((_b = this._airCurtain) === null || _b === void 0 ? void 0 : _b.connected) })}></span>
                        <span class="name"> ${(_c = this._airCurtain) === null || _c === void 0 ? void 0 : _c.name}</span>
                    </div>
                    ${html `
                        ${this.renderElapsedTime()}
                        ${this.renderCurrentTemperature()}
                        ${this.renderDoorOpenCount()}
                        ${this.renderFanSpeed()}
                        ${this.renderTempSetPoint()}
                        ${this.renderCalender()}
                        ${this.renderModel()}
                        ${this.renderDropDownButton()}`}
                </section>
            </div>
        </div>`;
    }
};
__decorate([
    property({ type: String })
], BernerCurtain.prototype, "id", void 0);
__decorate([
    property()
], BernerCurtain.prototype, "selected", null);
__decorate([
    state()
], BernerCurtain.prototype, "_showMenu", void 0);
__decorate([
    state()
], BernerCurtain.prototype, "_airCurtain", void 0);
__decorate([
    state()
], BernerCurtain.prototype, "_doorSensor", void 0);
__decorate([
    state()
], BernerCurtain.prototype, "_mode", void 0);
BernerCurtain = __decorate([
    customElement('berner-curtain')
], BernerCurtain);
export { BernerCurtain };
