import { getObjects, createObjects, updateObjects, deleteObjects, } from './../api/webApplicationApi';
import { v4 as uuidv4 } from 'uuid';
const objectType = 'role';
export class Role {
    constructor(roleID) {
        this._saveMethod = createObjects;
        this._object = {
            roleID: roleID,
            name: undefined,
            policies: [],
        };
    }
    static createNew() {
        return new Role(uuidv4());
    }
    static createFromObject(role) {
        const obj = new Role(role.roleID);
        obj._object = role;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.roleID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('role not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.name == undefined) {
            this._lastError = new Error('Name is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknow server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get roleID() {
        return this._object.roleID;
    }
    get name() {
        return this._object.name;
    }
    set name(name) {
        this._object.name = name;
    }
    get policies() {
        return this._object.policies;
    }
    addPolicy(policy) {
        if (this._object.policies.includes(policy.policyID))
            return;
        this._object.policies.push(policy.policyID);
    }
    deletePolicy(policy) {
        const index = this._object.policies.indexOf(policy.policyID);
        if (index > -1) {
            this._object.policies.splice(index, 1);
        }
    }
}
