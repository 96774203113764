import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { store } from '../redux/store';
import { loadStyle, EVENTS } from '../api/ui';
import { getConvertedTemperature } from '../api/graphDataApi';
let BernerWeather = class BernerWeather extends LitElement {
    constructor() {
        super(...arguments);
        this._initialized = false;
        this._siteId = '';
        this.onTempUnitChanged = () => {
            this.getData();
            this.requestUpdate();
        };
        this.onSiteSelect = (left) => {
            var _a;
            let index = this.allSites.findIndex((site) => { return site === this.siteId; });
            if (left) {
                if (index === 0) {
                    index = this.allSites.length - 1;
                }
                else {
                    index -= 1;
                }
            }
            else {
                if (index === this.allSites.length - 1) {
                    index = 0;
                }
                else {
                    index += 1;
                }
            }
            this.siteId = this.allSites[index];
            this.siteName = (_a = this._objectManager.getSite(this.siteId)) === null || _a === void 0 ? void 0 : _a.name;
        };
        this.firstUpdated = async () => {
            var _a;
            await loadStyle('./css/berner-weather.css', this.shadowRoot);
            const { objectManager } = store.getState();
            this._objectManager = objectManager;
            this.allSites = this.sites.split(':');
            this.siteName = (_a = this._objectManager.getSite(this.siteId)) === null || _a === void 0 ? void 0 : _a.name;
            this.getData();
            this._initialized = true;
            this.requestUpdate();
        };
    }
    get siteId() {
        return this._siteId;
    }
    set siteId(value) {
        this._siteId = value;
        this.getData();
        this.requestUpdate();
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    connectedCallback() {
        window.addEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged);
        super.connectedCallback();
    }
    disconnectedCallback() {
        window.removeEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged);
        super.disconnectedCallback();
    }
    updated() {
        var _a, _b;
        this.siteName = (_b = (_a = this._objectManager) === null || _a === void 0 ? void 0 : _a.getSite(this.siteId)) === null || _b === void 0 ? void 0 : _b.name;
    }
    async getData() {
        if (!this._objectManager)
            return;
        const site = this._objectManager.getSite(this.siteId);
        this._weather = await (site === null || site === void 0 ? void 0 : site.getCurrentWeather());
        this._weather.currentTemperature = getConvertedTemperature(this._weather.currentTemperature);
        this._weather.maximumTemperature = getConvertedTemperature(this._weather.maximumTemperature);
        this._weather.maximumTemperature = getConvertedTemperature(this._weather.maximumTemperature);
        this._weather.feelsLikeTemperature = getConvertedTemperature(this._weather.feelsLikeTemperature);
    }
    renderContent() {
        var _a, _b, _c, _d, _e;
        const displayTemp = ((_a = this._weather) === null || _a === void 0 ? void 0 : _a.currentTemperature) ? `${(_c = (_b = this._weather) === null || _b === void 0 ? void 0 : _b.currentTemperature) === null || _c === void 0 ? void 0 : _c.toLocaleString("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 })}°` : '';
        const displayHumidity = ((_d = this._weather) === null || _d === void 0 ? void 0 : _d.relativeHumidity) ? `${(_e = this._weather) === null || _e === void 0 ? void 0 : _e.relativeHumidity}%` : '';
        return html `
            <div class="card">
                <section class="card-body">
                    <div class="row content-row header">
                        <div class="col text-left">
                            <div class="row header top-row">
                                <div class="icon top-row-icon">
                                    <img
                                        src="./assets/icon_weather.svg"
                                        alt="users"
                                    />
                                </div>
                                <span class="card-title text-white title top-row-title"
                                    >${'Weather' +
            (this.siteName
                ? ' for ' + this.siteName
                : '')}
                                </span>
                                <div class="site-cycle-button-container top-row-buttons">
                                    <button
                                        type="button"
                                        class="btn site-cycle-button"
                                        @click=${() => this.onSiteSelect(true)}>&#9664;
                                    </button>
                                    <button
                                        type="button"
                                        class="btn site-cycle-button rotate"
                                        @click=${() => this.onSiteSelect(false)}>&#9664;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <article class="row pt-5">
                        <div class="col-6 left-element">
                            <h5 class="mb-3">Outside</h5>
                            <p class="temperature ml-2">
                                ${displayTemp}
                            </p>
                        </div>
                        <div class="col-6">
                            <h5 class="mb-3">Humidity</h5>
                            <p class="humidity ml-2">
                                ${displayHumidity}
                            </p>
                        </div>
                    </article>
                </section>
            </div>
        `;
    }
    render() {
        return html `
        <link id="css-target">
        <div class="css-loading" id="root">
            <div class="col-lg-12 mx-auto no-gutters h-100 p-0">
                ${this._initialized ? html `${this.renderContent()}` : html ``}
            </div>
        </div>`;
    }
};
__decorate([
    state()
], BernerWeather.prototype, "_weather", void 0);
__decorate([
    property({ attribute: 'site-id' })
], BernerWeather.prototype, "siteId", null);
__decorate([
    property({ attribute: 'sites' })
], BernerWeather.prototype, "sites", void 0);
BernerWeather = __decorate([
    customElement('berner-weather')
], BernerWeather);
export { BernerWeather };
