import { getObjects, createObjects, updateObjects, deleteObjects, getCognitoUser, } from './../api/webApplicationApi';
const objectType = 'identity';
export class Identity {
    constructor(identityID) {
        this._saveMethod = createObjects;
        this._object = {
            identityID: identityID,
            email: undefined,
            firstLogin: undefined,
            lastLogin: undefined,
            users: [],
        };
    }
    static createFromObject(identity) {
        const obj = new Identity(identity.identityID);
        obj._object = identity;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.identityID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('identity not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.email == undefined) {
            this._lastError = new Error('Name is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknow server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async loadCognitoUser() {
        if (!this._cognitoUser) {
            const getCognitoUserRequest = {
                email: this._object.email,
            };
            this._cognitoUser = await getCognitoUser(getCognitoUserRequest);
        }
        return true;
    }
    get cognitoUser() {
        return this._cognitoUser;
    }
    get lastError() {
        return this._lastError;
    }
    get identityID() {
        return this._object.identityID;
    }
    get email() {
        return this._object.email;
    }
    set email(email) {
        this._object.email = email;
    }
    get firstLogin() {
        if (this._object.firstLogin)
            return new Date(this._object.firstLogin);
        return undefined;
    }
    get lastLogin() {
        if (this._object.lastLogin)
            return new Date(this._object.lastLogin);
        return undefined;
    }
    get users() {
        return this._object.users;
    }
    addUser(user) {
        if (this._object.users.includes(user.userID))
            return;
        this._object.users.push(user.userID);
    }
    deleteUser(user) {
        const index = this._object.users.indexOf(user.userID);
        if (index > -1) {
            this._object.users.splice(index, 1);
        }
    }
}
