import { __decorate } from "tslib";
import { html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import Highcharts from 'highcharts';
import { BernerChartBase } from './berner-chart-base';
import { GRAPH_TYPES, RANGES, getDateMinusRange, dateToUTCDate, getDatesInRange } from '../../api/reporting';
import { getMaxMin, getAverage, getDisplayValue, getTotal, getMeasurementUI, MEASUREMENTS_LACKING_24HR_DATA } from '../../api/graphDataApi';
import { MEASUREMENT_TO_FUNC } from '../../api/dataManagementApi';
import { loadStyle, isTimeStampLast24hrs, UNITS_SPACE_PREFIX, UNITS_PLURAL, UNITS_PRECEDING_VALUE } from '../../api/ui';
/* Note: most of the SVG styling for graphs is defined in graphDataApi getMeasurementUI() */
let BernerGraph = class BernerGraph extends BernerChartBase {
    constructor() {
        super();
        this.getYAxis = (measurementOne, measurementTwo, yAxisLabel, yAxisM1minVal, yAxisM1maxVal, yAxisM2minVal, yAxisM2maxVal) => {
            const graphTypeM1 = this.getGraphType(measurementOne);
            const yAxis = [];
            yAxis.push({
                zoomEnabled: graphTypeM1 !== GRAPH_TYPES.BAR && graphTypeM1 !== GRAPH_TYPES.COLUMN,
                gridLineWidth: 1,
                visible: !this.useMinimalUI,
                title: {
                    text: yAxisLabel,
                    style: {
                        fontWeight: 'bold',
                    },
                    y: 5,
                },
                min: yAxisM1minVal,
                max: yAxisM1maxVal,
                height: '100%',
            });
            if (measurementTwo) {
                const graphTypeM2 = this.getGraphType(measurementTwo);
                yAxis.push({
                    zoomEnabled: graphTypeM2 !== GRAPH_TYPES.BAR && graphTypeM2 !== GRAPH_TYPES.COLUMN,
                    gridLineWidth: 1,
                    visible: !this.useMinimalUI,
                    title: {
                        text: yAxisLabel,
                        style: {
                            fontWeight: 'bold',
                        },
                        y: 5,
                    },
                    min: graphTypeM2 === GRAPH_TYPES.BAR || graphTypeM2 === GRAPH_TYPES.COLUMN ? 0 : yAxisM2minVal,
                    max: yAxisM2maxVal,
                    height: '100%',
                    // top: '0%',
                });
            }
            return yAxis;
        };
        this.getSeries = (colorM1, colorAlphaM1, timestampsM1, valuesM1, colorM2, colorAlphaM2, timestampsM2, valuesM2, seriesNameM1, seriesNameM2, averageLineData, injectedTimestampsM1, injectedTimestampsM2) => {
            const zoneYesterdayMidnight = getDateMinusRange(RANGES.ONE_DAY, dateToUTCDate(new Date())).setHours(0);
            const zone24HoursAgo = getDateMinusRange(RANGES.ONE_DAY, dateToUTCDate(new Date())).getTime();
            const zoneToday = dateToUTCDate(new Date()).getTime();
            const series = [];
            const zonesM1 = [];
            // push a dot zone for injected timestamps
            if (injectedTimestampsM1 === null || injectedTimestampsM1 === void 0 ? void 0 : injectedTimestampsM1.length) {
                for (const ts of injectedTimestampsM1) {
                    const realValueDate = timestampsM1.find((date) => {
                        return Number(date) > ts;
                    });
                    zonesM1.push({ value: realValueDate, dashStyle: 'Dot' });
                }
            }
            zonesM1.push({
                value: zoneYesterdayMidnight,
                dashStyle: 'Solid'
            });
            zonesM1.push({
                value: zone24HoursAgo,
                dashStyle: MEASUREMENTS_LACKING_24HR_DATA.includes(this.measurementOne) ? 'Dot' : 'Solid',
            });
            zonesM1.push({
                value: zoneToday,
                dashStyle: MEASUREMENTS_LACKING_24HR_DATA.includes(this.measurementOne) ? 'Dot' : 'Solid',
            });
            const graphTypeM1 = this.getGraphType(this.measurementOne);
            series.push({
                id: 'trend-line-m1',
                yAxis: 0,
                color: colorM1,
                fillColor: colorAlphaM1,
                data: valuesM1,
                lineWidth: 2,
                type: graphTypeM1,
                name: seriesNameM1,
                animation: true,
                zoneAxis: 'x',
                // dot zones are for projected data
                zones: zonesM1,
                threshold: graphTypeM1 === GRAPH_TYPES.BAR || graphTypeM1 === GRAPH_TYPES.COLUMN ? 0 : -9999
            });
            if (valuesM2 === null || valuesM2 === void 0 ? void 0 : valuesM2.length) {
                const zonesM2 = [];
                const graphTypeM2 = this.getGraphType(this.measurementTwo);
                if (injectedTimestampsM2 === null || injectedTimestampsM2 === void 0 ? void 0 : injectedTimestampsM2.length) {
                    const nonInjectedTimestamps = timestampsM2.filter((ts) => {
                        return injectedTimestampsM2.indexOf(ts) == -1;
                    });
                    let injectedTS = injectedTimestampsM2[0];
                    let nonInjectedTS = -1;
                    while (injectedTS) {
                        injectedTS = injectedTimestampsM2.find((ts) => {
                            return ts > nonInjectedTS;
                        });
                        if (injectedTS) {
                            zonesM2.push({ value: injectedTS, dashStyle: 'solid' });
                        }
                        nonInjectedTS = nonInjectedTimestamps.find((ts) => {
                            return ts > injectedTS;
                        });
                        zonesM2.push({ value: nonInjectedTS, dashStyle: 'dot' });
                    }
                }
                series.push({
                    id: 'trend-line-m2',
                    yAxis: 1,
                    color: colorM2,
                    fillColor: colorAlphaM2,
                    data: valuesM2,
                    lineWidth: 2,
                    type: graphTypeM2,
                    name: seriesNameM2,
                    animation: true,
                    zoneAxis: 'x',
                    pointWidth: graphTypeM2 === GRAPH_TYPES.BAR || graphTypeM2 === GRAPH_TYPES.COLUMN ? 10 : undefined,
                    // dot zones are for projected data
                    zones: zonesM2,
                    threshold: graphTypeM2 === GRAPH_TYPES.BAR || graphTypeM2 === GRAPH_TYPES.COLUMN ? 0 : -9999
                });
            }
            series.push({
                id: 'average-line',
                linkedTo: 'trend-line-m1',
                color: 'rgb(80,80,80)',
                data: averageLineData,
                type: 'line',
                lineWidth: 0.5,
                dashStyle: 'LongDashDot',
                allowPointSelect: false,
                enableMouseTracking: false,
                showInLegend: false,
                visible: this.shouldPlotAverages,
                animation: false,
            });
            return series;
        };
        this.injectMissingTimestamps = (data, expectedTimestamps) => {
            const injectedTimestamps = [];
            const earliestTS = data === null || data === void 0 ? void 0 : data.map(gd => gd.timestamp)[0];
            const earliestValue = data[0].value;
            const lastTS = data === null || data === void 0 ? void 0 : data.map(gd => gd.timestamp)[data.length - 1];
            const lastValue = data === null || data === void 0 ? void 0 : data.map(gd => gd.value)[data.length - 1];
            for (const ts of expectedTimestamps) {
                if (ts < earliestTS * 1000) {
                    const injectedTS = Number(ts);
                    data.push({
                        timestamp: injectedTS / 1000,
                        value: earliestValue
                    });
                    injectedTimestamps.push(injectedTS);
                }
                else if (ts > lastTS * 1000) {
                    const injectedTS = Number(ts);
                    data.push({
                        timestamp: injectedTS / 1000,
                        value: lastValue
                    });
                    injectedTimestamps.push(injectedTS);
                }
            }
            injectedTimestamps.sort((a, b) => { return a > b ? 1 : -1; });
            data.sort((a, b) => { return a.timestamp > b.timestamp ? 1 : -1; });
            return { amendedData: data, injectedTimestamps: injectedTimestamps };
        };
        this.injectMissingTimestampsFromRange = (data, range) => {
            const expectedDatesFromRange = getDatesInRange(this.range);
            const injectedTimestamps = [];
            if ((data === null || data === void 0 ? void 0 : data.length) !== expectedDatesFromRange.length) {
                for (const day of expectedDatesFromRange) {
                    if (!data.find((gData) => {
                        let raw;
                        let expected;
                        if (range === RANGES.ONE_DAY) {
                            raw = new Date(gData.timestamp * 1000).getHours();
                            expected = day.getHours();
                        }
                        else if (range === RANGES.TWO_DAYS || range === RANGES.WEEKLY) {
                            raw = new Date(gData.timestamp * 1000).getDate();
                            expected = day.getDate();
                        }
                        else if (range === RANGES.MONTHLY) {
                            raw = new Date(gData.timestamp * 1000).getDate();
                            expected = day.getDate();
                        }
                        else if (range === RANGES.YEARLY) {
                            raw = new Date(gData.timestamp * 1000).getMonth();
                            expected = day.getMonth();
                        }
                        return raw === expected;
                    })) {
                        const ts = day.getTime() / 1000;
                        data.push({
                            timestamp: ts,
                            value: 0
                        });
                        injectedTimestamps.push(ts * 1000);
                    }
                }
            }
            data.sort((a, b) => { return a.timestamp > b.timestamp ? 1 : -1; });
            injectedTimestamps.sort((a, b) => { return a > b ? 1 : -1; });
            return { amendedData: data, injectedTimestamps: injectedTimestamps };
        };
        this.getDatesAndValues = (rawData, measurement, categoryGraph) => {
            let timestamps = [];
            let values;
            // values for a category graph with dates handled separately
            if (categoryGraph) {
                const { amendedData } = this.injectMissingTimestampsFromRange(rawData, this.range);
                values = amendedData ? amendedData.map((gData) => {
                    return getDisplayValue(measurement, gData.value);
                }) : [];
                timestamps = amendedData ? amendedData.map((gData) => {
                    return gData.timestamp;
                }) : [];
            }
            else {
                let lastValidValue = 0;
                let injectedProjectedData = false;
                values = rawData ? rawData.map((gData) => {
                    let value = gData.value;
                    // compensate for data not yet processed within last 24 hrs
                    if (isTimeStampLast24hrs(gData.timestamp) && MEASUREMENTS_LACKING_24HR_DATA.includes(measurement)) {
                        injectedProjectedData = true;
                        value = lastValidValue;
                    }
                    else {
                        lastValidValue = value;
                    }
                    const d = new Date(gData.timestamp * 1000).getTime();
                    timestamps.push(d);
                    // values for a datetime graph (value and date)
                    return [
                        d,
                        getDisplayValue(measurement, value),
                    ];
                }) : [];
                // push a timestamp from now / today to end projection
                if (injectedProjectedData) {
                    const date = new Date();
                    date.setMinutes(0);
                    const timeStamp = dateToUTCDate(date).getTime();
                    values.push([
                        timeStamp,
                        getDisplayValue(measurement, lastValidValue),
                    ]);
                }
            }
            return { timestamps, values };
        };
        this.getDisplaySummary = (unit, displayTotal, rawData, decimals, average, min, max) => {
            const displayMax = min.toFixed(decimals) + unit;
            const displayMin = max.toFixed(decimals) + unit;
            const spaceBeforeUnit = UNITS_SPACE_PREFIX.includes(unit) ? true : false;
            const pluralUnits = UNITS_PLURAL.includes(unit) ? true : false;
            const displayValue = displayTotal ? getTotal(rawData).toFixed(decimals) : average.toFixed(decimals);
            const displayUnit = (pluralUnits && (displayTotal ? Math.ceil(getTotal(rawData)) > 1 : average > 1)) ? unit.concat('s') : unit;
            let displaySummary;
            if (UNITS_PRECEDING_VALUE.includes(unit)) {
                displaySummary = displayUnit + displayValue;
            }
            else {
                displaySummary = displayValue + (spaceBeforeUnit ? ' ' : '') + displayUnit;
            }
            const summaryTitle = displayTotal ? 'Total' : 'Average';
            return this.useMinimalUI
                ? `<span class="legend-summary-streamlined"> <span class="summary"> ${displaySummary} </span> ${summaryTitle}</span>`
                : `<span class="legend-summary"> Average: <span class="summary">${displaySummary}</span> Max: ${displayMax} Min: ${displayMin}</span>`;
        };
        this.drawGraph = () => {
            var _a;
            const aggregateFuncM1 = MEASUREMENT_TO_FUNC.get(this.measurementOne);
            const rangeData = (_a = this._data) === null || _a === void 0 ? void 0 : _a.get(this.range);
            const m1Name = this.shouldAggregate ? aggregateFuncM1 + '(' + this.measurementOne + ')' : this.measurementOne;
            const rawDataM1 = rangeData === null || rangeData === void 0 ? void 0 : rangeData.get(m1Name);
            const graphType = this.getGraphType(this.measurementOne);
            const isBarOrColumnGraph = graphType === GRAPH_TYPES.BAR || graphType === GRAPH_TYPES.COLUMN;
            const { timestamps: timestampsM1, values: valuesM1 } = this.getDatesAndValues(rawDataM1, this.measurementOne, isBarOrColumnGraph);
            const xAxisLabelFormatterFunc = this.xAxisLabelFormatter;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const componentContext = this;
            const range = this.range;
            let valuesM2;
            let timestampsM2;
            let rawDataM2;
            let colorM2;
            let yAxisMinM2;
            let yAxisMaxM2;
            let unitM2;
            let displayTotalM2;
            let decimalsM2;
            let m2Name;
            let LabelM2;
            let colorAlphaM2;
            if (this.measurementTwo) {
                const aggregateFuncM2 = MEASUREMENT_TO_FUNC.get(this.measurementTwo);
                m2Name = this.shouldAggregate ? aggregateFuncM2 + '(' + this.measurementTwo + ')' : this.measurementTwo;
                rawDataM2 = rangeData === null || rangeData === void 0 ? void 0 : rangeData.get(m2Name);
                const { timestamps, values } = this.getDatesAndValues(rawDataM2, this.measurementTwo, isBarOrColumnGraph);
                const { color, label, yAxisMin, yAxisMax, unit, displayTotal, decimals, colorAlpha } = getMeasurementUI(this.measurementTwo);
                colorM2 = color;
                yAxisMinM2 = yAxisMin;
                yAxisMaxM2 = yAxisMax;
                unitM2 = unit;
                displayTotalM2 = displayTotal;
                decimalsM2 = decimals;
                LabelM2 = label;
                colorAlphaM2 = colorAlpha;
                valuesM2 = values;
                timestampsM2 = timestamps;
            }
            let injectedTimestampsM2 = [];
            if (rawDataM2 && rawDataM2.length) {
                //let amendedData = [...rawDataM2];
                const { amendedData, injectedTimestamps } = this.injectMissingTimestamps(rawDataM2, timestampsM1);
                injectedTimestampsM2 = injectedTimestamps;
                const { values, timestamps } = this.getDatesAndValues(amendedData, this.measurementTwo, isBarOrColumnGraph);
                valuesM2 = values;
                timestampsM2 = timestamps;
            }
            const { unit: unitM1, label: LabelM1, yAxisLabel, color: colorM1, colorAlpha: colorAlphaM1, decimals: decimalsM1, icon, subtitle, yAxisMin: yAxisMinM1, yAxisMax: yAxisMaxM1, yAxisRangePercentLimit, displayTotal: displayTotalM1 } = getMeasurementUI(this.measurementOne);
            const averageM1 = getAverage(rawDataM1, this.measurementOne, timestampsM1.length);
            const maxMinM1 = getMaxMin(rawDataM1, this.measurementOne);
            let averageM2;
            let maxMinM2;
            let yAxisM2minVal;
            let yAxisM2maxVal;
            if (this.measurementTwo) {
                averageM2 = getAverage(rawDataM2, this.measurementTwo, timestampsM1.length);
                maxMinM2 = getMaxMin(rawDataM2, this.measurementTwo);
                const min = Math.min(maxMinM1.min, maxMinM2.min);
                const max = Math.max(maxMinM1.max, maxMinM2.max);
                yAxisM2minVal = yAxisMinM2 ? yAxisMinM2 : yAxisRangePercentLimit ? Math.floor(min - (min * (yAxisRangePercentLimit / 100))) : null;
                yAxisM2maxVal = yAxisMaxM2 ? yAxisMaxM2 : yAxisRangePercentLimit ? Math.ceil(max + (min * (yAxisRangePercentLimit / 100))) : null;
            }
            // use same min max y axis values for M1 and M2
            const yAxisM1minVal = yAxisM2minVal !== undefined && rawDataM2.length ? yAxisM2minVal :
                yAxisMinM1 ? yAxisMinM1 : yAxisRangePercentLimit ? Math.floor(maxMinM1.min - (maxMinM1.min * (yAxisRangePercentLimit / 100))) : null;
            const yAxisM1maxVal = yAxisM2maxVal !== undefined && rawDataM2.length ? yAxisM2maxVal :
                yAxisMaxM1 ? yAxisMaxM1 : yAxisRangePercentLimit ? Math.ceil(maxMinM1.max + (maxMinM1.min * (yAxisRangePercentLimit / 100))) : null;
            let seriesNameM1 = this.getDisplaySummary(unitM1, displayTotalM1, rawDataM1, decimalsM1, averageM1, maxMinM1.min, maxMinM1.max);
            let seriesNameM2 = this.measurementTwo ? this.getDisplaySummary(unitM2, displayTotalM2, rawDataM2, decimalsM2, averageM2, maxMinM2.min, maxMinM2.max) : '';
            if (this.measurementTwo) {
                seriesNameM1 = seriesNameM1 + ' ' + LabelM1;
                seriesNameM2 = seriesNameM2 + ' ' + LabelM2;
            }
            let averageLineData;
            if (this.shouldPlotAverages) {
                averageLineData = rawDataM1.map((gData) => {
                    return [new Date(gData.timestamp * 1000).getTime(), averageM1];
                });
            }
            let labelInterval;
            if (!isBarOrColumnGraph) {
                switch (this.range) {
                    case RANGES.ONE_DAY:
                    case RANGES.TWO_DAYS: {
                        labelInterval = 1000 * 3600 * 8;
                        break;
                    }
                    case RANGES.WEEKLY:
                    case RANGES.MONTHLY: {
                        labelInterval = 1000 * 3600 * 24;
                        break;
                    }
                    case RANGES.YEARLY: {
                        labelInterval = 30 * 24 * 3600 * 1000;
                        break;
                    }
                }
            }
            const legendAlign = (valuesM2 === null || valuesM2 === void 0 ? void 0 : valuesM2.length) ? 'center' : this.useMinimalUI ? 'left' : 'right';
            const titleAlign = this.titlePosition === 'center' ? 'center' : this.titlePosition === 'left' ? 'left' : this.useMinimalUI ? 'left' : 'center';
            this._chart = Highcharts.chart(this.shadowRoot.getElementById('canvas'), {
                chart: {
                    zoomType: 'x',
                    style: {
                        fontFamily: 'museo-sans, sans-serif',
                    },
                },
                xAxis: {
                    type: isBarOrColumnGraph ? 'category' : 'datetime',
                    zoomEnabled: !isBarOrColumnGraph,
                    gridLineWidth: this.useMinimalUI ? 1 : 0,
                    gridLineColor: 'rgb(80,80,80, 0.1)',
                    categories: isBarOrColumnGraph ? timestampsM1.map(ts => ts.toString()) : null,
                    startOnTick: false,
                    endOnTick: false,
                    tickInterval: labelInterval,
                    labels: {
                        style: {
                            fontWeight: 'bold',
                        },
                        formatter: function () {
                            return xAxisLabelFormatterFunc.call(componentContext, this.value, isBarOrColumnGraph);
                        },
                    },
                },
                title: {
                    useHTML: icon ? true : false,
                    text: !this.shouldShowTitle
                        ? undefined
                        : icon
                            ? `<img class="chart-icon" src="./assets/${icon}"><span>${LabelM1}</span>`
                            : LabelM1,
                    style: {
                        color: colorM1,
                        fontFamily: 'museo-sans, sans-serif',
                        fontWeight: 'bold',
                        fontSize: '14',
                    },
                    align: titleAlign,
                    y: 40,
                    x: 8
                },
                subtitle: {
                    text: subtitle,
                    style: {
                        color: colorM2,
                        fontFamily: 'museo-sans, sans-serif',
                        fontWeight: 'bold',
                    },
                    y: 45,
                },
                legend: {
                    useHTML: true,
                    labelFormatter: function () {
                        const label = this.index == 0 ? seriesNameM1 : seriesNameM2;
                        const color = this.index == 0 ? colorM1 : colorM2;
                        const classes = legendAlign === 'center' ? "ml-1" : "";
                        return `<p style='color:${color};' class=${classes}>${label}</p>`;
                    },
                    verticalAlign: this.useMinimalUI ? 'top' : 'bottom',
                    align: legendAlign,
                    y: 0,
                    symbolPadding: 0,
                    symbolWidth: 0,
                    symbolHeight: 0,
                    squareSymbol: false,
                },
                tooltip: {
                    valueDecimals: 1,
                    backgroundColor: 'rgba(20,40,82,0.75)',
                    borderColor: "transparent",
                    useHTML: true,
                    formatter: function () {
                        const graphType = componentContext.getGraphType(this.series.index === 1 ? componentContext.measurementTwo : componentContext.measurementOne);
                        const isBarOrColumnGraph = graphType === GRAPH_TYPES.BAR || graphType === GRAPH_TYPES.COLUMN;
                        let value = this.x;
                        if (isBarOrColumnGraph) {
                            value = new Date(this.x * 1000).getTime();
                        }
                        // i.e. Apr 1 2021
                        let dateFormatted = Highcharts.dateFormat.call(this, '%b %e, %Y', value);
                        let displayDate = dateFormatted;
                        // i.e. 09:00 AM
                        let displayHour = Highcharts.dateFormat.call(this, '%I:%M %p ', value);
                        if (graphType === GRAPH_TYPES.BAR || graphType === GRAPH_TYPES.COLUMN) {
                            // i.e. Apr 2021
                            dateFormatted = Highcharts.dateFormat.call(this, '%b, %Y', value);
                            if (range !== RANGES.ONE_DAY || range !== RANGES.TWO_DAYS) {
                                displayHour = '';
                            }
                            if (range === RANGES.YEARLY) {
                                displayDate = dateFormatted;
                            }
                            else if (range === RANGES.MONTHLY) {
                                displayDate = 'Week of ' + displayDate;
                            }
                        }
                        const decimals = this.series.index === 0 ? decimalsM1 : decimalsM2;
                        const unit = this.series.index === 1 ? unitM2 : unitM1;
                        const spaceBeforeUnit = UNITS_SPACE_PREFIX.includes(unit) ? true : false;
                        const pluralUnits = UNITS_PLURAL.includes(unit) ? true : false;
                        const displayUnit = (pluralUnits && this.y > 1) ? unit.concat('s') : unit;
                        const spacer = spaceBeforeUnit ? ' ' : '';
                        const displayValue = UNITS_PRECEDING_VALUE.includes(unit) ? unit + this.y.toFixed(decimals) : this.y.toFixed(decimals) + spacer + displayUnit;
                        return ('<table class="tool-tip">' +
                            '<tr><td style="text-align: center">' +
                            displayDate +
                            '</td></tr>' +
                            '<tr><td style="text-align: center">' +
                            displayHour +
                            '</td></tr>' +
                            '<tr><td style="text-align: center">' +
                            displayValue +
                            '</td></tr>' +
                            '</table>');
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                        },
                    }
                },
                yAxis: this.getYAxis(this.measurementOne, this.measurementTwo, yAxisLabel, yAxisM1minVal, yAxisM1maxVal, yAxisM2minVal, yAxisM2maxVal),
                series: this.getSeries(colorM1, colorAlphaM1, timestampsM1, valuesM1, colorM2, colorAlphaM2, timestampsM2, valuesM2, seriesNameM1, seriesNameM2, averageLineData, [], injectedTimestampsM2),
            });
        };
        Highcharts.setOptions({
            time: {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        });
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                height: 100%;
                display: none;
            }
        `;
    }
    updated(changed) {
        super.updated(changed);
    }
    async firstUpdated(changed) {
        await loadStyle('./css/graphs/berner-graph.css', this.shadowRoot);
        super.firstUpdated(changed);
        this.requestUpdate();
    }
    xAxisLabelFormatter(value, valueIsTimestamp) {
        let formattedDate = '';
        const graphType = this.getGraphType(this.measurementOne);
        if (valueIsTimestamp) {
            const date = new Date(Number(value) * 1000);
            value = date.getTime();
        }
        switch (this.range) {
            case RANGES.ONE_DAY:
                formattedDate = Highcharts.dateFormat('%I %p', value);
                break;
            case RANGES.TWO_DAYS:
                if (graphType === GRAPH_TYPES.BAR || graphType === GRAPH_TYPES.COLUMN) {
                    formattedDate = Highcharts.dateFormat.call(this, '%m.%d', value);
                }
                else {
                    formattedDate = Highcharts.dateFormat('%I %p', value);
                }
                break;
            case RANGES.WEEKLY:
            case RANGES.MONTHLY:
                formattedDate = Highcharts.dateFormat.call(this, '%m.%d', value);
                break;
            case RANGES.YEARLY:
                formattedDate = Highcharts.dateFormat.call(this, '%m', value);
                break;
        }
        return formattedDate;
    }
    render() {
        return html `
        <link id="css-target">
        <div class="css-loading" id="root">
            <div class="container ${this.ratio}" id="container">
                <div class="chart-container">
                    <div class="canvas" id="canvas">
                        <div
                            class="spinner-border spinner-loader"
                            role="status"
                        ></div>
                    </div>
                </div>
            </div>
        </div>`;
    }
};
BernerGraph = __decorate([
    customElement('berner-graph')
], BernerGraph);
export { BernerGraph };
