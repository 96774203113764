import { getLocations, saveLocations, deleteLocations, } from './../api/webApplicationApi';
export class Location {
    constructor(siteID) {
        this._location = {
            siteID: siteID,
            latitude: undefined,
            longitude: undefined,
            locationType: 'postal_code',
            location: undefined,
            naturalGasRate: 0,
            electricityRate: 0,
            oilRate: 0,
            powerCycles: 60,
        };
    }
    static createFromObject(location) {
        const obj = new Location(location.siteID);
        obj._location = location;
        return obj;
    }
    async load() {
        const response = await getLocations([this._location.siteID]);
        if (response.length > 0) {
            this._location = response[0];
            return true;
        }
        return false;
    }
    async save() {
        if (this._location.latitude == undefined) {
            this._lastError = new Error('Latitude is undefined');
            return false;
        }
        if (this._location.longitude == undefined) {
            this._lastError = new Error('longitude is undefined');
            return false;
        }
        if (this._location.locationType == undefined) {
            this._lastError = new Error('Location Type is undefined');
            return false;
        }
        if (this._location.location == undefined) {
            this._lastError = new Error('Location is undefined');
            return false;
        }
        if (this._location.naturalGasRate == undefined) {
            this._lastError = new Error('Natural Gas Rate is undefined');
            return false;
        }
        if (this._location.electricityRate == undefined) {
            this._lastError = new Error('Electricity Rate is undefined');
            return false;
        }
        if (this._location.oilRate == undefined) {
            this._lastError = new Error('Oil Rate is undefined');
            return false;
        }
        if (this._location.powerCycles == undefined) {
            this._lastError = new Error('Power Cycles Rate is undefined');
            return false;
        }
        this._lastError = undefined;
        try {
            const response = await saveLocations([this._location]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        this._lastError = undefined;
        try {
            const response = await deleteLocations([this._location.siteID]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get longitude() {
        return this._location.longitude;
    }
    set longitude(longitude) {
        this._location.longitude = longitude;
    }
    get latitude() {
        return this._location.latitude;
    }
    set latitude(latitude) {
        this._location.latitude = latitude;
    }
    get postalCode() {
        return this._location.location;
    }
    set postalCode(postalCode) {
        this._location.location = postalCode;
    }
    get powerCycles() {
        return this._location.powerCycles;
    }
    set powerCycles(powerCycles) {
        this._location.powerCycles = powerCycles;
    }
    get electricityRate() {
        return this._location.electricityRate;
    }
    set electricityRate(electricityRate) {
        this._location.electricityRate = electricityRate;
    }
    get naturalGasRate() {
        return this._location.naturalGasRate;
    }
    set naturalGasRate(naturalGasRate) {
        this._location.naturalGasRate = naturalGasRate;
    }
    get oilRate() {
        return this._location.oilRate;
    }
    set oilRate(oilRate) {
        this._location.oilRate = oilRate;
    }
}
