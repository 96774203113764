export const SET_APP_STATE = 'SET_APP_STATE';
export const AUTH_FAILED = 'AUTH_FAILED';
export const SET_APP_TEXT = 'SET_APP_TEXT';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_OBJECT_MANAGER = 'SET_OBJECT_MANAGER';
export const SET_NAV_BAR_COLLAPSED_STATE = 'SET_NAV_BAR_COLLAPSED_STATE';
export const SET_MEASUREMENT_DATA = "SET_MEASUREMENT_DATA";
export const SET_OLD_MEASUREMENT_DATA = "SET_OLD_MEASUREMENT_DATA";
export const RESET_CACHED_MEASUREMENT_DATA = "RESET_CACHED_MEASUREMENT_DATA";
export const SET_MEASUREMENT_DATA_CACHED_STATE = "SET_MEASUREMENT_DATA_CACHED_STATE";
export const SET_MEASUREMENT_DATA_CACHING_IN_PROGRESS_STATE = "SET_MEASUREMENT_DATA_CACHING_IN_PROGRESS_STATE";
export const SET_PRELOAD_OBJECTS_STATE = "SET_PRELOAD_OBJECTS_STATE";
export const SET_PRELOAD_OBJECTS_IN_PROGRESS_STATE = "SET_PRELOAD_OBJECTS_IN_PROGRESS_STATE";
export const RESET_PRELOADED_OBJECTS_STATE = "RESET_PRELOADED_OBJECTS_STATE";
export const setAppState = (appState) => {
    return {
        type: SET_APP_STATE,
        payload: {
            appState,
        },
    };
};
export const authFailed = (error) => {
    return {
        type: AUTH_FAILED,
        payload: {
            error,
        },
    };
};
export const setAppText = (applicationText) => {
    return {
        type: SET_APP_TEXT,
        payload: {
            applicationText,
        },
    };
};
export const setUserEmail = (userEmail) => {
    return {
        type: SET_USER_EMAIL,
        payload: {
            userEmail: userEmail,
        },
    };
};
export const setNavBarCollapsedState = (collapsed) => {
    return {
        type: SET_NAV_BAR_COLLAPSED_STATE,
        payload: {
            navBarCollapsedState: collapsed,
        },
    };
};
export const setObjectManager = (objectManager) => {
    return {
        type: SET_OBJECT_MANAGER,
        payload: {
            objectManager,
        },
    };
};
export const setMeasurementData = (deviceId, measurementData) => {
    return {
        type: SET_MEASUREMENT_DATA,
        payload: {
            deviceId,
            measurementData,
        },
    };
};
export const setOldMeasurementData = (deviceId, measurementDataOld) => {
    return {
        type: SET_OLD_MEASUREMENT_DATA,
        payload: {
            deviceId,
            measurementDataOld,
        },
    };
};
export const setMeasurementDataCachedState = (cached) => {
    return {
        type: SET_MEASUREMENT_DATA_CACHED_STATE,
        payload: {
            measurementDataCachedState: cached,
        },
    };
};
export const setMeasurementDataCachingInProgressState = () => {
    return {
        type: SET_MEASUREMENT_DATA_CACHING_IN_PROGRESS_STATE,
        payload: {
            measurementDataCachingInProgressState: true,
        },
    };
};
export const resetCachedMeasurementData = () => {
    return {
        type: RESET_CACHED_MEASUREMENT_DATA,
        payload: {},
    };
};
export const setPreloadObjectsState = (preloaded) => {
    return {
        type: SET_PRELOAD_OBJECTS_STATE,
        payload: {
            preloadObjectsState: preloaded,
        },
    };
};
export const setPreloadObjectsInProgressState = () => {
    return {
        type: SET_PRELOAD_OBJECTS_IN_PROGRESS_STATE,
        payload: {
            preloadObjectsInProgressState: true,
        },
    };
};
export const resetPreloadedObjectsState = () => {
    return {
        type: RESET_PRELOADED_OBJECTS_STATE,
        payload: {},
    };
};
