import { getObjects, createObjects, updateObjects, updateShadow, getShadow, } from './../api/webApplicationApi';
const objectType = 'airCurtain';
export class AirCurtain {
    constructor(airCurtainID) {
        this._saveMethod = createObjects;
        this._object = {
            name: undefined,
            airCurtainID: airCurtainID,
            airCurtainGroups: [],
            connected: undefined,
            connectivityTimestamp: undefined,
            eccPub: undefined,
            heaterPower: undefined,
            model: undefined,
            pairingKey: undefined,
        };
    }
    static createNew(airCurtainID) {
        return new AirCurtain(airCurtainID);
    }
    static createFromObject(airCurtain, shadow) {
        const obj = new AirCurtain(airCurtain.airCurtainID);
        obj._object = airCurtain;
        obj._shadow = shadow;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.airCurtainID],
        };
        const getShadowRequest = {
            airCurtainID: this._object.airCurtainID,
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                this._shadow = await getShadow(getShadowRequest);
                return true;
            }
            else {
                this._lastError = new Error('air curtain not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async refreshShadow() {
        const getShadowRequest = {
            airCurtainID: this._object.airCurtainID,
        };
        this._lastError = undefined;
        try {
            this._shadow = await getShadow(getShadowRequest);
            return true;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async saveProperties() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.name == undefined) {
            this._lastError = new Error('Name is undefined');
            return false;
        }
        if (this._object.heaterPower == undefined) {
            this._lastError = new Error('Heater Power is undefined');
            return false;
        }
        if (this._object.model == undefined) {
            this._lastError = new Error('Model is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknown server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async saveAirCurtainDesired(desired) {
        const updateShadowRequest = {
            airCurtainID: this._object.airCurtainID,
            desired: desired,
        };
        this._lastError = undefined;
        try {
            const response = await updateShadow(updateShadowRequest);
            if (!response) {
                this._lastError = new Error('unknown server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    // public async delete() {
    //     const deleteObject:CreateUpdateDeleteRequest = {
    //         objectType: objectType,
    //         object: this._object
    //     };
    //     return new Promise(resolve => deleteObjects([deleteObject]).then(response => resolve(response)));
    // }
    //object properties
    get lastError() {
        return this._lastError;
    }
    get airCurtainID() {
        return this._object.airCurtainID;
    }
    get name() {
        return this._object.name;
    }
    set name(name) {
        this._object.name = name;
    }
    get airCurtainGroups() {
        if (this._object.airCurtainGroups === undefined) {
            this._object.airCurtainGroups = [];
        }
        return this._object.airCurtainGroups;
    }
    addAirCurtainGroup(airCurtainGroup) {
        if (this._object.airCurtainGroups.includes(airCurtainGroup.airCurtainGroupID))
            return;
        this._object.airCurtainGroups.push(airCurtainGroup.airCurtainGroupID);
    }
    deleteAirCurtainGroup(airCurtainGroup) {
        const index = this._object.airCurtainGroups.indexOf(airCurtainGroup.airCurtainGroupID);
        if (index > -1) {
            this._object.airCurtainGroups.splice(index, 1);
        }
    }
    get connected() {
        return this._object.connected;
    }
    get connectivityTimestamp() {
        return this._object.connectivityTimestamp;
    }
    get eccPub() {
        return this._object.eccPub;
    }
    get heaterPower() {
        return this._object.heaterPower;
    }
    set heaterPower(heaterPower) {
        this._object.heaterPower = heaterPower;
    }
    get model() {
        return this._object.model;
    }
    set model(model) {
        this._object.model = model;
    }
    get pairingKey() {
        return this._object.pairingKey;
    }
    get provisionedTimestamp() {
        return this._object.provisionedTimestamp;
    }
    // shadow properties
    get isShadowLoaded() {
        return this._shadow != undefined;
    }
    get bacnetConfiguration() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.bacnetConfiguration
            ? {
                enabled: this._shadow.bacnetConfiguration.enabled,
                deviceName: this._shadow.bacnetConfiguration.deviceName,
                deviceId: this._shadow.bacnetConfiguration.deviceId,
                udpPort: this._shadow.bacnetConfiguration.udpPort,
                bbmdEnabled: this._shadow.bacnetConfiguration.bbmdEnabled,
                bbmdAddress: this._shadow.bacnetConfiguration.bbmdAddress,
                bbmdPort: this._shadow.bacnetConfiguration.bbmdPort,
                bbmdTtl: this._shadow.bacnetConfiguration.bbmdTtl,
                interface: this._shadow.bacnetConfiguration.interface,
            }
            : undefined;
    }
    async setBacnetConfiguration(bacnetConfiguration) {
        if (!this.isShadowLoaded)
            return false;
        const bacnetConfigurationShadow = {
            enabled: bacnetConfiguration.enabled != undefined
                ? bacnetConfiguration.enabled
                : this._shadow.bacnetConfiguration.enabled,
            deviceName: bacnetConfiguration.deviceName != undefined
                ? bacnetConfiguration.deviceName
                : this._shadow.bacnetConfiguration.deviceName,
            deviceId: bacnetConfiguration.deviceId != undefined
                ? bacnetConfiguration.deviceId
                : this._shadow.bacnetConfiguration.deviceId,
            udpPort: bacnetConfiguration.udpPort != undefined
                ? bacnetConfiguration.udpPort
                : this._shadow.bacnetConfiguration.udpPort,
            bbmdEnabled: bacnetConfiguration.bbmdEnabled != undefined
                ? bacnetConfiguration.bbmdEnabled
                : this._shadow.bacnetConfiguration.bbmdEnabled,
            bbmdAddress: bacnetConfiguration.bbmdAddress != undefined
                ? bacnetConfiguration.bbmdAddress
                : this._shadow.bacnetConfiguration.bbmdAddress,
            bbmdPort: bacnetConfiguration.bbmdPort != undefined
                ? bacnetConfiguration.bbmdPort
                : this._shadow.bacnetConfiguration.bbmdPort,
            bbmdTtl: bacnetConfiguration.bbmdTtl != undefined
                ? bacnetConfiguration.bbmdTtl
                : this._shadow.bacnetConfiguration.bbmdTtl,
            interface: bacnetConfiguration.interface != undefined
                ? bacnetConfiguration.interface
                : this._shadow.bacnetConfiguration.interface,
        };
        const desired = {
            bacnetConfiguration: bacnetConfigurationShadow,
        };
        const result = await this.saveAirCurtainDesired(desired);
        if (result) {
            this._shadow.bacnetConfiguration = bacnetConfigurationShadow;
        }
        return result;
    }
    get cpuSerial() {
        if (!this.isShadowLoaded)
            return undefined;
        if (this._shadow.cpuSerial)
            return this._shadow.cpuSerial.replace(/^[0]+/g, '');
        else
            return 'N/A';
    }
    get doorState() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.doorState;
    }
    get doorOpenCount() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.doorOpenCount;
    }
    get eth0Ip() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.eth0Ip;
    }
    get eth0Mac() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.eth0Mac;
    }
    get externalThermostatContactState() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.externalThermostatContactState;
    }
    get thermostatProbeModeState() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.thermostatProbeModeState;
    }
    get heaterRelayState() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.heaterRelayState;
    }
    get sensorTemperature() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.temperature;
    }
    get intelliswitchVersion() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.intelliswitchVersion;
    }
    get lineFrequency() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.lineFrequency;
    }
    get mode() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.modeState;
    }
    async setMode(mode) {
        if (!this.isShadowLoaded)
            return false;
        const desired = {
            modeState: mode,
        };
        const result = await this.saveAirCurtainDesired(desired);
        if (result) {
            this._shadow.modeState = mode;
        }
        return result;
    }
    get motorRpm() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.motorRpm;
    }
    get osVersion() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.osVersion;
    }
    get wcsVersion() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.wcsVersion;
    }
    get pureMode() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.pureMode;
    }
    get schedule() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.schedule
            ? {
                enabled: this._shadow.schedule.enabled,
                events: this._shadow.schedule.events
                    ? this._shadow.schedule.events.map((item) => {
                        return {
                            dayMinute: item.day_minute,
                            mode: item.mode,
                            weekDay: item.week_day,
                            enabled: item.enabled,
                        };
                    })
                    : [],
            }
            : undefined;
    }
    async setSchedule(schedule) {
        if (!this.isShadowLoaded)
            return false;
        const scheduleShadow = {
            enabled: schedule.enabled,
            events: schedule.events.map((item) => {
                return {
                    day_minute: item.dayMinute,
                    mode: item.mode,
                    week_day: item.weekDay,
                    enabled: item.enabled,
                };
            }),
        };
        const desired = {
            schedule: scheduleShadow,
        };
        const result = await this.saveAirCurtainDesired(desired);
        if (result) {
            this._shadow.schedule = scheduleShadow;
        }
        return result;
    }
    get fanSpeed() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.fanSpeed;
    }
    async setFanSpeed(fanSpeed) {
        if (!this.isShadowLoaded)
            return false;
        const desired = {
            fanSpeed: fanSpeed,
        };
        const result = await this.saveAirCurtainDesired(desired);
        if (result) {
            this._shadow.fanSpeed = fanSpeed;
        }
        return result;
    }
    // fan speeds
    get fanSpeeds() {
        if (!this.isShadowLoaded)
            return undefined;
        switch (this._shadow.speedRange) {
            case 3:
            case 4:
            case 5:
                return 1;
            case 10:
            case 11:
                return 2;
            case 6:
            case 7:
            case 8:
            case 9:
                return 3;
            case 0:
            case 1:
            case 2:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 20:
            case 21:
            case 22:
            case 23:
            case 30:
            case 31:
            case 32:
            case 33:
            case 40:
            case 41:
            case 42:
            case 43:
                return 10;
            default:
                return 0;
        }
    }
    // has speed control
    get hasSpeedControl() {
        if (!this.isShadowLoaded)
            return undefined;
        return this.fanSpeeds > 0;
    }
    // has thermostat
    get hasThermostat() {
        if (!this.isShadowLoaded)
            return undefined;
        switch (this._shadow.speedRange) {
            case 2:
            case 4:
            case 5:
            case 7:
            case 9:
            case 11:
            case 12:
            case 14:
            case 16:
            case 21:
            case 23:
            case 31:
            case 33:
            case 41:
            case 43:
                return true;
            default:
                return false;
        }
    }
    get speedRange() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.speedRange;
    }
    get temperatureSetPoint() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.temperatureSetPoint;
    }
    async setTemperatureSetPoint(temperatureSetPoint) {
        if (!this.isShadowLoaded)
            return false;
        const desired = {
            temperatureSetPoint: temperatureSetPoint,
        };
        const result = await this.saveAirCurtainDesired(desired);
        if (result) {
            this._shadow.temperatureSetPoint = temperatureSetPoint;
        }
        return result;
    }
    get timeDelay() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.timeDelay;
    }
    async setTimeDelay(timeDelay) {
        if (!this.isShadowLoaded)
            return false;
        const desired = {
            timeDelay: timeDelay,
        };
        const result = await this.saveAirCurtainDesired(desired);
        if (result) {
            this._shadow.timeDelay = timeDelay;
        }
        return result;
    }
    get maxTimestamp() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.maxTimestamp;
    }
    get timezone() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.timezone;
    }
    async setTimezone(timezone) {
        if (!this.isShadowLoaded)
            return false;
        const desired = {
            timezone: timezone,
        };
        const result = await this.saveAirCurtainDesired(desired);
        if (result) {
            this._shadow.timezone = timezone;
        }
        return result;
    }
    get version() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.version;
    }
    get wifiSSID() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.wifiSSID;
    }
    get wlan0Ip() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.wlan0Ip;
    }
    get wlan0Mac() {
        if (!this.isShadowLoaded)
            return undefined;
        return this._shadow.wlan0Mac;
    }
}
