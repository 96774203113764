import { Auth, API } from 'aws-amplify';
import { key as X_API_KEY } from './x-api-key-web-application.json';
const API_NAME = 'WebApplicationAPI';
const APP_VERSION = '2.0';
const PROTOCOL_VERSION = '0.1';
export const getObjects = (getRequests) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getobjects', { getRequests });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const createObjects = (createRequests) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('createobjects', { createRequests });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const updateObjects = (updateRequests) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('updateobjects', { updateRequests });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const deleteObjects = (deleteRequests) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('deleteobjects', { deleteRequests });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getUserPolicies = (objectTypes) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getuserpolicies', { objectTypes });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const verifyPermissions = (request) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('verifypermissions', request);
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getShadow = (request) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getshadow', request);
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const updateShadow = (request) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getshadow', request);
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getObjectAndActionTypes = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getobjectandactiontypes', undefined);
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getInstallations = (installations) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getinstallations', { installations });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const saveInstallations = (installations) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('saveinstallations', { installations });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const deleteInstallations = (installations) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('deleteinstallations', { installations });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getLocations = (siteIDs) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getlocations', { siteIDs });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const saveLocations = (locations) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('savelocations', { locations });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const deleteLocations = (siteIDs) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('deletelocations', { siteIDs });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getCognitoUser = (request) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getcognitouser', request);
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const sendInvitationEmail = (request) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('sendinvitationemail', request);
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getSiteCurrentWeather = (request) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getsitecurrentweather', request);
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getUnitIDs = (orderID) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getunitids', { orderID });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
export const getIdentityAirCurtainCounts = (identityIDs) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post('getidentityaircurtaincounts', { identityIDs });
            resolve(response.data.body.response);
        }
        catch (error) {
            if (error.response &&
                error.response.data &&
                error.response.data.body &&
                error.response.data.body.error) {
                reject(new Error(error.response.data.body.error));
            }
            else {
                reject(error);
            }
        }
    });
};
const post = async (endpoint, body) => {
    const session = await Auth.currentSession();
    const init = {
        headers: {
            Authorization: session.getIdToken().getJwtToken(),
            'x-api-key': X_API_KEY,
        },
        response: true,
        body: body,
    };
    const response = await API.post(API_NAME, '/prod/' + endpoint, init);
    // console.log('post response: ', response);
    return response;
};
