import { __decorate } from "tslib";
import { LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { GRAPH_TYPES } from '../../api/reporting';
import { AGGREGATES_KEY, COLUMN_CHART_MEASUREMENTS } from '../../api/graphDataApi';
import { getCachedData, MEASUREMENT_TO_FUNC } from '../../api/dataManagementApi';
import { EVENTS } from '../../api/ui';
let BernerChartBase = class BernerChartBase extends LitElement {
    constructor() {
        super(...arguments);
        this.deviceIds = '';
        this.measurementOne = '';
        this.measurementTwo = '';
        this.plotAverages = 'false';
        this.aggregate = 'false';
        this.sum = 'false';
        this.labelOne = '';
        this.labelTwo = '';
        this.range = '';
        this.minimalUI = 'false';
        this.showTitle = 'true';
        this.titlePosition = '';
        this.ratio = '';
        this.siteId = '';
        this._error = '';
        this._hasData = false;
        this._data = new Map();
        this._emittedRenderedEvent = false;
        this.getGraphType = (measurement) => {
            const graphType = COLUMN_CHART_MEASUREMENTS.includes(measurement) ?
                GRAPH_TYPES.COLUMN : GRAPH_TYPES.AREASPLINE;
            return graphType;
        };
        this.onTempUnitChanged = () => {
            this.refreshGraph();
        };
        this.drawGraph = () => {
            throw new Error('drawGraph should be implemented in a derived class');
        };
    }
    get useMinimalUI() { return this.minimalUI === 'true'; }
    get shouldShowTitle() { return this.showTitle === 'true'; }
    get shouldPlotAverages() { return this.plotAverages === 'true'; }
    get shouldAggregate() { return this.aggregate === 'true'; }
    get shouldSum() { return this.sum === 'true'; }
    connectedCallback() {
        window.addEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged);
        super.connectedCallback();
    }
    disconnectedCallback() {
        window.removeEventListener(EVENTS.CHANGE_TEMP_UNIT, this.onTempUnitChanged);
        super.disconnectedCallback();
    }
    updated(changed) {
        // changes that require a request to the back-end
        if (changed.has('plotAverages') ||
            changed.has('deviceIds') ||
            changed.has('range') ||
            changed.has('measurementOne') ||
            changed.has('measurementTwo') ||
            changed.has('aggregate') ||
            changed.has('labelOne') ||
            changed.has('labelTwo')) {
            this.refreshGraph();
        }
    }
    async refreshGraph() {
        this._error = '';
        this._hasData = false;
        const measurements = [];
        if (this.measurementOne.trim().length) {
            measurements.push(this.measurementOne);
        }
        if (this.measurementTwo.trim().length) {
            measurements.push(this.measurementTwo);
        }
        let rangeData = new Map();
        const cachedData = await getCachedData(this.range, this.deviceIds.indexOf(',') ? this.deviceIds.split(',') : [this.deviceIds], [this.measurementOne, this.measurementTwo], this.shouldAggregate, false, this.siteId);
        rangeData = cachedData.get(this.shouldAggregate ? AGGREGATES_KEY : this.deviceIds.indexOf(',')
            ? this.deviceIds.split(',')[0]
            : this.deviceIds);
        this._data.set(this.range, rangeData);
        const aggregateFunc = MEASUREMENT_TO_FUNC.get(measurements[0]);
        const measurementName = this.shouldAggregate ? aggregateFunc + '(' + measurements[0] + ')' : measurements[0];
        const deviceData = rangeData === null || rangeData === void 0 ? void 0 : rangeData.get(measurementName);
        if (deviceData === null || deviceData === void 0 ? void 0 : deviceData.length) {
            this._hasData = true;
            // work around, make sure the dom is updated first
            setTimeout(() => {
                this.drawGraph();
                const credits = this.shadowRoot.querySelector('.highcharts-credits');
                credits === null || credits === void 0 ? void 0 : credits.classList.add('hidden');
                this._chart.reflow(); // workaround for chart overflows parent div
                if (!this._emittedRenderedEvent) {
                    this._emittedRenderedEvent = true;
                    const event = new CustomEvent(EVENTS.CHART_RENDERED, {
                        detail: { chart: this.id },
                    });
                    window.dispatchEvent(event);
                }
            }, 1500);
        }
        else {
            this._error = 'no data for ' + this.measurementOne;
            console.log(this._error);
            const canvas = this.shadowRoot.getElementById('canvas');
            canvas.innerHTML = '';
        }
    }
    render() {
        throw new Error('render should be implemented in a derived class');
    }
};
__decorate([
    property({ type: String, attribute: 'device-ids' })
], BernerChartBase.prototype, "deviceIds", void 0);
__decorate([
    property({ type: String, attribute: 'measurement-one' })
], BernerChartBase.prototype, "measurementOne", void 0);
__decorate([
    property({ type: String, attribute: 'measurement-two' })
], BernerChartBase.prototype, "measurementTwo", void 0);
__decorate([
    property({ type: String, attribute: 'plot-averages' })
], BernerChartBase.prototype, "plotAverages", void 0);
__decorate([
    property({ type: String, attribute: 'aggregate' })
], BernerChartBase.prototype, "aggregate", void 0);
__decorate([
    property({ type: String, attribute: 'sum' })
], BernerChartBase.prototype, "sum", void 0);
__decorate([
    property({ type: String, attribute: 'label-one' })
], BernerChartBase.prototype, "labelOne", void 0);
__decorate([
    property({ type: String, attribute: 'label-two' })
], BernerChartBase.prototype, "labelTwo", void 0);
__decorate([
    property({ type: String, attribute: 'range' })
], BernerChartBase.prototype, "range", void 0);
__decorate([
    property({ type: String, attribute: 'minimal-ui' })
], BernerChartBase.prototype, "minimalUI", void 0);
__decorate([
    property({ type: String, attribute: 'show-title' })
], BernerChartBase.prototype, "showTitle", void 0);
__decorate([
    property({ type: String, attribute: 'title-position' })
], BernerChartBase.prototype, "titlePosition", void 0);
__decorate([
    property({ type: String, attribute: 'ratio' })
], BernerChartBase.prototype, "ratio", void 0);
__decorate([
    property({ type: String, attribute: 'site-id' })
], BernerChartBase.prototype, "siteId", void 0);
__decorate([
    state()
], BernerChartBase.prototype, "_error", void 0);
__decorate([
    state()
], BernerChartBase.prototype, "_hasData", void 0);
BernerChartBase = __decorate([
    customElement('berner-chart-base')
], BernerChartBase);
export { BernerChartBase };
