import { getObjects, createObjects, updateObjects, deleteObjects, } from './../api/webApplicationApi';
import { v4 as uuidv4 } from 'uuid';
const objectType = 'organization';
export class Organization {
    constructor(organizationID) {
        this._saveMethod = createObjects;
        this._object = {
            organizationID: organizationID,
            accountID: undefined,
            name: undefined,
            groups: [],
            userGroups: [],
            siteGroups: [],
            roles: [],
            policies: [],
            invitations: [],
        };
    }
    static createNew() {
        return new Organization(uuidv4());
    }
    static createFromObject(organization) {
        const obj = new Organization(organization.organizationID);
        obj._object = organization;
        obj._saveMethod = updateObjects;
        return obj;
    }
    async load() {
        const getObject = {
            objectType: objectType,
            keys: [this._object.organizationID],
        };
        this._lastError = undefined;
        try {
            const response = await getObjects([getObject]);
            if (response &&
                response.length > 0 &&
                response[0].objects &&
                response[0].objects.length == 1) {
                this._saveMethod = updateObjects;
                this._object = response[0].objects[0];
                return true;
            }
            else {
                this._lastError = new Error('organization not found');
                return false;
            }
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async save() {
        const saveObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        if (this._object.name == undefined) {
            this._lastError = new Error('Name is undefined');
            return false;
        }
        if (this._object.accountID == undefined) {
            this._lastError = new Error('Account ID is undefined');
            return false;
        }
        try {
            const response = await this._saveMethod([saveObject]);
            if (response) {
                this._saveMethod = updateObjects;
            }
            else {
                this._lastError = new Error('unknown server error');
            }
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    async delete() {
        const deleteObject = {
            objectType: objectType,
            object: this._object,
        };
        this._lastError = undefined;
        try {
            const response = await deleteObjects([deleteObject]);
            return response;
        }
        catch (error) {
            this._lastError = error;
            return false;
        }
    }
    get lastError() {
        return this._lastError;
    }
    get organizationId() {
        return this._object.organizationID;
    }
    get accountID() {
        return this._object.accountID;
    }
    set accountID(accountID) {
        this._object.accountID = accountID;
    }
    get name() {
        return this._object.name;
    }
    set name(name) {
        this._object.name = name;
    }
    get groups() {
        return this._object.groups;
    }
    addGroup(group) {
        if (this._object.groups.includes(group.groupID))
            return;
        this._object.groups.push(group.groupID);
    }
    deleteGroup(group) {
        const index = this._object.groups.indexOf(group.groupID);
        if (index > -1) {
            this._object.groups.splice(index, 1);
        }
    }
    get userGroups() {
        return this._object.userGroups;
    }
    addUserGroup(userGroup) {
        if (this._object.userGroups.includes(userGroup.userGroupID))
            return;
        this._object.userGroups.push(userGroup.userGroupID);
    }
    deleteUserGroup(userGroup) {
        const index = this._object.userGroups.indexOf(userGroup.userGroupID);
        if (index > -1) {
            this._object.userGroups.splice(index, 1);
        }
    }
    get siteGroups() {
        return this._object.siteGroups;
    }
    addSiteGroup(siteGroup) {
        if (this._object.siteGroups.includes(siteGroup.siteGroupID))
            return;
        this._object.siteGroups.push(siteGroup.siteGroupID);
    }
    deleteSiteGroup(siteGroup) {
        const index = this._object.siteGroups.indexOf(siteGroup.siteGroupID);
        if (index > -1) {
            this._object.siteGroups.splice(index, 1);
        }
    }
    get roles() {
        return this._object.roles;
    }
    addRole(role) {
        if (this._object.roles.includes(role.roleID))
            return;
        this._object.roles.push(role.roleID);
    }
    deleteRole(role) {
        const index = this._object.roles.indexOf(role.roleID);
        if (index > -1) {
            this._object.roles.splice(index, 1);
        }
    }
    get policies() {
        return this._object.policies;
    }
    addPolicy(policy) {
        if (this._object.policies.includes(policy.policyID))
            return;
        this._object.policies.push(policy.policyID);
    }
    deletePolicy(policy) {
        const index = this._object.policies.indexOf(policy.policyID);
        if (index > -1) {
            this._object.policies.splice(index, 1);
        }
    }
    get invitations() {
        return this._object.invitations;
    }
    addInvitation(invitation) {
        if (this._object.invitations.includes(invitation.invitationID))
            return;
        this._object.invitations.push(invitation.invitationID);
    }
    deleteInvitation(invitation) {
        const index = this._object.invitations.indexOf(invitation.invitationID);
        if (index > -1) {
            this._object.invitations.splice(index, 1);
        }
    }
}
