import { __decorate } from "tslib";
import { html, LitElement, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { EVENTS, loadStyle } from '../../api/ui';
let BernerConfirmation = class BernerConfirmation extends LitElement {
    constructor() {
        super(...arguments);
        this.title = '';
        this.message = '';
        this.confirmation = '';
        this.cancel = '';
        this.action = '';
    }
    // class css-loading is removed as soon as the external css is loaded
    static get styles() {
        return css `
            .css-loading {
                display: none;
            }
        `;
    }
    async firstUpdated() {
        await loadStyle('./css/modals/berner-confirmation-modal.css', this.shadowRoot);
    }
    renderContent() {
        return html `
            <div class="container-fluid">
                <span class="mx-auto">${this.message}</span>
            </div>
        `;
    }
    render() {
        var _a;
        const onClick = () => {
            window.dispatchEvent(new Event(EVENTS.CLICK_OUT));
        };
        const onClickClose = (e) => {
            e.stopPropagation();
            const event = new CustomEvent(EVENTS.CLOSE_MODAL, {
                detail: { confirmed: false, action: this.action },
            });
            window.dispatchEvent(event);
        };
        const onClickConfirm = async (e) => {
            e.stopPropagation();
            const event = new CustomEvent(EVENTS.CLOSE_MODAL, {
                detail: { confirmed: true, action: this.action },
            });
            window.dispatchEvent(event);
        };
        return html `
        <link id="css-target">
        <div class="css-loading" id="root" @click=${onClick}>
            <section
                class="modal show"
                tabindex="-1"
                role="dialog"
                style="display: block; padding-right: 15px;"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <section class="modal-content">
                        <section class="modal-header mb-2">
                            <h5 class="modal-title">${this.title}</h5>
                            <button
                                type="button"
                                class="close"
                                aria-label="Close"
                                @click=${onClickClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </section>
                        <section class="modal-body">
                            ${this.renderContent()}
                        </section>
                        <section class="modal-footer">
                            <div class="button-container mx-auto">
                                <button
                                    .hidden=${!((_a = this.cancel) === null || _a === void 0 ? void 0 : _a.length)}
                                    type="button"
                                    class="btn btn-secondary mr-2"
                                    @click=${onClickClose}
                                >
                                    ${this.cancel}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-info ml-2"
                                    @click=${onClickConfirm}
                                >
                                    ${this.confirmation}
                                </button>
                            </div>
                        </section>
                    </section>
                </div>
            </section>
        </div>`;
    }
};
__decorate([
    property({ type: String, attribute: true })
], BernerConfirmation.prototype, "title", void 0);
__decorate([
    property({ type: String, attribute: true })
], BernerConfirmation.prototype, "message", void 0);
__decorate([
    property({ type: String, attribute: true })
], BernerConfirmation.prototype, "confirmation", void 0);
__decorate([
    property({ type: String, attribute: true })
], BernerConfirmation.prototype, "cancel", void 0);
__decorate([
    property({ type: String, attribute: true })
], BernerConfirmation.prototype, "action", void 0);
BernerConfirmation = __decorate([
    customElement('berner-confirmation-modal')
], BernerConfirmation);
export { BernerConfirmation };
